import * as React from 'react';
import { motion } from 'framer-motion';

function AirAnimationSlide4(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <style>
          {
            '.air_slide_4__cls-2{opacity:.5;mix-blend-mode:multiply}.air_slide_4__cls-3{fill:#e37a39}.air_slide_4__cls-4{fill:#faae5c}.air_slide_4__cls-5{fill:#fcee21}.air_slide_4__cls-6{fill:#d9e021}.air_slide_4__cls-7{fill:#999}.air_slide_4__cls-8{fill:gray}.air_slide_4__cls-9{fill:#75b0e0}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="air_slide_4__Layer_1" data-name="Layer 1">
          <g id="air_slide_4___5_Syre_regn" data-name="5 Syre regn">
            <g id="air_slide_4__partikler">
              <g
                transform="translate(-880 -330)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  repeatCount="indefinite"
                  path="M822.89,293.14C785,272.6,677.4,257.74,662.38,255.71c-118-16-216.26-87.94-268.46-119.46C313.32,87.59,177.67,58-62.07,51.68"
                />
                <path
                  className="air_slide_4__cls-3"
                  d="M863.13 331.57l-15.85-10.34a3 3 0 01.36-5.14l18-8.51a2.94 2.94 0 014.17 3l-2.15 18.84a2.93 2.93 0 01-4.53 2.15z"
                />
                <path
                  className="air_slide_4__cls-4"
                  d="M861.86 327.23l-10.05-6.57a1.87 1.87 0 01.22-3.26l11.42-5.4a1.87 1.87 0 012.65 1.91l-1.37 12a1.87 1.87 0 01-2.87 1.32z"
                />
              </g>
              <g
                transform="translate(-880 -330)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="3s"
                  repeatCount="indefinite"
                  path="M822.89,293.14C785,272.6,677.4,257.74,662.38,255.71c-118-16-216.26-87.94-268.46-119.46C313.32,87.59,177.67,58-62.07,51.68"
                />
                <path
                  className="air_slide_4__cls-5"
                  d="M860.68 328.81a1.26 1.26 0 01-.33-.05l-7.82-2a1.31 1.31 0 01-.93-.92l-2.15-7.84a1.33 1.33 0 01.33-1.27l5.67-5.77a1.29 1.29 0 01.94-.39 1.24 1.24 0 01.33 0l7.82 2a1.31 1.31 0 01.93.92l2.15 7.82a1.33 1.33 0 01-.33 1.27l-5.67 5.77a1.3 1.3 0 01-.94.46z"
                />
                <path
                  className="air_slide_4__cls-6"
                  d="M856.39 311.86l7.81 2 2.15 7.82-5.67 5.77-7.81-2-2.15-7.82 5.67-5.77m0-2.63a2.63 2.63 0 00-1.88.79l-5.67 5.77a2.62 2.62 0 00-.66 2.54l2.15 7.82a2.65 2.65 0 001.87 1.85l7.82 2a3 3 0 00.66.08 2.63 2.63 0 001.88-.79l5.67-5.77a2.62 2.62 0 00.66-2.54l-2.15-7.81a2.61 2.61 0 00-1.87-1.85l-7.82-2.05a3 3 0 00-.66-.08z"
                />
              </g>
              <g
                transform="translate(-880 -330)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="6s"
                  repeatCount="indefinite"
                  path="M822.89,293.14C785,272.6,677.4,257.74,662.38,255.71c-118-16-216.26-87.94-268.46-119.46C313.32,87.59,177.67,58-62.07,51.68"
                />
                <path
                  className="air_slide_4__cls-3"
                  d="M871.21 316.88l-13.89 12.86a2.93 2.93 0 01-4.88-1.61l-3.78-19.6a3 3 0 013.93-3.32l17.67 6.79a3 3 0 01.95 4.88z"
                />
                <path
                  className="air_slide_4__cls-4"
                  d="M866.7 317l-8.82 8.16a1.86 1.86 0 01-3.09-1l-2.4-12.44a1.87 1.87 0 012.5-2.11l11.21 4.28a1.88 1.88 0 01.6 3.11z"
                />
              </g>
              <g
                transform="translate(-880 -330)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="9s"
                  repeatCount="indefinite"
                  path="M822.89,293.14C785,272.6,677.4,257.74,662.38,255.71c-118-16-216.26-87.94-268.46-119.46C313.32,87.59,177.67,58-62.07,51.68"
                />
                <path
                  className="air_slide_4__cls-7"
                  d="M849.23 333.08a3.76 3.76 0 01-2.13-.65 3.87 3.87 0 01-1.07-5.34l5-7.48-7.45-5a3.85 3.85 0 014.27-6.41l7.44 5 5-7.47a3.82 3.82 0 013.2-1.72 3.76 3.76 0 012.13.65 3.87 3.87 0 011.07 5.34l-5 7.47 7.45 5a3.86 3.86 0 011.06 5.34 3.82 3.82 0 01-3.2 1.72 3.76 3.76 0 01-2.13-.65l-7.44-5-5 7.47a3.82 3.82 0 01-3.2 1.73z"
                />
                <path
                  className="air_slide_4__cls-8"
                  d="M863.41 306a2 2 0 011.05.32 1.91 1.91 0 01.52 2.64l-6 9.1 9.07 6.06a1.9 1.9 0 01.53 2.63 1.91 1.91 0 01-1.58.85 1.87 1.87 0 01-1.05-.32l-9.06-6.06-6 9.1a1.91 1.91 0 01-1.58.85 2 2 0 01-1.05-.32 1.91 1.91 0 01-.52-2.64l6-9.1-9.11-6.11a1.9 1.9 0 011-3.48 1.85 1.85 0 011.05.32l9.06 6.06 6-9.1a1.91 1.91 0 011.58-.85m0-3.91a5.77 5.77 0 00-4.83 2.6l-3.87 5.83-5.81-3.88a5.8 5.8 0 00-6.44 9.66l5.83 3.9-3.8 5.85a5.84 5.84 0 001.61 8.05 5.81 5.81 0 008-1.62l3.87-5.83 5.81 3.88a5.8 5.8 0 006.44-9.66l-5.83-3.9 3.89-5.85a5.81 5.81 0 00-4.83-9z"
                />
              </g>
              <g
                transform="translate(-880 -330)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="1s"
                  repeatCount="indefinite"
                  path="M822.89,293.14c-37.87-20.54-156.09-26.94-243.56,0C465.48,328.19,406.26,206.79,326.5,180.35c-81.76-27.1-190-2.55-420.87,33.09"
                />
                <path
                  className="air_slide_4__cls-5"
                  d="M849.36 329.7a1.62 1.62 0 01-1-.33l-7.64-5.92a1.59 1.59 0 01-.6-1.45l1.29-9.6a1.57 1.57 0 011-1.25l8.92-3.69a1.81 1.81 0 01.6-.11 1.56 1.56 0 011 .33l7.64 5.91a1.56 1.56 0 01.59 1.45l-1.28 9.61a1.56 1.56 0 01-1 1.24l-8.88 3.69a1.61 1.61 0 01-.64.12z"
                />
                <path
                  className="air_slide_4__cls-6"
                  d="M851.92 308.92l7.65 5.92-1.29 9.6-8.92 3.68-7.65-5.91 1.29-9.61 8.92-3.68m0-3.15a3.17 3.17 0 00-1.2.24l-8.92 3.69a3.15 3.15 0 00-1.92 2.49l-1.28 9.6a3.12 3.12 0 001.19 2.9l7.64 5.92a3.14 3.14 0 003.13.42l8.92-3.69a3.12 3.12 0 001.92-2.49l1.28-9.6a3.12 3.12 0 00-1.19-2.9l-7.64-5.92a3.17 3.17 0 00-1.93-.66z"
                />
              </g>
              <g
                transform="translate(-880 -330)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="4s"
                  repeatCount="indefinite"
                  path="M822.89,293.14c-37.87-20.54-156.09-26.94-243.56,0C465.48,328.19,406.26,206.79,326.5,180.35c-81.76-27.1-190-2.55-420.87,33.09"
                />
                <path
                  className="air_slide_4__cls-7"
                  d="M849.23 333.08a3.76 3.76 0 01-2.13-.65 3.87 3.87 0 01-1.07-5.34l5-7.48-7.45-5a3.85 3.85 0 014.27-6.41l7.44 5 5-7.47a3.82 3.82 0 013.2-1.72 3.76 3.76 0 012.13.65 3.87 3.87 0 011.07 5.34l-5 7.47 7.45 5a3.86 3.86 0 011.06 5.34 3.82 3.82 0 01-3.2 1.72 3.76 3.76 0 01-2.13-.65l-7.44-5-5 7.47a3.82 3.82 0 01-3.2 1.73z"
                />
                <path
                  className="air_slide_4__cls-8"
                  d="M863.41 306a2 2 0 011.05.32 1.91 1.91 0 01.52 2.64l-6 9.1 9.07 6.06a1.9 1.9 0 01.53 2.63 1.91 1.91 0 01-1.58.85 1.87 1.87 0 01-1.05-.32l-9.06-6.06-6 9.1a1.91 1.91 0 01-1.58.85 2 2 0 01-1.05-.32 1.91 1.91 0 01-.52-2.64l6-9.1-9.11-6.11a1.9 1.9 0 011-3.48 1.85 1.85 0 011.05.32l9.06 6.06 6-9.1a1.91 1.91 0 011.58-.85m0-3.91a5.77 5.77 0 00-4.83 2.6l-3.87 5.83-5.81-3.88a5.8 5.8 0 00-6.44 9.66l5.83 3.9-3.8 5.85a5.84 5.84 0 001.61 8.05 5.81 5.81 0 008-1.62l3.87-5.83 5.81 3.88a5.8 5.8 0 006.44-9.66l-5.83-3.9 3.89-5.85a5.81 5.81 0 00-4.83-9z"
                />
              </g>
              <g
                transform="translate(-880 -330)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="7s"
                  repeatCount="indefinite"
                  path="M822.89,293.14c-37.87-20.54-156.09-26.94-243.56,0C465.48,328.19,406.26,206.79,326.5,180.35c-81.76-27.1-190-2.55-420.87,33.09"
                />
                <path
                  className="air_slide_4__cls-3"
                  d="M860.81 308.36l6.25 17.91a2.94 2.94 0 01-3.41 3.85l-19.42-4.3a3 3 0 01-1.47-4.94l13.17-13.6a2.94 2.94 0 014.88 1.08z"
                />
                <path
                  className="air_slide_4__cls-4"
                  d="M859.17 312.58l4 11.36a1.87 1.87 0 01-2.16 2.45l-12.33-2.73a1.88 1.88 0 01-.93-3.14l8.36-8.63a1.87 1.87 0 013.06.69z"
                />
              </g>
              <g
                transform="translate(-880 -330)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="10s"
                  repeatCount="indefinite"
                  path="M822.89,293.14c-37.87-20.54-156.09-26.94-243.56,0C465.48,328.19,406.26,206.79,326.5,180.35c-81.76-27.1-190-2.55-420.87,33.09"
                />
                <path
                  className="air_slide_4__cls-5"
                  d="M854 332a1.61 1.61 0 01-1-.32l-7.64-5.92a1.59 1.59 0 01-.6-1.45l1.29-9.6a1.56 1.56 0 011-1.25l8.92-3.69a1.56 1.56 0 01.6-.11 1.61 1.61 0 011 .32l7.65 5.92a1.56 1.56 0 01.59 1.45l-1.28 9.6a1.56 1.56 0 01-1 1.25l-8.92 3.69a1.59 1.59 0 01-.61.11z"
                />
                <path
                  className="air_slide_4__cls-6"
                  d="M856.55 311.24l7.64 5.91-1.28 9.61-8.91 3.68-7.65-5.91 1.29-9.61 8.92-3.68m0-3.15a3.17 3.17 0 00-1.2.24l-8.93 3.67a3.12 3.12 0 00-1.92 2.49l-1.28 9.6a3.12 3.12 0 001.19 2.9l7.64 5.92a3.14 3.14 0 003.13.42l8.92-3.69a3.12 3.12 0 001.92-2.49l1.28-9.6a3.12 3.12 0 00-1.19-2.9l-7.64-5.92a3.17 3.17 0 00-1.93-.66z"
                />
              </g>
              <g
                transform="translate(-880 -320)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="2s"
                  repeatCount="indefinite"
                  path="M822.89,293.14C785,272.6,633.41,287.86,545.31,263c-55.65-15.68-166.83-78.83-246.58-105.26C217,130.66,81,137.58-110.81,120.27"
                />
                <path
                  className="air_slide_4__cls-3"
                  d="M846.06 322l1.27-18.93a2.94 2.94 0 014.65-2.2l16.17 11.62a3 3 0 01-.58 5.12L850.13 325a3 3 0 01-4.07-3z"
                />
                <path
                  className="air_slide_4__cls-4"
                  d="M849.23 318.81l.8-12a1.87 1.87 0 013-1.4l10.26 7.38a1.87 1.87 0 01-.36 3.25l-11.07 4.63a1.86 1.86 0 01-2.63-1.86z"
                />
              </g>
              <g
                transform="translate(-880 -320)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="5s"
                  repeatCount="indefinite"
                  path="M822.89,293.14C785,272.6,633.41,287.86,545.31,263c-55.65-15.68-166.83-78.83-246.58-105.26C217,130.66,81,137.58-110.81,120.27"
                />
                <path
                  className="air_slide_4__cls-7"
                  d="M849.23 333.08a3.76 3.76 0 01-2.13-.65 3.87 3.87 0 01-1.07-5.34l5-7.48-7.45-5a3.85 3.85 0 014.27-6.41l7.44 5 5-7.47a3.82 3.82 0 013.2-1.72 3.76 3.76 0 012.13.65 3.87 3.87 0 011.07 5.34l-5 7.47 7.45 5a3.86 3.86 0 011.06 5.34 3.82 3.82 0 01-3.2 1.72 3.76 3.76 0 01-2.13-.65l-7.44-5-5 7.47a3.82 3.82 0 01-3.2 1.73z"
                />
                <path
                  className="air_slide_4__cls-8"
                  d="M863.41 306a2 2 0 011.05.32 1.91 1.91 0 01.52 2.64l-6 9.1 9.07 6.06a1.9 1.9 0 01.53 2.63 1.91 1.91 0 01-1.58.85 1.87 1.87 0 01-1.05-.32l-9.06-6.06-6 9.1a1.91 1.91 0 01-1.58.85 2 2 0 01-1.05-.32 1.91 1.91 0 01-.52-2.64l6-9.1-9.11-6.11a1.9 1.9 0 011-3.48 1.85 1.85 0 011.05.32l9.06 6.06 6-9.1a1.91 1.91 0 011.58-.85m0-3.91a5.77 5.77 0 00-4.83 2.6l-3.87 5.83-5.81-3.88a5.8 5.8 0 00-6.44 9.66l5.83 3.9-3.8 5.85a5.84 5.84 0 001.61 8.05 5.81 5.81 0 008-1.62l3.87-5.83 5.81 3.88a5.8 5.8 0 006.44-9.66l-5.83-3.9 3.89-5.85a5.81 5.81 0 00-4.83-9z"
                />
              </g>
              <g
                transform="translate(-880 -320)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="8s"
                  repeatCount="indefinite"
                  path="M822.89,293.14C785,272.6,633.41,287.86,545.31,263c-55.65-15.68-166.83-78.83-246.58-105.26C217,130.66,81,137.58-110.81,120.27"
                />
                <path
                  className="air_slide_4__cls-5"
                  d="M858.41 333.6a1.62 1.62 0 01-1-.34l-7.56-6a1.6 1.6 0 01-.57-1.46l1.41-9.59a1.59 1.59 0 011-1.23l9-3.56a1.49 1.49 0 01.58-.11 1.54 1.54 0 011 .34l7.56 6a1.58 1.58 0 01.58 1.46l-1.41 9.58a1.61 1.61 0 01-1 1.24l-9 3.56a1.62 1.62 0 01-.59.11z"
                />
                <path
                  className="air_slide_4__cls-6"
                  d="M861.24 312.86l7.56 6-1.41 9.58-9 3.57-7.56-6 1.42-9.58 9-3.56m0-3.15a3.15 3.15 0 00-1.16.23l-9 3.56a3.11 3.11 0 00-2 2.46l-1.42 9.59a3.18 3.18 0 001.15 2.92l7.56 6a3.15 3.15 0 002 .68 3.07 3.07 0 001.16-.22l9-3.56a3.16 3.16 0 002-2.47l1.42-9.58a3.15 3.15 0 00-1.16-2.92l-7.56-6a3.14 3.14 0 00-2-.69z"
                />
              </g>
              <g
                transform="translate(-880 -320)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  begin="1s"
                  repeatCount="indefinite"
                  path="M822.89,293.14C785,272.6,633.41,287.86,545.31,263c-55.65-15.68-166.83-78.83-246.58-105.26C217,130.66,81,137.58-110.81,120.27"
                />
                <path
                  className="air_slide_4__cls-3"
                  d="M873.56 318.78l-13.89 12.86a2.94 2.94 0 01-4.88-1.6l-3.79-19.6a2.94 2.94 0 013.93-3.32l17.66 6.74a3 3 0 01.97 4.92z"
                />
                <path
                  className="air_slide_4__cls-4"
                  d="M869.05 318.94l-8.82 8.16a1.86 1.86 0 01-3.09-1l-2.4-12.44a1.87 1.87 0 012.5-2.1l11.21 4.27a1.88 1.88 0 01.6 3.11z"
                />
              </g>
              <g
                transform="translate(-880 -320)"
              >
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  keyPoints="0.3;1"
                  keyTimes="0;1"
                  calcMode="linear"
                  repeatCount="1"
                  fill="freeze"
                  path="M822.89,293.14C785,272.6,633.41,287.86,545.31,263c-55.65-15.68-166.83-78.83-246.58-105.26C217,130.66,81,137.58-110.81,120.27"
                />
                <path
                  className="air_slide_4__cls-5"
                  d="M856.6 333.36a1.62 1.62 0 01-1-.33l-7.6-5.92a1.56 1.56 0 01-.59-1.45l1.28-9.6a1.59 1.59 0 011-1.25l8.93-3.68a1.53 1.53 0 01.6-.12 1.55 1.55 0 011 .33l7.64 5.91a1.6 1.6 0 01.6 1.45l-1.29 9.61a1.57 1.57 0 01-1 1.24l-8.93 3.69a1.57 1.57 0 01-.64.12z"
                />
                <path
                  className="air_slide_4__cls-6"
                  d="M859.17 312.58l7.64 5.92-1.28 9.6-8.93 3.68-7.6-5.91 1.28-9.6 8.93-3.69m0-3.15a3.18 3.18 0 00-1.21.24l-9 3.69a3.15 3.15 0 00-1.92 2.49l-1.28 9.6a3.15 3.15 0 001.19 2.91l7.64 5.91a3.17 3.17 0 001.93.66 3.13 3.13 0 001.2-.24l8.93-3.68a3.16 3.16 0 001.91-2.5l1.29-9.6a3.13 3.13 0 00-1.2-2.9l-7.64-5.92a3.17 3.17 0 00-1.92-.66z"
                />
              </g>
              <g transform="translate(-880 -320)">
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  keyPoints="0.5;1"
                  keyTimes="0;1"
                  calcMode="linear"
                  repeatCount="1"
                  fill="freeze"
                  path="M822.89,293.14C785,272.6,677.4,257.74,662.38,255.71c-118-16-216.26-87.94-268.46-119.46C313.32,87.59,177.67,58-62.07,51.68"
                />
                <path
                  className="air_slide_4__cls-7"
                  d="M848.18 330.81a3.85 3.85 0 01-3.2-6l5-7.47-7.45-5a3.85 3.85 0 012.14-7.06 3.76 3.76 0 012.13.65l7.44 5 4.95-7.47a3.85 3.85 0 116.41 4.27l-5 7.48 7.44 5a3.85 3.85 0 11-4.26 6.41l-7.44-5-5 7.46a3.82 3.82 0 01-3.16 1.73z"
                />
                <path
                  className="air_slide_4__cls-8"
                  d="M862.36 303.7a1.87 1.87 0 011 .32 1.9 1.9 0 01.52 2.63l-6 9.1 9.07 6.06a1.91 1.91 0 01.52 2.64 1.89 1.89 0 01-1.57.84 1.85 1.85 0 01-1-.32l-9.07-6.06-6 9.1a1.91 1.91 0 01-1.58.85 1.85 1.85 0 01-1-.32 1.9 1.9 0 01-.52-2.63l6-9.1-9.07-6.07a1.9 1.9 0 011-3.48 2 2 0 011 .32l9.06 6.06 6-9.1a1.9 1.9 0 011.58-.84m0-3.91a5.78 5.78 0 00-4.83 2.59l-3.88 5.84-5.8-3.89a5.8 5.8 0 00-6.44 9.66l5.83 3.9-3.89 5.86a5.8 5.8 0 109.66 6.42l3.87-5.83 5.81 3.88a5.8 5.8 0 006.44-9.66l-5.83-3.89 3.89-5.86a5.81 5.81 0 00-4.83-9z"
                />
              </g>
              <g transform="translate(-880 -320)">
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  keyPoints="0.7;1"
                  keyTimes="0;1"
                  calcMode="linear"
                  repeatCount="1"
                  fill="freeze"
                  path="M822.89,293.14C785,272.6,677.4,257.74,662.38,255.71c-118-16-216.26-87.94-268.46-119.46C313.32,87.59,177.67,58-62.07,51.68"
                />
                <path
                  className="air_slide_4__cls-3"
                  d="M862.65 334.23l-18.51-3.78a3 3 0 01-1.56-4.91L856.2 311a2.93 2.93 0 015 1.26l4.9 18.32a3 3 0 01-3.45 3.65z"
                />
                <path
                  className="air_slide_4__cls-4"
                  d="M859.89 330.66l-11.75-2.4a1.87 1.87 0 01-1-3.12l8.64-9.23a1.87 1.87 0 013.17.8l3.1 11.63a1.87 1.87 0 01-2.16 2.32z"
                />
              </g>
              <g transform="translate(-880 -320)">
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  keyPoints="0.5;1"
                  keyTimes="0;1"
                  calcMode="linear"
                  repeatCount="1"
                  fill="freeze"
                  path="M822.89,293.14c-37.87-20.54-156.09-26.94-243.56,0C465.48,328.19,406.26,206.79,326.5,180.35c-81.76-27.1-190-2.55-420.87,33.09"
                />
                <path
                  className="air_slide_4__cls-7"
                  d="M849.23 333.08a3.76 3.76 0 01-2.13-.65 3.87 3.87 0 01-1.07-5.34l5-7.48-7.45-5a3.85 3.85 0 014.27-6.41l7.44 5 5-7.47a3.82 3.82 0 013.2-1.72 3.76 3.76 0 012.13.65 3.87 3.87 0 011.07 5.34l-5 7.47 7.45 5a3.86 3.86 0 011.06 5.34 3.82 3.82 0 01-3.2 1.72 3.76 3.76 0 01-2.13-.65l-7.44-5-5 7.47a3.82 3.82 0 01-3.2 1.73z"
                />
                <path
                  className="air_slide_4__cls-8"
                  d="M863.41 306a2 2 0 011.05.32 1.91 1.91 0 01.52 2.64l-6 9.1 9.07 6.06a1.9 1.9 0 01.53 2.63 1.91 1.91 0 01-1.58.85 1.87 1.87 0 01-1.05-.32l-9.06-6.06-6 9.1a1.91 1.91 0 01-1.58.85 2 2 0 01-1.05-.32 1.91 1.91 0 01-.52-2.64l6-9.1-9.11-6.11a1.9 1.9 0 011-3.48 1.85 1.85 0 011.05.32l9.06 6.06 6-9.1a1.91 1.91 0 011.58-.85m0-3.91a5.77 5.77 0 00-4.83 2.6l-3.87 5.83-5.81-3.88a5.8 5.8 0 00-6.44 9.66l5.83 3.9-3.8 5.85a5.84 5.84 0 001.61 8.05 5.81 5.81 0 008-1.62l3.87-5.83 5.81 3.88a5.8 5.8 0 006.44-9.66l-5.83-3.9 3.89-5.85a5.81 5.81 0 00-4.83-9z"
                />
              </g>
              <g transform="translate(-880 -320)">
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  keyPoints="0.8;1"
                  keyTimes="0;1"
                  calcMode="linear"
                  repeatCount="1"
                  fill="freeze"
                  path="M822.89,293.14C785,272.6,633.41,287.86,545.31,263c-55.65-15.68-166.83-78.83-246.58-105.26C217,130.66,81,137.58-110.81,120.27"
                />
                <path
                  className="air_slide_4__cls-5"
                  d="M856.43 330.9a1.55 1.55 0 01-1-.33l-7.64-5.91a1.57 1.57 0 01-.6-1.45l1.28-9.61a1.58 1.58 0 011-1.24l8.93-3.69a1.57 1.57 0 01.6-.12 1.62 1.62 0 011 .33l7.65 5.92a1.56 1.56 0 01.59 1.45l-1.28 9.6a1.59 1.59 0 01-1 1.25l-8.96 3.68a1.53 1.53 0 01-.57.12z"
                />
                <path
                  className="air_slide_4__cls-6"
                  d="M859 310.13l7.64 5.91-1.28 9.6-8.93 3.69-7.64-5.92 1.28-9.6 8.93-3.68m0-3.15a3.13 3.13 0 00-1.2.24l-8.93 3.69a3.11 3.11 0 00-1.87 2.49l-1.29 9.6a3.13 3.13 0 001.2 2.9l7.64 5.92a3.17 3.17 0 001.92.66 3.1 3.1 0 001.2-.24l8.93-3.69a3.15 3.15 0 001.92-2.49l1.28-9.6a3.14 3.14 0 00-1.19-2.9l-7.64-5.92A3.19 3.19 0 00859 307z"
                />
              </g>
              <g transform="translate(-880 -320)">
                <animateMotion
                  dur="10s"
                  rotate="auto"
                  keyPoints="0.8;1"
                  keyTimes="0;1"
                  calcMode="linear"
                  repeatCount="1"
                  fill="freeze"
                  path="M822.89,293.14c-37.87-20.54-156.09-26.94-243.56,0C465.48,328.19,406.26,206.79,326.5,180.35c-81.76-27.1-190-2.55-420.87,33.09"
                />
                <path
                  className="air_slide_4__cls-3"
                  d="M865.91 330.73L847.39 327a3 3 0 01-1.56-4.91l13.63-14.54a2.93 2.93 0 015 1.25l4.89 18.32a2.94 2.94 0 01-3.44 3.61z"
                />
                <path
                  className="air_slide_4__cls-4"
                  d="M863.14 327.15l-11.75-2.4a1.87 1.87 0 01-1-3.11l8.65-9.23a1.86 1.86 0 013.16.79l3.11 11.63a1.87 1.87 0 01-2.17 2.32z"
                />
              </g>
            </g>
            <g id="air_slide_4__vandr\xE5ber">
              <g id="air_slide_4__rain">
                <motion.g
                  id="air_slide_4__rain_drop"
                  animate={{
                    x: [0, -40],
                    y: [0, 240],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 3, repeat: Infinity,
                  }}
                >
                  <path
                    id="air_slide_4__vandr\xE5be"
                    className="air_slide_4__cls-9"
                    d="M630.45 384.71a1.47 1.47 0 012.09.62c2.33 4.9 9.32 21.38 5.65 35.63-3.86 15-14.64 14.77-21.25 12.79s-15.41-9.37-10.73-22.41c4.97-13.84 19.67-23.8 24.24-26.63z"
                  />
                  <path
                    className="air_slide_4__cls-3"
                    d="M616.15 426l-1.69-15.9a2.48 2.48 0 013.54-2.51l15.11 7.29a2.49 2.49 0 01.26 4.34l-13.37 8.6a2.47 2.47 0 01-3.85-1.82z"
                  />
                  <path
                    className="air_slide_4__cls-4"
                    d="M618.31 422.85l-1.07-10.1a1.57 1.57 0 012.24-1.59l9.6 4.63a1.59 1.59 0 01.16 2.76l-8.52 5.45a1.57 1.57 0 01-2.41-1.15z"
                  />
                </motion.g>
                <motion.g
                  animate={{
                    x: [0, -40],
                    y: [0, 240],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    delay: 1, duration: 3, repeat: Infinity,
                  }}
                  id="air_slide_4__rain_drop-2"
                  data-name="rain_drop"
                >
                  <path
                    id="air_slide_4__vandr\xE5be-2"
                    data-name="vandr\xE5be"
                    className="air_slide_4__cls-9"
                    d="M794.38 451.05a1.46 1.46 0 012.09.62c2.32 4.89 9.32 21.37 5.64 35.62-3.86 15-14.63 14.78-21.24 12.79s-15.42-9.37-10.74-22.41c4.97-13.84 19.67-23.79 24.25-26.62z"
                  />
                  <path
                    className="air_slide_4__cls-3"
                    d="M780.07 492.32l-1.68-15.9a2.48 2.48 0 013.53-2.51L797 481.2a2.5 2.5 0 01.26 4.34l-13.43 8.62a2.48 2.48 0 01-3.76-1.84z"
                  />
                  <path
                    className="air_slide_4__cls-4"
                    d="M782.23 489.18l-1.07-10.09a1.58 1.58 0 012.25-1.6l9.59 4.63a1.59 1.59 0 01.17 2.76l-8.53 5.46a1.57 1.57 0 01-2.41-1.16z"
                  />
                </motion.g>
                <motion.g
                  animate={{
                    x: [0, -40],
                    y: [0, 240],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    delay: 2, duration: 3, repeat: Infinity,
                  }}
                  id="air_slide_4__rain_drop-3"
                  data-name="rain_drop"
                >
                  <path
                    id="air_slide_4__vandr\xE5be-3"
                    data-name="vandr\xE5be"
                    className="air_slide_4__cls-9"
                    d="M379.79 341.93a1.46 1.46 0 012.08.61c2.33 4.9 9.33 21.38 5.65 35.63-3.86 15-14.63 14.77-21.24 12.79s-15.42-9.37-10.74-22.41c4.97-13.84 19.67-23.79 24.25-26.62z"
                  />
                  <path
                    className="air_slide_4__cls-9"
                    d="M365.54 382.93L363.85 367a2.48 2.48 0 013.54-2.5l15.12 7.29a2.5 2.5 0 01.26 4.34l-13.43 8.61a2.48 2.48 0 01-3.8-1.81z"
                  />
                  <path
                    className="air_slide_4__cls-9"
                    d="M367.7 379.79l-1.07-10.1a1.58 1.58 0 012.25-1.59l9.59 4.63a1.58 1.58 0 01.17 2.75l-8.53 5.52a1.57 1.57 0 01-2.41-1.21z"
                  />
                  <g>
                    <path
                      id="air_slide_4__vandr\xE5be-4"
                      data-name="vandr\xE5be"
                      className="air_slide_4__cls-9"
                      d="M379.79 341.93a1.46 1.46 0 012.08.61c2.33 4.9 9.33 21.38 5.65 35.63-3.86 15-14.63 14.77-21.24 12.79s-15.42-9.37-10.74-22.41c4.97-13.84 19.67-23.79 24.25-26.62z"
                    />
                    <path
                      className="air_slide_4__cls-3"
                      d="M365.54 382.93L363.85 367a2.48 2.48 0 013.54-2.5l15.12 7.29a2.5 2.5 0 01.26 4.34l-13.43 8.61a2.48 2.48 0 01-3.8-1.81z"
                    />
                    <path
                      className="air_slide_4__cls-4"
                      d="M367.7 379.79l-1.07-10.1a1.58 1.58 0 012.25-1.59l9.59 4.63a1.58 1.58 0 01.17 2.75l-8.53 5.52a1.57 1.57 0 01-2.41-1.21z"
                    />
                  </g>
                </motion.g>
                <motion.g
                  animate={{
                    x: [0, -40],
                    y: [0, 240],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    duration: 3, repeat: Infinity,
                  }}
                  id="air_slide_4__rain_drop-4"
                  data-name="rain_drop"
                >
                  <path
                    id="air_slide_4__vandr\xE5be-5"
                    data-name="vandr\xE5be"
                    className="air_slide_4__cls-9"
                    d="M191.6 313.7a1.46 1.46 0 012.09.62c2.32 4.89 9.32 21.37 5.65 35.62-3.87 15-14.64 14.78-21.25 12.79s-15.42-9.37-10.73-22.41c4.97-13.84 19.64-23.79 24.24-26.62z"
                  />
                  <path
                    className="air_slide_4__cls-9"
                    d="M176.16 353.49l-1.69-15.9a2.49 2.49 0 013.54-2.51l15.11 7.29a2.49 2.49 0 01.26 4.34L180 355.33a2.48 2.48 0 01-3.84-1.84z"
                  />
                  <path
                    className="air_slide_4__cls-9"
                    d="M178.32 350.35l-1.07-10.09a1.57 1.57 0 012.24-1.59l9.6 4.62a1.59 1.59 0 01.16 2.76l-8.52 5.47a1.57 1.57 0 01-2.41-1.17z"
                  />
                  <g>
                    <path
                      className="air_slide_4__cls-3"
                      d="M176.16 353.49l-1.69-15.9a2.49 2.49 0 013.54-2.51l15.11 7.29a2.49 2.49 0 01.26 4.34L180 355.33a2.48 2.48 0 01-3.84-1.84z"
                    />
                    <path
                      className="air_slide_4__cls-4"
                      d="M178.32 350.35l-1.07-10.09a1.57 1.57 0 012.24-1.59l9.6 4.62a1.59 1.59 0 01.16 2.76l-8.52 5.47a1.57 1.57 0 01-2.41-1.17z"
                    />
                  </g>
                </motion.g>
                <motion.g
                  animate={{
                    x: [0, -40],
                    y: [0, 240],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    delay: 0.5, duration: 3, repeat: Infinity,
                  }}
                  id="air_slide_4__rain_drop-5"
                  data-name="rain_drop"
                >
                  <path
                    id="air_slide_4__vandr\xE5be-6"
                    data-name="vandr\xE5be"
                    className="air_slide_4__cls-9"
                    d="M291.32 308.65a1.46 1.46 0 012.09.62c2.32 4.89 9.32 21.37 5.64 35.62-3.86 15-14.63 14.78-21.24 12.79s-15.42-9.37-10.74-22.41c4.93-13.84 19.67-23.79 24.25-26.62z"
                  />
                  <path
                    className="air_slide_4__cls-5"
                    d="M286 351.87a1.24 1.24 0 01-.27 0l-9-1.66a1.47 1.47 0 01-1.14-1l-3.07-8.66a1.48 1.48 0 01.27-1.47l5.94-7a1.47 1.47 0 011.14-.53h.27l9 1.66a1.5 1.5 0 011.14 1l3.07 8.67a1.49 1.49 0 01-.27 1.47l-5.94 7a1.47 1.47 0 01-1.14.52z"
                  />
                  <path
                    className="air_slide_4__cls-6"
                    d="M279.86 333l9 1.66 3.07 8.67-5.94 7-9-1.67-3.07-8.66 5.94-7m0-3a3 3 0 00-2.28 1.06l-5.94 7a3 3 0 00-.54 2.94l3.07 8.66a3 3 0 002.28 1.94l9 1.66a2.47 2.47 0 00.54.05 3 3 0 002.28-1.05l5.94-7a3 3 0 00.54-2.93l-3.07-8.66a3 3 0 00-2.28-1.94l-9-1.67a3.29 3.29 0 00-.54-.05z"
                  />
                </motion.g>
                <motion.g
                  animate={{
                    x: [0, -40],
                    y: [0, 240],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    delay: 1.5, duration: 3, repeat: Infinity,
                  }}
                  id="air_slide_4__rain_drop-6"
                  data-name="rain_drop"
                >
                  <path
                    id="air_slide_4__vandr\xE5be-7"
                    data-name="vandr\xE5be"
                    className="air_slide_4__cls-9"
                    d="M710.51 451.05a1.46 1.46 0 012.09.62c2.32 4.89 9.32 21.37 5.64 35.62-3.86 15-14.63 14.78-21.24 12.79s-15.42-9.37-10.74-22.41c4.97-13.84 19.67-23.79 24.25-26.62z"
                  />
                  <path
                    className="air_slide_4__cls-5"
                    d="M705.44 492.52h-.27l-9-1.66a1.5 1.5 0 01-1.14-1L692 481.2a1.51 1.51 0 01.26-1.47l5.94-7a1.5 1.5 0 011.14-.53 1.24 1.24 0 01.27 0l9 1.66a1.47 1.47 0 011.13 1l3.08 8.66a1.51 1.51 0 01-.27 1.47l-5.94 7a1.5 1.5 0 01-1.17.53z"
                  />
                  <path
                    className="air_slide_4__cls-6"
                    d="M699.29 473.7l9 1.66 3.07 8.66-5.94 7-9-1.66-3.08-8.67 5.94-7m0-3a3 3 0 00-2.27 1.05l-5.95 7a3 3 0 00-.53 2.93l3.07 8.67a3 3 0 002.27 1.93l9 1.67a3.29 3.29 0 00.54 0 3 3 0 002.28-1.06l5.94-7a3 3 0 00.54-2.93l-3.08-8.67a3 3 0 00-2.27-1.94l-9-1.66a2.62 2.62 0 00-.55-.05z"
                  />
                </motion.g>
                <motion.g
                  animate={{
                    x: [0, -40],
                    y: [0, 240],
                    opacity: [0, 1, 0],
                  }}
                  transition={{
                    delay: 2.5, duration: 3, repeat: Infinity,
                  }}
                  id="air_slide_4__rain_drop-7"
                  data-name="rain_drop"
                >
                  <path
                    id="air_slide_4__vandr\xE5be-8"
                    data-name="vandr\xE5be"
                    className="air_slide_4__cls-9"
                    d="M524.63 407.27a1.46 1.46 0 012.09.62c2.32 4.89 9.32 21.37 5.65 35.62-3.87 15-14.64 14.78-21.25 12.79s-15.41-9.37-10.73-22.41c4.97-13.84 19.67-23.79 24.24-26.62z"
                  />
                  <path
                    className="air_slide_4__cls-5"
                    d="M519.32 451.63h-.27l-9-1.66a1.45 1.45 0 01-1.13-1l-3.08-8.66a1.51 1.51 0 01.27-1.47l5.94-7a1.5 1.5 0 011.14-.53h.27l9 1.67a1.48 1.48 0 011.13 1l3.08 8.67a1.53 1.53 0 01-.27 1.47l-5.94 7a1.5 1.5 0 01-1.14.51z"
                  />
                  <path
                    className="air_slide_4__cls-6"
                    d="M513.17 432.8l9 1.66 3.08 8.67-5.94 7-9-1.67-3.07-8.66 5.94-7m0-3a3 3 0 00-2.28 1.06l-5.94 7a3 3 0 00-.54 2.93l3.08 8.66a3 3 0 002.27 1.94l9 1.66a2.47 2.47 0 00.54.05 3 3 0 002.28-1.05l5.94-7a3 3 0 00.53-2.93l-3.06-8.65a3 3 0 00-2.27-1.94l-9-1.67a3.29 3.29 0 00-.54-.05z"
                  />
                </motion.g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AirAnimationSlide4;
