import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import {
  Container, Row,
} from 'react-bootstrap';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import './wp-air-post.scss';

import { GlobalDispatchContext } from '../../context/GlobalContextProvider';
import { ThemeDispatchContext } from '../../context/ThemeContextProvider';

import SummaryContent from '../../components/Chapters/SummaryContent/SummaryContent';
import ChapterCarouselContent from '../../components/Chapters/ChapterCarouselContent/ChapterCarouselContent';
import Eboti from './components/Eboti/Eboti';

import LastSlideImage from './components/ImgComponents/LastSlideImage';

import BgSlide1 from './components/ImgComponents/AirBgSlide1';
import BgSlide2 from './components/ImgComponents/AirBgSlide2';
import BgSlide3 from './components/ImgComponents/AirBgSlide3';
import BgSlide4 from './components/ImgComponents/AirBgSlide4';
import BgSlide5 from './components/ImgComponents/AirBgSlide5';
import BgSlide6 from './components/ImgComponents/AirBgSlide6';
import BgSlide7 from './components/ImgComponents/AirBgSlide7';

import AnimationSlide1 from './components/AirAnimationSlide1/AirAnimationSlide1';
import AnimationSlide2 from './components/AirAnimationSlide2/AirAnimationSlide2';
import AnimationSlide3 from './components/AirAnimationSlide3/AirAnimationSlide3';
import AnimationSlide4 from './components/AirAnimationSlide4/AirAnimationSlide4';
import AnimationSlide5 from './components/AirAnimationSlide5/AirAnimationSlide5';
import AnimationSlide6 from './components/AirAnimationSlide6/AirAnimationSlide6';
import AnimationSlide7 from './components/AirAnimationSlide7/AirAnimationSlide7';

import NoAnimationSlide1 from './components/AirAnimationSlide1/AirNoAnimationSlide1';
import NoAnimationSlide2 from './components/AirAnimationSlide2/AirNoAnimationSlide2';
import NoAnimationSlide3 from './components/AirAnimationSlide3/AirNoAnimationSlide3';
import NoAnimationSlide4 from './components/AirAnimationSlide4/AirNoAnimationSlide4';
import NoAnimationSlide5 from './components/AirAnimationSlide5/AirNoAnimationSlide5';
import NoAnimationSlide6 from './components/AirAnimationSlide6/AirNoAnimationSlide6';
import NoAnimationSlide7 from './components/AirAnimationSlide7/AirNoAnimationSlide7';

const wpAirPost = ({
  data: {
    wpAirPost: {
      title, acfChaptersFields, seo, language, translations,
    },
  },
}) => {
  useEffect(() => {
    dispatch({ type: 'SET_TRANSLATION_LINKS', payload: { translationLinks: translations } });
    dispatch({ type: 'SET_LANG', payload: { lang: language } });
    dispatchTheme({ type: 'SET_THEME', payload: { themeName: 'air' } });
  }, []);

  const dispatch = useContext(GlobalDispatchContext);
  const dispatchTheme = useContext(ThemeDispatchContext);

  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselOrSummary, setCarouselOrSummary] = useState('carousel');

  const slideAnimations = [<AnimationSlide1 />,
    <AnimationSlide2 />, <AnimationSlide3 />, <AnimationSlide4 />,
    <AnimationSlide5 />, <AnimationSlide6 />, <AnimationSlide7 />];

  const slideNoAnimations = [<NoAnimationSlide1 />,
    <NoAnimationSlide2 />, <NoAnimationSlide3 />, <NoAnimationSlide4 />,
    <NoAnimationSlide5 />, <NoAnimationSlide6 />, <NoAnimationSlide7 />];

  const slideBg = [<BgSlide1 />, <BgSlide2 />, <BgSlide3 />,
    <BgSlide4 />, <BgSlide5 />, <BgSlide6 />, <BgSlide7 />];

  const handleCarouselOrSummary = () => {
    if (carouselOrSummary === 'carousel') {
      setCarouselOrSummary('summary');
    } else {
      setActiveIndex(acfChaptersFields.slidesContent.length - 1);
      console.log('active ', activeIndex);
      setCarouselOrSummary('carousel');
    }
  };

  return (
    <Layout>
      <SEO
        title={title}
        meta={
        [
          { opengraphSiteName: seo.opengraphSiteName },
          { opengraphType: seo.opengraphType },
          { opengraphTitle: seo.opengraphTitle },
          { opengraphImage: seo.opengraphImage?.link },
          { imageHeight: seo.opengraphImage?.mediaDetails.height },
          { imageWidth: seo.opengraphImage?.mediaDetails.width },
        ]
    }
      />
      <Container fluid className="container-global-styling air-post-container custom-max-height">
        <Row className="h-100 justify-content-center align-items-center">

          {
            carouselOrSummary === 'carousel'
              ? (
                <ChapterCarouselContent
                  chapterTitle={title}
                  acfChaptersFields={acfChaptersFields}
                  handleCarouselOrSummary={handleCarouselOrSummary}
                  eboti={<Eboti index={activeIndex} />}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  slideAnimations={slideAnimations}
                  slideNoAnimations={slideNoAnimations}
                  slideBg={slideBg}
                />
              )

              : (
                <SummaryContent
                  summaryTitle={acfChaptersFields.summaryTitle}
                  summaryText={acfChaptersFields.summaryText}
                  exercisesLinkLabelText={acfChaptersFields.exercisesLinkLabelText ? acfChaptersFields.exercisesLinkLabelText : ''}
                  exercisesPageLink={acfChaptersFields.exercisesPageLink ? acfChaptersFields.exercisesPageLink.uri : ''}
                  handleCarouselOrSummary={handleCarouselOrSummary}
                  lastSlideImage={<LastSlideImage />}
                />
              )
            }
        </Row>
      </Container>
    </Layout>
  );
};
export default wpAirPost;

export const query = graphql`
  query($id: String) {
    wpAirPost(id: { eq: $id }) {
      id  
      title
      content
      translations {
        language {
          locale
          name
        }
        uri
      }
      language {
          locale
          name
      }
      acfChaptersFields {
        fieldGroupName
        readMoreButtonLabel
        slidesContent {
        fieldGroupName
        text
        title
        introductionText
        additionalFieldsContent{
            title
            text
          }
        }
        summaryTitle
        summaryText
        exercisesLinkLabelText
        exercisesPageLink {
          ... on WpAirExercisesPost {
            uri
          }
        }
      }
      seo {
          opengraphUrl
          opengraphSiteName
          opengraphType
          opengraphTitle
          opengraphImage {
            link
            mediaDetails {
              height
              width
            }
          }
         }
    }
  }
`;
