import * as React from 'react';

function AirNoAnimationSlide5(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <style>
          {
            '.air_slide_5__cls-3{fill:#e37a39}.air_slide_5__cls-4{fill:#faae5c}.air_slide_5__cls-5{fill:#fcee21}.air_slide_5__cls-6{fill:#d9e021}.air_slide_5__cls-7{fill:#999}.air_slide_5__cls-8{fill:gray}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="air_slide_5__Layer_1" data-name="Layer 1">
          <g id="air_slide_5__Particles_GRP" data-name="Particles GRP">
            <g id="air_slide_5__Wind">
              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1406.46 109.67l14.26-8a2.55 2.55 0 000-4.45l-15-8.37a2.54 2.54 0 00-3.77 2.35l.78 16.39a2.54 2.54 0 003.73 2.08z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1407.8 106l9-5.09a1.62 1.62 0 000-2.83l-9.54-5.31a1.62 1.62 0 00-2.4 1.5l.5 10.4a1.61 1.61 0 002.44 1.33z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-5"
                  d="M1413.44 109a1.36 1.36 0 01-.48-.09L1405 106a1.38 1.38 0 01-.89-1.06l-1.48-8.35a1.41 1.41 0 01.47-1.3l6.47-5.46a1.39 1.39 0 01.89-.32 1.5 1.5 0 01.47.08l8 2.9a1.38 1.38 0 01.89 1l1.47 8.36a1.36 1.36 0 01-.47 1.29l-6.47 5.46a1.37 1.37 0 01-.91.4z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1410.48 90.9l8 2.89 1.48 8.36-6.48 5.46-7.94-2.9-1.54-8.35 6.47-5.46m0-2.76a2.75 2.75 0 00-1.78.65l-6.47 5.46a2.76 2.76 0 00-.93 2.59l1.47 8.35a2.75 2.75 0 001.77 2.11l8 2.9a2.81 2.81 0 00.94.16 2.71 2.71 0 001.78-.65l6.47-5.46a2.75 2.75 0 00.94-2.58l-1.48-8.36a2.75 2.75 0 00-1.77-2.11l-7.95-2.89a2.52 2.52 0 00-.94-.17z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-7"
                  d="M1406.56 109.86a2.8 2.8 0 01-1.63-.52 2.85 2.85 0 01-.7-4L1408 100l-5.41-3.79a2.84 2.84 0 011.63-5.17 2.78 2.78 0 011.62.51l5.41 3.79 3.77-5.43a2.84 2.84 0 014.66 3.26l-3.78 5.43 5.41 3.8a2.84 2.84 0 11-3.26 4.65l-5.4-3.79-3.78 5.43a2.84 2.84 0 01-2.32 1.22z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1417.36 90.09a1.38 1.38 0 01.8.25 1.4 1.4 0 01.34 2l-4.6 6.62 6.59 4.62a1.4 1.4 0 01.34 2 1.37 1.37 0 01-1.94.34l-6.59-4.62-4.6 6.62a1.4 1.4 0 01-1.94.34 1.4 1.4 0 01-.35-2l4.6-6.61-6.58-4.65a1.4 1.4 0 01.8-2.55 1.42 1.42 0 01.8.25l6.58 4.62 4.61-6.61a1.38 1.38 0 011.14-.6m0-2.88a4.27 4.27 0 00-3.51 1.83l-2.95 4.24-4.22-3a4.22 4.22 0 00-2.45-.78 4.29 4.29 0 00-2.46 7.8l4.24 3-3 4.25a4.29 4.29 0 001 6 4.22 4.22 0 002.45.78 4.3 4.3 0 003.52-1.84l3-4.24 4.22 3a4.22 4.22 0 002.45.78 4.29 4.29 0 002.46-7.79l-4.24-3 3-4.26a4.27 4.27 0 00-3.51-6.73z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1406.46 109.67l14.26-8a2.55 2.55 0 000-4.45l-15-8.37a2.54 2.54 0 00-3.77 2.35l.78 16.39a2.54 2.54 0 003.73 2.08z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1407.8 106l9-5.09a1.62 1.62 0 000-2.83l-9.54-5.31a1.62 1.62 0 00-2.4 1.5l.5 10.4a1.61 1.61 0 002.44 1.33z"
                />
              </g>
              {/*  ------------- motion path 2 ---------------- */}
              <g>
                <path
                  className="air_slide_5__cls-5"
                  d="M1413.44 109a1.36 1.36 0 01-.48-.09L1405 106a1.38 1.38 0 01-.89-1.06l-1.48-8.35a1.41 1.41 0 01.47-1.3l6.47-5.46a1.39 1.39 0 01.89-.32 1.5 1.5 0 01.47.08l8 2.9a1.38 1.38 0 01.89 1l1.47 8.36a1.36 1.36 0 01-.47 1.29l-6.47 5.46a1.37 1.37 0 01-.91.4z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1410.48 90.9l8 2.89 1.48 8.36-6.48 5.46-7.94-2.9-1.54-8.35 6.47-5.46m0-2.76a2.75 2.75 0 00-1.78.65l-6.47 5.46a2.76 2.76 0 00-.93 2.59l1.47 8.35a2.75 2.75 0 001.77 2.11l8 2.9a2.81 2.81 0 00.94.16 2.71 2.71 0 001.78-.65l6.47-5.46a2.75 2.75 0 00.94-2.58l-1.48-8.36a2.75 2.75 0 00-1.77-2.11l-7.95-2.89a2.52 2.52 0 00-.94-.17z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-7"
                  d="M1406.56 109.86a2.8 2.8 0 01-1.63-.52 2.85 2.85 0 01-.7-4L1408 100l-5.41-3.79a2.84 2.84 0 011.63-5.17 2.78 2.78 0 011.62.51l5.41 3.79 3.77-5.43a2.84 2.84 0 014.66 3.26l-3.78 5.43 5.41 3.8a2.84 2.84 0 11-3.26 4.65l-5.4-3.79-3.78 5.43a2.84 2.84 0 01-2.32 1.22z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1417.36 90.09a1.38 1.38 0 01.8.25 1.4 1.4 0 01.34 2l-4.6 6.62 6.59 4.62a1.4 1.4 0 01.34 2 1.37 1.37 0 01-1.94.34l-6.59-4.62-4.6 6.62a1.4 1.4 0 01-1.94.34 1.4 1.4 0 01-.35-2l4.6-6.61-6.58-4.65a1.4 1.4 0 01.8-2.55 1.42 1.42 0 01.8.25l6.58 4.62 4.61-6.61a1.38 1.38 0 011.14-.6m0-2.88a4.27 4.27 0 00-3.51 1.83l-2.95 4.24-4.22-3a4.22 4.22 0 00-2.45-.78 4.29 4.29 0 00-2.46 7.8l4.24 3-3 4.25a4.29 4.29 0 001 6 4.22 4.22 0 002.45.78 4.3 4.3 0 003.52-1.84l3-4.24 4.22 3a4.22 4.22 0 002.45.78 4.29 4.29 0 002.46-7.79l-4.24-3 3-4.26a4.27 4.27 0 00-3.51-6.73z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1407.74 90.72l-6.63 15a2.56 2.56 0 002.68 3.56l17-2.35a2.56 2.56 0 001.61-4.16L1412 90.13a2.53 2.53 0 00-4.26.59z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1408.87 94.47l-4.21 9.52a1.62 1.62 0 001.7 2.26l10.81-1.5a1.62 1.62 0 001-2.63l-6.61-8a1.61 1.61 0 00-2.69.35z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1407.74 90.72l-6.63 15a2.56 2.56 0 002.68 3.56l17-2.35a2.56 2.56 0 001.61-4.16L1412 90.13a2.53 2.53 0 00-4.26.59z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1408.87 94.47l-4.21 9.52a1.62 1.62 0 001.7 2.26l10.81-1.5a1.62 1.62 0 001-2.63l-6.61-8a1.61 1.61 0 00-2.69.35z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1407.74 90.72l-6.63 15a2.56 2.56 0 002.68 3.56l17-2.35a2.56 2.56 0 001.61-4.16L1412 90.13a2.53 2.53 0 00-4.26.59z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1408.87 94.47l-4.21 9.52a1.62 1.62 0 001.7 2.26l10.81-1.5a1.62 1.62 0 001-2.63l-6.61-8a1.61 1.61 0 00-2.69.35z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-5"
                  d="M1410 108.9a1.39 1.39 0 01-.94-.38l-6.18-5.8a1.37 1.37 0 01-.39-1.31l1.92-8.27a1.37 1.37 0 01.94-1l8.09-2.46a1.34 1.34 0 01.4-.06 1.39 1.39 0 01.94.37l6.17 5.8a1.39 1.39 0 01.4 1.32l-1.92 8.26a1.37 1.37 0 01-.94 1l-8.09 2.47a1.39 1.39 0 01-.4.06z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1413.88 91l6.17 5.8-1.92 8.26-8.09 2.47-6.17-5.8 1.92-8.27 8.09-2.46m0-2.76a2.84 2.84 0 00-.81.12l-8.07 2.46a2.74 2.74 0 00-1.88 2l-1.92 8.26a2.75 2.75 0 00.8 2.63l6.17 5.81a2.79 2.79 0 001.89.74 2.72 2.72 0 00.8-.12l8.09-2.46a2.77 2.77 0 001.88-2l1.92-8.27a2.74 2.74 0 00-.79-2.63l-6.18-5.8a2.71 2.71 0 00-1.88-.75z"
                />
              </g>
              {/*  ------------- motion path 3 ---------------- */}

              <g>

                <path
                  className="air_slide_5__cls-5"
                  d="M1410 108.9a1.39 1.39 0 01-.94-.38l-6.18-5.8a1.37 1.37 0 01-.39-1.31l1.92-8.27a1.37 1.37 0 01.94-1l8.09-2.46a1.34 1.34 0 01.4-.06 1.39 1.39 0 01.94.37l6.17 5.8a1.39 1.39 0 01.4 1.32l-1.92 8.26a1.37 1.37 0 01-.94 1l-8.09 2.47a1.39 1.39 0 01-.4.06z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1413.88 91l6.17 5.8-1.92 8.26-8.09 2.47-6.17-5.8 1.92-8.27 8.09-2.46m0-2.76a2.84 2.84 0 00-.81.12l-8.07 2.46a2.74 2.74 0 00-1.88 2l-1.92 8.26a2.75 2.75 0 00.8 2.63l6.17 5.81a2.79 2.79 0 001.89.74 2.72 2.72 0 00.8-.12l8.09-2.46a2.77 2.77 0 001.88-2l1.92-8.27a2.74 2.74 0 00-.79-2.63l-6.18-5.8a2.71 2.71 0 00-1.88-.75z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-7"
                  d="M1406.56 109.86a2.8 2.8 0 01-1.63-.52 2.85 2.85 0 01-.7-4L1408 100l-5.41-3.79a2.84 2.84 0 011.63-5.17 2.78 2.78 0 011.62.51l5.41 3.79 3.77-5.43a2.84 2.84 0 014.66 3.26l-3.78 5.43 5.41 3.8a2.84 2.84 0 01-1.63 5.17 2.82 2.82 0 01-1.63-.52l-5.4-3.79-3.78 5.43a2.84 2.84 0 01-2.31 1.17z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1417.36 90.09a1.38 1.38 0 01.8.25 1.4 1.4 0 01.34 2l-4.6 6.62 6.59 4.62a1.4 1.4 0 11-1.6 2.29l-6.59-4.62-4.6 6.62a1.4 1.4 0 01-1.94.34 1.4 1.4 0 01-.35-2l4.6-6.61-6.58-4.6a1.4 1.4 0 01-.35-2 1.4 1.4 0 011.15-.6 1.42 1.42 0 01.8.25l6.58 4.62 4.61-6.61a1.38 1.38 0 011.14-.6m0-2.88a4.27 4.27 0 00-3.51 1.83l-2.95 4.24-4.22-3a4.22 4.22 0 00-2.45-.78 4.29 4.29 0 00-2.46 7.8l4.24 3-3 4.25a4.29 4.29 0 001 6 4.22 4.22 0 002.45.78 4.3 4.3 0 003.52-1.84l3-4.24 4.22 3a4.22 4.22 0 002.45.78 4.29 4.29 0 002.46-7.79l-4.24-3 3-4.26a4.27 4.27 0 00-3.51-6.73z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1407.74 90.72l-6.63 15a2.56 2.56 0 002.68 3.56l17-2.35a2.56 2.56 0 001.61-4.16L1412 90.13a2.53 2.53 0 00-4.26.59z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1408.87 94.47l-4.21 9.52a1.62 1.62 0 001.7 2.26l10.81-1.5a1.62 1.62 0 001-2.63l-6.61-8a1.61 1.61 0 00-2.69.35z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-5"
                  d="M1410 108.9a1.39 1.39 0 01-.94-.38l-6.18-5.8a1.37 1.37 0 01-.39-1.31l1.92-8.27a1.37 1.37 0 01.94-1l8.09-2.46a1.34 1.34 0 01.4-.06 1.39 1.39 0 01.94.37l6.17 5.8a1.39 1.39 0 01.4 1.32l-1.92 8.26a1.37 1.37 0 01-.94 1l-8.09 2.47a1.39 1.39 0 01-.4.06z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1413.88 91l6.17 5.8-1.92 8.26-8.09 2.47-6.17-5.8 1.92-8.27 8.09-2.46m0-2.76a2.84 2.84 0 00-.81.12l-8.07 2.46a2.74 2.74 0 00-1.88 2l-1.92 8.26a2.75 2.75 0 00.8 2.63l6.17 5.81a2.79 2.79 0 001.89.74 2.72 2.72 0 00.8-.12l8.09-2.46a2.77 2.77 0 001.88-2l1.92-8.27a2.74 2.74 0 00-.79-2.63l-6.18-5.8a2.71 2.71 0 00-1.88-.75z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-7"
                  d="M1406.56 109.86a2.8 2.8 0 01-1.63-.52 2.85 2.85 0 01-.7-4L1408 100l-5.41-3.79a2.84 2.84 0 011.63-5.17 2.78 2.78 0 011.62.51l5.41 3.79 3.77-5.43a2.84 2.84 0 014.66 3.26l-3.78 5.43 5.41 3.8a2.84 2.84 0 01-1.63 5.17 2.82 2.82 0 01-1.63-.52l-5.4-3.79-3.78 5.43a2.84 2.84 0 01-2.31 1.17z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1417.36 90.09a1.38 1.38 0 01.8.25 1.4 1.4 0 01.34 2l-4.6 6.62 6.59 4.62a1.4 1.4 0 11-1.6 2.29l-6.59-4.62-4.6 6.62a1.4 1.4 0 01-1.94.34 1.4 1.4 0 01-.35-2l4.6-6.61-6.58-4.6a1.4 1.4 0 01-.35-2 1.4 1.4 0 011.15-.6 1.42 1.42 0 01.8.25l6.58 4.62 4.61-6.61a1.38 1.38 0 011.14-.6m0-2.88a4.27 4.27 0 00-3.51 1.83l-2.95 4.24-4.22-3a4.22 4.22 0 00-2.45-.78 4.29 4.29 0 00-2.46 7.8l4.24 3-3 4.25a4.29 4.29 0 001 6 4.22 4.22 0 002.45.78 4.3 4.3 0 003.52-1.84l3-4.24 4.22 3a4.22 4.22 0 002.45.78 4.29 4.29 0 002.46-7.79l-4.24-3 3-4.26a4.27 4.27 0 00-3.51-6.73z"
                />
              </g>

              <g>
                <path
                  className="air_slide_5__cls-7"
                  d="M1410.08 111.37a2.76 2.76 0 01-.57-.06 2.83 2.83 0 01-2.21-3.35l1.31-6.49-6.46-1.32a2.84 2.84 0 01.56-5.63 2.69 2.69 0 01.57.06l6.46 1.32 1.32-6.49a2.85 2.85 0 012.78-2.28 2.76 2.76 0 01.57.06 2.85 2.85 0 012.21 3.35L1415.3 97l6.47 1.33a2.84 2.84 0 01-.57 5.62 3.66 3.66 0 01-.57 0l-6.46-1.32-1.31 6.49a2.85 2.85 0 01-2.78 2.25z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1413.84 88.57h.28a1.41 1.41 0 011.09 1.65l-1.61 7.91 7.88 1.61a1.4 1.4 0 01-.28 2.77h-.28l-7.87-1.61-1.61 7.91a1.39 1.39 0 01-1.36 1.12 1.33 1.33 0 01-.28 0 1.39 1.39 0 01-1.09-1.65l1.6-7.9-7.87-1.61a1.4 1.4 0 01-1.09-1.66 1.39 1.39 0 011.36-1.12 1.27 1.27 0 01.28 0l7.88 1.61 1.6-7.91a1.41 1.41 0 011.37-1.12m0-2.88a4.28 4.28 0 00-4.19 3.43l-1 5.08-5-1a4.12 4.12 0 00-.86-.09 4.28 4.28 0 00-.85 8.48l5.05 1-1 5.09a4.29 4.29 0 003.34 5 4.91 4.91 0 00.86.08 4.29 4.29 0 004.19-3.43l1-5.07 5 1a4.09 4.09 0 00.85.09 4.29 4.29 0 00.86-8.49l-5.06-1 1-5.08a4.28 4.28 0 00-3.33-5 4.19 4.19 0 00-.86-.09z"
                />
              </g>

              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1421.71 107.48l.14-16.41a2.54 2.54 0 00-3.86-2.2l-14.7 9a2.55 2.55 0 00.16 4.45l14.57 7.45a2.54 2.54 0 003.69-2.29z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1419.2 104.49l.08-10.42a1.61 1.61 0 00-2.45-1.39l-9.33 5.68a1.62 1.62 0 00.11 2.83l9.24 4.73a1.62 1.62 0 002.35-1.43z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1421.71 107.48l.14-16.41a2.54 2.54 0 00-3.86-2.2l-14.7 9a2.55 2.55 0 00.16 4.45l14.57 7.45a2.54 2.54 0 003.69-2.29z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1419.2 104.49l.08-10.42a1.61 1.61 0 00-2.45-1.39l-9.33 5.68a1.62 1.62 0 00.11 2.83l9.24 4.73a1.62 1.62 0 002.35-1.43z"
                />
              </g>
              <g>

                <path
                  className="air_slide_5__cls-5"
                  d="M1413.47 109a1.48 1.48 0 01-.46-.08l-8-2.86a1.36 1.36 0 01-.89-1.05l-1.52-8.35a1.39 1.39 0 01.46-1.3l6.45-5.49a1.4 1.4 0 01.89-.33 1.27 1.27 0 01.47.09l8 2.85a1.4 1.4 0 01.89 1.06l1.51 8.35a1.35 1.35 0 01-.46 1.29l-6.44 5.49a1.39 1.39 0 01-.9.33z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1410.44 90.9l8 2.86 1.51 8.35-6.45 5.49-8-2.86-1.5-8.35 6.44-5.49m0-2.75a2.73 2.73 0 00-1.78.65l-6.45 5.5a2.72 2.72 0 00-.92 2.58l1.51 8.35a2.78 2.78 0 001.78 2.11l8 2.86a3 3 0 00.93.16 2.77 2.77 0 001.79-.66l6.45-5.49a2.76 2.76 0 00.92-2.59l-1.51-8.35a2.77 2.77 0 00-1.78-2.1l-8-2.86a2.77 2.77 0 00-.94-.16z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-7"
                  d="M1412.27 111.56a2.83 2.83 0 01-2.84-2.75l-.21-6.62-6.6.21a2.84 2.84 0 01-.18-5.68l6.59-.22-.22-6.62a2.84 2.84 0 012.74-2.93 2.85 2.85 0 012.92 2.75l.22 6.62 6.6-.22a2.84 2.84 0 01.18 5.68l-6.59.22.21 6.62a2.85 2.85 0 01-2.74 2.94z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1411.65 88.39a1.39 1.39 0 011.39 1.35l.27 8.07 8-.27h.05a1.4 1.4 0 010 2.8l-8 .27.26 8.06a1.4 1.4 0 01-1.35 1.45 1.41 1.41 0 01-1.4-1.36l-.26-8.06-8 .26a1.39 1.39 0 01-1.39-1.35 1.4 1.4 0 011.35-1.45l8-.26-.27-8.06a1.4 1.4 0 011.35-1.45h.05m0-2.89h-.14a4.28 4.28 0 00-4.14 4.42l.17 5.18-5.15.17a4.29 4.29 0 00.14 8.57h.14l5.15-.17.17 5.18a4.27 4.27 0 004.28 4.14h.14a4.29 4.29 0 004.13-4.43l-.16-5.18 5.15-.17a4.28 4.28 0 00-.14-8.56h-.14l-5.16.17-.17-5.18a4.26 4.26 0 00-4.27-4.15z"
                />
              </g>

              {/*  ------------- motion path 1 different starting point ---------------- */}

              <g>

                <path
                  className="air_slide_5__cls-3"
                  d="M1401.48 92.08l4.54 15.76a2.54 2.54 0 004.33 1L1421.88 96a2.56 2.56 0 00-1.42-4.23l-16.08-3a2.55 2.55 0 00-2.9 3.31z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1404.74 94.22l2.89 10a1.6 1.6 0 002.74.63l7.32-8.13a1.62 1.62 0 00-.9-2.68l-10.21-1.87a1.62 1.62 0 00-1.84 2.05z"
                />
              </g>
              <g>

                <path
                  className="air_slide_5__cls-7"
                  d="M1412.8 111.52a2.83 2.83 0 01-2.83-2.58l-.59-6.6-6.56.59h-.26a2.85 2.85 0 01-.25-5.68l6.57-.59-.59-6.6a2.84 2.84 0 012.57-3.08h.26a2.82 2.82 0 012.82 2.59l.59 6.6 6.57-.59h.25a2.85 2.85 0 01.26 5.68l-6.57.59.59 6.6a2.85 2.85 0 01-2.58 3.08z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1411.12 88.42a1.41 1.41 0 011.39 1.28l.71 8 8-.72h.12a1.4 1.4 0 01.13 2.79l-8 .72.72 8a1.4 1.4 0 01-1.27 1.52h-.12a1.39 1.39 0 01-1.39-1.27l-.72-8-8 .72h-.13a1.41 1.41 0 01-1.39-1.28 1.4 1.4 0 011.27-1.52l8-.72-.71-8a1.39 1.39 0 011.26-1.52h.13m0-2.88h-.39a4.29 4.29 0 00-3.87 4.65l.46 5.17-5.14.46a4.28 4.28 0 00.38 8.55 2.55 2.55 0 00.39 0l5.13-.46.46 5.16a4.26 4.26 0 004.26 3.91h.38a4.29 4.29 0 003.88-4.65l-.46-5.16 5.14-.47a4.28 4.28 0 00-.39-8.55h-.38l-5.13.46-.46-5.16a4.26 4.26 0 00-4.26-3.9z"
                />
              </g>
              <g>

                <path
                  className="air_slide_5__cls-3"
                  d="M1407.74 90.72l-6.63 15a2.56 2.56 0 002.68 3.56l17-2.35a2.56 2.56 0 001.61-4.16L1412 90.13a2.53 2.53 0 00-4.26.59z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1408.87 94.47l-4.21 9.52a1.62 1.62 0 001.7 2.26l10.81-1.5a1.62 1.62 0 001-2.63l-6.61-8a1.61 1.61 0 00-2.69.35z"
                />
              </g>
              <g>

                <path
                  className="air_slide_5__cls-5"
                  d="M1410 108.9a1.39 1.39 0 01-.94-.38l-6.18-5.8a1.37 1.37 0 01-.39-1.31l1.92-8.27a1.37 1.37 0 01.94-1l8.09-2.46a1.34 1.34 0 01.4-.06 1.39 1.39 0 01.94.37l6.17 5.8a1.39 1.39 0 01.4 1.32l-1.92 8.26a1.37 1.37 0 01-.94 1l-8.09 2.47a1.39 1.39 0 01-.4.06z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1413.88 91l6.17 5.8-1.92 8.26-8.09 2.47-6.17-5.8 1.92-8.27 8.09-2.46m0-2.76a2.84 2.84 0 00-.81.12l-8.07 2.46a2.74 2.74 0 00-1.88 2l-1.92 8.26a2.75 2.75 0 00.8 2.63l6.17 5.81a2.79 2.79 0 001.89.74 2.72 2.72 0 00.8-.12l8.09-2.46a2.77 2.77 0 001.88-2l1.92-8.27a2.74 2.74 0 00-.79-2.63l-6.18-5.8a2.71 2.71 0 00-1.88-.75z"
                />
              </g>

              {/*  ------------- motion path 2 different starting point ---------------- */}

              <g>

                <path
                  className="air_slide_5__cls-5"
                  d="M1410 108.9a1.39 1.39 0 01-.94-.38l-6.18-5.8a1.37 1.37 0 01-.39-1.31l1.92-8.27a1.37 1.37 0 01.94-1l8.09-2.46a1.34 1.34 0 01.4-.06 1.39 1.39 0 01.94.37l6.17 5.8a1.39 1.39 0 01.4 1.32l-1.92 8.26a1.37 1.37 0 01-.94 1l-8.09 2.47a1.39 1.39 0 01-.4.06z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1413.88 91l6.17 5.8-1.92 8.26-8.09 2.47-6.17-5.8 1.92-8.27 8.09-2.46m0-2.76a2.84 2.84 0 00-.81.12l-8.07 2.46a2.74 2.74 0 00-1.88 2l-1.92 8.26a2.75 2.75 0 00.8 2.63l6.17 5.81a2.79 2.79 0 001.89.74 2.72 2.72 0 00.8-.12l8.09-2.46a2.77 2.77 0 001.88-2l1.92-8.27a2.74 2.74 0 00-.79-2.63l-6.18-5.8a2.71 2.71 0 00-1.88-.75z"
                />
              </g>
              <g>

                <path
                  className="air_slide_5__cls-7"
                  d="M1406.56 109.86a2.8 2.8 0 01-1.63-.52 2.85 2.85 0 01-.7-4L1408 100l-5.41-3.79a2.84 2.84 0 011.63-5.17 2.78 2.78 0 011.62.51l5.41 3.79 3.77-5.43a2.84 2.84 0 014.66 3.26l-3.78 5.43 5.41 3.8a2.84 2.84 0 01-1.63 5.17 2.82 2.82 0 01-1.63-.52l-5.4-3.79-3.78 5.43a2.84 2.84 0 01-2.31 1.17z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1417.36 90.09a1.38 1.38 0 01.8.25 1.4 1.4 0 01.34 2l-4.6 6.62 6.59 4.62a1.4 1.4 0 11-1.6 2.29l-6.59-4.62-4.6 6.62a1.4 1.4 0 01-1.94.34 1.4 1.4 0 01-.35-2l4.6-6.61-6.58-4.6a1.4 1.4 0 01-.35-2 1.4 1.4 0 011.15-.6 1.42 1.42 0 01.8.25l6.58 4.62 4.61-6.61a1.38 1.38 0 011.14-.6m0-2.88a4.27 4.27 0 00-3.51 1.83l-2.95 4.24-4.22-3a4.22 4.22 0 00-2.45-.78 4.29 4.29 0 00-2.46 7.8l4.24 3-3 4.25a4.29 4.29 0 001 6 4.22 4.22 0 002.45.78 4.3 4.3 0 003.52-1.84l3-4.24 4.22 3a4.22 4.22 0 002.45.78 4.29 4.29 0 002.46-7.79l-4.24-3 3-4.26a4.27 4.27 0 00-3.51-6.73z"
                />
              </g>
              <g>

                <path
                  className="air_slide_5__cls-3"
                  d="M1416.18 107.79l6.62-15a2.55 2.55 0 00-2.67-3.57l-17 2.36a2.55 2.55 0 00-1.61 4.15l10.41 12.65a2.54 2.54 0 004.25-.59z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1415.05 104l4.21-9.52a1.62 1.62 0 00-1.7-2.26l-10.82 1.49a1.62 1.62 0 00-1 2.64l6.61 8a1.61 1.61 0 002.7-.35z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-5"
                  d="M1410 108.9a1.39 1.39 0 01-.94-.38l-6.18-5.8a1.37 1.37 0 01-.39-1.31l1.92-8.27a1.37 1.37 0 01.94-1l8.09-2.46a1.34 1.34 0 01.4-.06 1.39 1.39 0 01.94.37l6.17 5.8a1.39 1.39 0 01.4 1.32l-1.92 8.26a1.37 1.37 0 01-.94 1l-8.09 2.47a1.39 1.39 0 01-.4.06z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1413.88 91l6.17 5.8-1.92 8.26-8.09 2.47-6.17-5.8 1.92-8.27 8.09-2.46m0-2.76a2.84 2.84 0 00-.81.12l-8.07 2.46a2.74 2.74 0 00-1.88 2l-1.92 8.26a2.75 2.75 0 00.8 2.63l6.17 5.81a2.79 2.79 0 001.89.74 2.72 2.72 0 00.8-.12l8.09-2.46a2.77 2.77 0 001.88-2l1.92-8.27a2.74 2.74 0 00-.79-2.63l-6.18-5.8a2.71 2.71 0 00-1.88-.75z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-5"
                  d="M1410 108.9a1.39 1.39 0 01-.94-.38l-6.18-5.8a1.37 1.37 0 01-.39-1.31l1.92-8.27a1.37 1.37 0 01.94-1l8.09-2.46a1.34 1.34 0 01.4-.06 1.39 1.39 0 01.94.37l6.17 5.8a1.39 1.39 0 01.4 1.32l-1.92 8.26a1.37 1.37 0 01-.94 1l-8.09 2.47a1.39 1.39 0 01-.4.06z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1413.88 91l6.17 5.8-1.92 8.26-8.09 2.47-6.17-5.8 1.92-8.27 8.09-2.46m0-2.76a2.84 2.84 0 00-.81.12l-8.07 2.46a2.74 2.74 0 00-1.88 2l-1.92 8.26a2.75 2.75 0 00.8 2.63l6.17 5.81a2.79 2.79 0 001.89.74 2.72 2.72 0 00.8-.12l8.09-2.46a2.77 2.77 0 001.88-2l1.92-8.27a2.74 2.74 0 00-.79-2.63l-6.18-5.8a2.71 2.71 0 00-1.88-.75z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-5"
                  d="M1410 108.9a1.39 1.39 0 01-.94-.38l-6.18-5.8a1.37 1.37 0 01-.39-1.31l1.92-8.27a1.37 1.37 0 01.94-1l8.09-2.46a1.34 1.34 0 01.4-.06 1.39 1.39 0 01.94.37l6.17 5.8a1.39 1.39 0 01.4 1.32l-1.92 8.26a1.37 1.37 0 01-.94 1l-8.09 2.47a1.39 1.39 0 01-.4.06z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1413.88 91l6.17 5.8-1.92 8.26-8.09 2.47-6.17-5.8 1.92-8.27 8.09-2.46m0-2.76a2.84 2.84 0 00-.81.12l-8.07 2.46a2.74 2.74 0 00-1.88 2l-1.92 8.26a2.75 2.75 0 00.8 2.63l6.17 5.81a2.79 2.79 0 001.89.74 2.72 2.72 0 00.8-.12l8.09-2.46a2.77 2.77 0 001.88-2l1.92-8.27a2.74 2.74 0 00-.79-2.63l-6.18-5.8a2.71 2.71 0 00-1.88-.75z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-5"
                  d="M1410 108.9a1.39 1.39 0 01-.94-.38l-6.18-5.8a1.37 1.37 0 01-.39-1.31l1.92-8.27a1.37 1.37 0 01.94-1l8.09-2.46a1.34 1.34 0 01.4-.06 1.39 1.39 0 01.94.37l6.17 5.8a1.39 1.39 0 01.4 1.32l-1.92 8.26a1.37 1.37 0 01-.94 1l-8.09 2.47a1.39 1.39 0 01-.4.06z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M1413.88 91l6.17 5.8-1.92 8.26-8.09 2.47-6.17-5.8 1.92-8.27 8.09-2.46m0-2.76a2.84 2.84 0 00-.81.12l-8.07 2.46a2.74 2.74 0 00-1.88 2l-1.92 8.26a2.75 2.75 0 00.8 2.63l6.17 5.81a2.79 2.79 0 001.89.74 2.72 2.72 0 00.8-.12l8.09-2.46a2.77 2.77 0 001.88-2l1.92-8.27a2.74 2.74 0 00-.79-2.63l-6.18-5.8a2.71 2.71 0 00-1.88-.75z"
                />
              </g>

              {/*  ------------- motion path 4 ---------------- */}
              <g>
                <path
                  className="air_slide_5__cls-7"
                  d="M1410.08 111.37a2.76 2.76 0 01-.57-.06 2.83 2.83 0 01-2.21-3.35l1.31-6.49-6.46-1.32a2.84 2.84 0 01.56-5.63 2.69 2.69 0 01.57.06l6.46 1.32 1.32-6.49a2.85 2.85 0 012.78-2.28 2.76 2.76 0 01.57.06 2.85 2.85 0 012.21 3.35L1415.3 97l6.47 1.33a2.84 2.84 0 01-.57 5.62 3.66 3.66 0 01-.57 0l-6.46-1.32-1.31 6.49a2.85 2.85 0 01-2.78 2.25z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1413.84 88.57h.28a1.41 1.41 0 011.09 1.65l-1.61 7.91 7.88 1.61a1.4 1.4 0 01-.28 2.77h-.28l-7.87-1.61-1.61 7.91a1.39 1.39 0 01-1.36 1.12 1.33 1.33 0 01-.28 0 1.39 1.39 0 01-1.09-1.65l1.6-7.9-7.87-1.61a1.4 1.4 0 01-1.09-1.66 1.39 1.39 0 011.36-1.12 1.27 1.27 0 01.28 0l7.88 1.61 1.6-7.91a1.41 1.41 0 011.37-1.12m0-2.88a4.28 4.28 0 00-4.19 3.43l-1 5.08-5-1a4.12 4.12 0 00-.86-.09 4.28 4.28 0 00-.85 8.48l5.05 1-1 5.09a4.29 4.29 0 003.34 5 4.91 4.91 0 00.86.08 4.29 4.29 0 004.19-3.43l1-5.07 5 1a4.09 4.09 0 00.85.09 4.29 4.29 0 00.86-8.49l-5.06-1 1-5.08a4.28 4.28 0 00-3.33-5 4.19 4.19 0 00-.86-.09z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-7"
                  d="M1410.08 111.37a2.76 2.76 0 01-.57-.06 2.83 2.83 0 01-2.21-3.35l1.31-6.49-6.46-1.32a2.84 2.84 0 01.56-5.63 2.69 2.69 0 01.57.06l6.46 1.32 1.32-6.49a2.85 2.85 0 012.78-2.28 2.76 2.76 0 01.57.06 2.85 2.85 0 012.21 3.35L1415.3 97l6.47 1.33a2.84 2.84 0 01-.57 5.62 3.66 3.66 0 01-.57 0l-6.46-1.32-1.31 6.49a2.85 2.85 0 01-2.78 2.25z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1413.84 88.57h.28a1.41 1.41 0 011.09 1.65l-1.61 7.91 7.88 1.61a1.4 1.4 0 01-.28 2.77h-.28l-7.87-1.61-1.61 7.91a1.39 1.39 0 01-1.36 1.12 1.33 1.33 0 01-.28 0 1.39 1.39 0 01-1.09-1.65l1.6-7.9-7.87-1.61a1.4 1.4 0 01-1.09-1.66 1.39 1.39 0 011.36-1.12 1.27 1.27 0 01.28 0l7.88 1.61 1.6-7.91a1.41 1.41 0 011.37-1.12m0-2.88a4.28 4.28 0 00-4.19 3.43l-1 5.08-5-1a4.12 4.12 0 00-.86-.09 4.28 4.28 0 00-.85 8.48l5.05 1-1 5.09a4.29 4.29 0 003.34 5 4.91 4.91 0 00.86.08 4.29 4.29 0 004.19-3.43l1-5.07 5 1a4.09 4.09 0 00.85.09 4.29 4.29 0 00.86-8.49l-5.06-1 1-5.08a4.28 4.28 0 00-3.33-5 4.19 4.19 0 00-.86-.09z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-7"
                  d="M1410.08 111.37a2.76 2.76 0 01-.57-.06 2.83 2.83 0 01-2.21-3.35l1.31-6.49-6.46-1.32a2.84 2.84 0 01.56-5.63 2.69 2.69 0 01.57.06l6.46 1.32 1.32-6.49a2.85 2.85 0 012.78-2.28 2.76 2.76 0 01.57.06 2.85 2.85 0 012.21 3.35L1415.3 97l6.47 1.33a2.84 2.84 0 01-.57 5.62 3.66 3.66 0 01-.57 0l-6.46-1.32-1.31 6.49a2.85 2.85 0 01-2.78 2.25z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M1413.84 88.57h.28a1.41 1.41 0 011.09 1.65l-1.61 7.91 7.88 1.61a1.4 1.4 0 01-.28 2.77h-.28l-7.87-1.61-1.61 7.91a1.39 1.39 0 01-1.36 1.12 1.33 1.33 0 01-.28 0 1.39 1.39 0 01-1.09-1.65l1.6-7.9-7.87-1.61a1.4 1.4 0 01-1.09-1.66 1.39 1.39 0 011.36-1.12 1.27 1.27 0 01.28 0l7.88 1.61 1.6-7.91a1.41 1.41 0 011.37-1.12m0-2.88a4.28 4.28 0 00-4.19 3.43l-1 5.08-5-1a4.12 4.12 0 00-.86-.09 4.28 4.28 0 00-.85 8.48l5.05 1-1 5.09a4.29 4.29 0 003.34 5 4.91 4.91 0 00.86.08 4.29 4.29 0 004.19-3.43l1-5.07 5 1a4.09 4.09 0 00.85.09 4.29 4.29 0 00.86-8.49l-5.06-1 1-5.08a4.28 4.28 0 00-3.33-5 4.19 4.19 0 00-.86-.09z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1421.71 107.48l.14-16.41a2.54 2.54 0 00-3.86-2.2l-14.7 9a2.55 2.55 0 00.16 4.45l14.57 7.45a2.54 2.54 0 003.69-2.29z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1419.2 104.49l.08-10.42a1.61 1.61 0 00-2.45-1.39l-9.33 5.68a1.62 1.62 0 00.11 2.83l9.24 4.73a1.62 1.62 0 002.35-1.43z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1421.71 107.48l.14-16.41a2.54 2.54 0 00-3.86-2.2l-14.7 9a2.55 2.55 0 00.16 4.45l14.57 7.45a2.54 2.54 0 003.69-2.29z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1419.2 104.49l.08-10.42a1.61 1.61 0 00-2.45-1.39l-9.33 5.68a1.62 1.62 0 00.11 2.83l9.24 4.73a1.62 1.62 0 002.35-1.43z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-3"
                  d="M1421.71 107.48l.14-16.41a2.54 2.54 0 00-3.86-2.2l-14.7 9a2.55 2.55 0 00.16 4.45l14.57 7.45a2.54 2.54 0 003.69-2.29z"
                />
                <path
                  className="air_slide_5__cls-4"
                  d="M1419.2 104.49l.08-10.42a1.61 1.61 0 00-2.45-1.39l-9.33 5.68a1.62 1.62 0 00.11 2.83l9.24 4.73a1.62 1.62 0 002.35-1.43z"
                />
              </g>
            </g>
            <g id="air_slide_5__Particles_GRP-2" data-name="Particles GRP">
              <path
                className="air_slide_5__cls-3"
                d="M413.68 439.65l14.26-8a2.55 2.55 0 000-4.46l-15-8.37a2.54 2.54 0 00-3.77 2.36l.78 16.39a2.54 2.54 0 003.73 2.08z"
              />
              <path
                className="air_slide_5__cls-4"
                d="M415 436l9.05-5.09a1.62 1.62 0 000-2.83l-9.55-5.31a1.62 1.62 0 00-2.4 1.49l.5 10.41A1.61 1.61 0 00415 436z"
              />
              <path
                className="air_slide_5__cls-3"
                d="M511.83 461.15l14.26-8a2.56 2.56 0 000-4.46l-15-8.37a2.55 2.55 0 00-3.78 2.36l.79 16.39a2.54 2.54 0 003.73 2.08z"
              />
              <path
                className="air_slide_5__cls-4"
                d="M513.17 457.47l9.05-5.09a1.62 1.62 0 000-2.83l-9.55-5.31a1.61 1.61 0 00-2.39 1.49l.5 10.41a1.61 1.61 0 002.39 1.33z"
              />
              <path
                className="air_slide_5__cls-5"
                d="M493.62 412.61a1.35 1.35 0 01-.47-.09l-7.94-2.89a1.38 1.38 0 01-.89-1.06l-1.48-8.35a1.39 1.39 0 01.47-1.29l6.47-5.47a1.41 1.41 0 01.89-.32 1.5 1.5 0 01.47.08l7.95 2.9a1.38 1.38 0 01.89 1.05l1.47 8.36a1.36 1.36 0 01-.47 1.29l-6.47 5.46a1.35 1.35 0 01-.89.33z"
              />
              <path
                className="air_slide_5__cls-6"
                d="M490.67 394.52l7.95 2.89 1.48 8.36-6.48 5.46-7.94-2.9-1.48-8.33 6.47-5.46m0-2.76a2.75 2.75 0 00-1.78.65l-6.47 5.46a2.77 2.77 0 00-.93 2.59l1.47 8.35a2.75 2.75 0 001.77 2.11l7.95 2.9a2.81 2.81 0 00.94.16 2.71 2.71 0 001.78-.65l6.47-5.46a2.75 2.75 0 00.94-2.58l-1.48-8.36a2.75 2.75 0 00-1.77-2.11l-7.95-2.89a2.52 2.52 0 00-.94-.17z"
              />
              <g>
                <path
                  className="air_slide_5__cls-5"
                  d="M550.75 447.32a1.5 1.5 0 01-.47-.08l-7.95-2.9a1.4 1.4 0 01-.89-1l-1.44-8.41a1.35 1.35 0 01.47-1.29l6.47-5.46a1.32 1.32 0 01.88-.33 1.61 1.61 0 01.48.08l7.94 2.9a1.38 1.38 0 01.89 1.05l1.48 8.36a1.4 1.4 0 01-.47 1.29l-6.5 5.47a1.36 1.36 0 01-.89.32z"
                />
                <path
                  className="air_slide_5__cls-6"
                  d="M547.79 429.23l8 2.89 1.48 8.36-6.47 5.46-8-2.89-1.48-8.36 6.47-5.46m0-2.76a2.77 2.77 0 00-1.78.65l-6.47 5.46a2.77 2.77 0 00-.94 2.59l1.48 8.36a2.75 2.75 0 001.77 2.11l7.95 2.89a2.74 2.74 0 00.94.17 2.81 2.81 0 001.78-.65l6.47-5.46a2.77 2.77 0 00.93-2.59l-1.47-8.36a2.76 2.76 0 00-1.77-2.11l-8-2.89a2.83 2.83 0 00-.94-.17z"
                />
              </g>
              <g>
                <path
                  className="air_slide_5__cls-7"
                  d="M445.55 456.31a2.84 2.84 0 01-2.32-4.47l3.78-5.43-5.41-3.8a2.84 2.84 0 011.63-5.17 2.79 2.79 0 011.62.52l5.4 3.79 3.78-5.43a2.85 2.85 0 012.33-1.22 2.77 2.77 0 011.62.52 2.84 2.84 0 01.71 3.95L454.9 445l5.42 3.79a2.84 2.84 0 01-3.26 4.66l-5.4-3.79-3.78 5.42a2.82 2.82 0 01-2.33 1.22z"
                />
                <path
                  className="air_slide_5__cls-8"
                  d="M456.36 436.54a1.41 1.41 0 01.8.26 1.4 1.4 0 01.34 1.95l-4.6 6.61 6.59 4.62a1.41 1.41 0 01.34 1.95 1.39 1.39 0 01-1.94.35l-6.59-4.62-4.6 6.61a1.4 1.4 0 01-1.15.6 1.42 1.42 0 01-.8-.25 1.41 1.41 0 01-.34-1.95l4.6-6.62-6.59-4.62a1.4 1.4 0 01-.34-1.95 1.37 1.37 0 011.94-.34l6.59 4.62 4.6-6.62a1.41 1.41 0 011.15-.6m0-2.88a4.28 4.28 0 00-3.51 1.84l-3 4.23-4.22-3a4.22 4.22 0 00-2.45-.78 4.28 4.28 0 00-2.46 7.79l4.24 3L442 451a4.28 4.28 0 007 4.9l2.95-4.24 4.22 3a4.28 4.28 0 104.91-7l-4.23-3 3-4.25a4.3 4.3 0 00-1.06-6 4.22 4.22 0 00-2.45-.78z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AirNoAnimationSlide5;
