import * as React from 'react';
import { motion } from 'framer-motion';

function SvgComponent(props) {
  return (
    <svg
      className="eboti"
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <filter id="shadow-eboti-14" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="prefix__clip-path">
          <path
            className="prefix__cls-1"
            d="M143.91 128.42v14.16c0 11.19-8 20.28-17.82 20.29-9.86 0-17.86-9.05-17.88-20.24v-14.16c0-9.77 7-17.7 15.57-17.72h4.51c8.61-.02 15.6 7.9 15.62 17.67z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-2">
          <path
            className="prefix__cls-2"
            d="M183.89 98l-.89-1.13a82.84 82.84 0 00-26.24-22.69 73.65 73.65 0 00-62.64-3.79 83.13 83.13 0 00-28.79 19.35l-1 1a2.32 2.32 0 00-.18 2.94l.85 1.13a83.11 83.11 0 0026.26 22.69 73.63 73.63 0 0062.63 3.79 83.26 83.26 0 0028.83-19.35l1-1a2.31 2.31 0 00.18-2.94z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-3">
          <path
            className="prefix__cls-2"
            d="M147.53 42.75C142 29.15 132.09 22 118.89 22.2c-12.45.15-22.26 3.85-29.19 11-10.8 11.14-10.55 26.5-10.54 27.14a2.16 2.16 0 00.14.67c.49 1.18 4 7.05 24.06 6.75 5.73-.09 12.25-.36 18.55-.71 14.54-.79 28-2 28.16-2a2 2 0 001.81-2 63.69 63.69 0 00-4.35-20.3z"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-1{fill:#2bb673}.prefix__cls-12,.prefix__cls-2{fill:none}.prefix__cls-4{fill:#199b69}.prefix__cls-6{fill:#fcf568;stroke:#fcf568}.prefix__cls-12,.prefix__cls-6{stroke-linecap:round;stroke-linejoin:round}.prefix__cls-6{stroke-width:3.92px}.prefix__cls-12{stroke:#006838;stroke-width:4.97px}'
          }
        </style>
      </defs>
      <g
        filter="url(#shadow-eboti-14)"
      >
        <path
          className="prefix__cls-1"
          d="M143.91 128.42v14.16c0 11.19-8 20.28-17.82 20.29-9.86 0-17.86-9.05-17.88-20.24v-14.16c0-9.77 7-17.7 15.57-17.72h4.51c8.61-.02 15.6 7.9 15.62 17.67z"
        />
        <g clipPath="url(#prefix__clip-path)">
          <path
            className="prefix__cls-4"
            d="M143.6 123.38l1.31 11a75.82 75.82 0 01-38.25 4.35l-1.29-10.8c-1.25-10.47 5.22-19.85 14.44-21l4.83-.57c9.23-1.05 17.71 6.55 18.96 17.02z"
          />
        </g>
        <path
          className="prefix__cls-1"
          d="M194.54 95.12l-6.82-7.48a89.45 89.45 0 00-126.37-5.76l-7.48 6.82a5.8 5.8 0 00-.37 8.18l6.82 7.48a88.87 88.87 0 0062 29.05 90.23 90.23 0 0017.51-.89 88.75 88.75 0 0046.87-22.4l7.38-6.73a6 6 0 002-3.75 5.75 5.75 0 00-1.54-4.52z"
        />
        <motion.g
          id="prefix__hand-l"
          animate={{
            y: [0, -10, 0, -10, 0, -10],
            x: [0, -20],
          }}
          transition={{
            duration: 2, repeat: Infinity,
          }}
        >
          <circle cx={73.86} cy={136.59} r={8.16} fill="#f4c356" />
          <path className="prefix__cls-6" d="M51.84 168.75l18.75-27.27" />
          <path
            className="prefix__cls-1"
            d="M77.94 131.92A24.12 24.12 0 01102.06 156a24.12 24.12 0 01-24.12-24.12v.04z"
            transform="rotate(160.59 89.993 143.98)"
          />
        </motion.g>
        <path
          d="M34 177.8l37.65-.93a4 4 0 003.6-5.39l-23.36-60.86a3.94 3.94 0 00-3.8-2.54l-37.65.92a4 4 0 00-3.61 5.38l23.35 60.86A4 4 0 0034 177.8z"
          fill="#ffe183"
        />
        <path
          d="M31.36 177.8l37.64-.93a4 4 0 003.6-5.39l-23.33-60.86a3.94 3.94 0 00-3.8-2.54L7.82 109a4 4 0 00-3.61 5.38l23.34 60.86a4 4 0 003.81 2.56z"
          fill="#f9cf60"
        />
        <path
          className="prefix__cls-1"
          d="M19.83 141a24.12 24.12 0 0124.12 24.12A24.12 24.12 0 0119.83 141z"
          transform="rotate(158.75 31.888 153.026)"
        />
        <g clipPath="url(#prefix__clip-path-2)">
          <path
            className="prefix__cls-4"
            d="M183.89 98l-.89-1.13a82.84 82.84 0 00-26.24-22.69 73.65 73.65 0 00-62.64-3.79 83.13 83.13 0 00-28.79 19.35l-1 1a2.32 2.32 0 00-.18 2.94l.85 1.13a83.11 83.11 0 0026.26 22.69 73.63 73.63 0 0062.63 3.79 83.26 83.26 0 0028.83-19.35l1-1a2.31 2.31 0 00.18-2.94z"
          />
        </g>
        <path
          d="M182.54 99.44l-.85-1.09A81.25 81.25 0 00156 76.45a73.14 73.14 0 00-61.32-3.74 81.18 81.18 0 00-28.19 18.6l-1 1a2.19 2.19 0 00-.17 2.83l.84 1.09a81.36 81.36 0 0025.72 21.9 73.19 73.19 0 0061.32 3.75 81 81 0 0028.19-18.61l1-1a2.19 2.19 0 00.18-2.83z"
          fill="#beeda2"
        />
        <g>
          <path
            d="M110.07 104.23a1.18 1.18 0 00-1.23 1.11s-.2 3.6-2.46 5.6a5.72 5.72 0 01-4.39 1.29 5.83 5.83 0 01-4.19-1.91c-2-2.28-1.7-5.85-1.7-5.92a1.16 1.16 0 00-2.32-.19c0 .19-.35 4.58 2.24 7.62a8.11 8.11 0 005.8 2.73 10.59 10.59 0 002 0 7.51 7.51 0 004.15-1.84c3-2.63 3.23-7 3.24-7.22a1.16 1.16 0 00-1.14-1.27z"
            fill="#006838"
          />
          <path
            className="prefix__cls-12"
            d="M86.36 85.8l.2 12.55M120.99 85.27l.2 12.55"
          />
        </g>
        <g id="prefix__hat">
          <path
            className="prefix__cls-6"
            d="M151 63.76s-20.76 6.66-40.77 9.36C96.65 75 84.07 73.94 76 71.69c-19.89-5.59 5-12.28 5-12.28s6.16 3.65 22.12 4 47.88.35 47.88.35z"
          />
          <g clipPath="url(#prefix__clip-path-3)" fill="#fcf568">
            <path d="M147.53 42.75C142 29.15 132.09 22 118.89 22.2c-12.45.15-22.26 3.85-29.19 11-10.8 11.14-10.55 26.5-10.54 27.14a2.16 2.16 0 00.14.67c.49 1.18 4 7.05 24.06 6.75 5.73-.09 12.25-.36 18.55-.71 14.54-.79 28-2 28.16-2a2 2 0 001.81-2 63.69 63.69 0 00-4.35-20.3z" />
            <path
              d="M122.54 20.73a80.9 80.9 0 00-15.87 23.16 54.31 54.31 0 00-4.08 26.66l-18-1.67s-.54-13.11 3.78-24c4.58-11.54 18.73-23.32 18.73-23.32z"
              stroke="#f9cf5f"
              strokeWidth={3.92}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
