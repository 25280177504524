import * as React from 'react';
import { motion } from 'framer-motion';

function SvgComponent(props) {
  return (
    <svg
      className="eboti"
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 200"
      {...props}
    >
      <defs>
        <filter id="shadow-eboti-17" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="prefix__clip-path">
          <path
            className="prefix__cls-1"
            d="M208.21 156v14.16c0 11.19-8 20.27-17.82 20.29-9.85 0-17.86-9-17.87-20.24v-14.16c0-9.77 6.95-17.71 15.56-17.72h4.51c8.61-.05 15.6 7.86 15.62 17.67z"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-1{fill:#2bb673}.prefix__cls-2{fill:#199b69}.prefix__cls-5{fill:none;stroke:#006838;stroke-linecap:round;stroke-linejoin:round;stroke-width:4.97px}.prefix__cls-7{fill:#678dc9}'
          }
        </style>
      </defs>
      <g
        filter="url(#shadow-eboti-17)"
      >
        <path
          className="prefix__cls-2"
          d="M215.29 68a20.84 20.84 0 01-20.84 20.84A20.84 20.84 0 01215.29 68z"
          transform="rotate(9.39 204.843 78.424)"
        />
        <path
          className="prefix__cls-1"
          d="M167.54 52.62a32.67 32.67 0 0132.67 32.67 32.67 32.67 0 01-32.67-32.67z"
          transform="rotate(-170.61 183.877 68.952)"
        />
        <path
          className="prefix__cls-1"
          d="M208.21 156v14.16c0 11.19-8 20.27-17.82 20.29-9.85 0-17.86-9-17.87-20.24v-14.16c0-9.77 6.95-17.71 15.56-17.72h4.51c8.61-.05 15.6 7.86 15.62 17.67z"
        />
        <g clipPath="url(#prefix__clip-path)">
          <path
            className="prefix__cls-2"
            d="M207.9 150.92l1.31 11a75.82 75.82 0 01-38.21 4.36l-1.29-10.81c-1.25-10.47 5.22-19.84 14.44-20.94l4.83-.58c9.19-1.1 17.68 6.5 18.92 16.97z"
          />
        </g>
        <path
          id="prefix__HAND_R"
          data-name="HAND R"
          className="prefix__cls-1"
          d="M241.22 171.35a24.57 24.57 0 00-18.81-6.74 24.71 24.71 0 0026.48 25.2 24.55 24.55 0 00-7.67-18.46z"
        />
        <path
          className="prefix__cls-1"
          d="M255.58 135.17l-4.92-8.87A89.65 89.65 0 00128.8 91.4l-8.87 4.92a5.81 5.81 0 00-2.26 7.89l4.92 8.86a89 89 0 0053.7 42.68 90.11 90.11 0 0017.27 3.19 89 89 0 0050.89-11l8.76-4.85a6 6 0 002.77-3.2 5.76 5.76 0 00-.4-4.72z"
        />
        <path
          className="prefix__cls-2"
          d="M243.28 133.89l-.58-1.26a81.24 81.24 0 00-20.19-27.37 71.4 71.4 0 00-58.93-16.87A81.18 81.18 0 00132 100.91l-1.16.76a2.31 2.31 0 00-.8 2.79l.58 1.26a81.24 81.24 0 0020.19 27.37A71.4 71.4 0 00209.7 150a81.18 81.18 0 0031.62-12.52l1.16-.76a2.21 2.21 0 00.9-1.26 2.17 2.17 0 00-.1-1.57z"
        />
        <path
          d="M241.65 135l-.58-1.21a79.3 79.3 0 00-19.93-26.25A71.78 71.78 0 00163.44 91a79.19 79.19 0 00-30.81 11.72l-1.13.71a2.16 2.16 0 00-.76 2.66l.58 1.21a79.29 79.29 0 0019.93 26.24A71.69 71.69 0 00209 150.09a79.28 79.28 0 0030.8-11.71l1.13-.72a2.13 2.13 0 00.87-1.19 2.09 2.09 0 00-.15-1.47z"
          fill="#beeda2"
        />
        <motion.path
          animate={{
            scale: [1, 0, 1],
          }}
          transition={{
            duration: 0.3, repeat: Infinity, repeatDelay: 5,
          }}
          className="prefix__cls-5"
          d="M158.34 102.13l-3.09 12.16"
        />
        <motion.path
          animate={{
            scale: [1, 0, 1],
          }}
          transition={{
            duration: 0.3, repeat: Infinity, repeatDelay: 5,
          }}
          className="prefix__cls-5"
          d="M204.26 110.9l-3.1 12.17"
        />
        <ellipse
          cx={174.38}
          cy={129.47}
          rx={5.2}
          ry={7.56}
          transform="rotate(-56.98 174.39 129.471)"
          fill="#006838"
        />
        <motion.g
          id="prefix__umbrella"
          animate={{
            x: [0, 70, 70, 0],
            y: [0, 70, 70, 0],
          }}
          transition={{
            duration: 4, times: [0, 0.3, 0.8, 1], repeat: Infinity, repeatDelay: 7,
          }}
          style={{
            transformBox: 'fill-box',
            originX: 'center',
            originY: 'bottom',
          }}
        >
          <rect
            className="prefix__cls-7"
            x={108.24}
            y={38.57}
            width={4.51}
            height={120.68}
            rx={2.26}
            transform="rotate(-10.73 110.489 98.892)"
          />
          <path
            className="prefix__cls-7"
            d="M164.71 60.91L162.44 49a.44.44 0 010-.1v-.2c-5.22-26.58-33.63-43.53-63.8-38.1A7.13 7.13 0 0192 14.26a8.56 8.56 0 01-2.41-.35V14c0 3.3-3.26 6-7.28 6a7.6 7.6 0 01-6.45-3.21 57.77 57.77 0 00-22.73 18.32 6 6 0 01.78 8.63 5.79 5.79 0 01-3.15 10.65 6 6 0 01-2-.35v.05a6 6 0 01-5 5.91 43.8 43.8 0 00.61 11l2.32 12.23L47 85c5.23-1 7.41-3.75 9.53-6.42s3.92-4.93 8.53-5.8 7.26.66 10.06 2.28c3 1.7 6 3.47 11.22 2.48s7.4-3.74 9.52-6.41c1.61-2 3.15-4 6.06-5.11a13.94 13.94 0 012.47-.7 15.93 15.93 0 011.95-.24c3.46-.17 5.72 1.15 8.11 2.53 3 1.7 6 3.47 11.21 2.48s7.41-3.75 9.52-6.41 3.92-4.93 8.53-5.81 7.27.66 10.07 2.29 6 3.47 11.21 2.48zm-78.77-11a8.14 8.14 0 01-2.4-.35v.06c0 3.31-3.26 6-7.28 6S69 53 69 49.64a5.69 5.69 0 012.9-4.77A5.83 5.83 0 0168.8 40c0-3.35 3.3-6.06 7.37-6.06h.85c.79-2.66 3.69-4.63 7.16-4.63 4.07 0 7.37 2.71 7.37 6.06a5.45 5.45 0 01-1.77 3.93A5.61 5.61 0 0193 44.14c0 3.2-3.17 5.79-7.06 5.79z"
          />
          <g id="prefix__hand">
            <path
              id="prefix__HAND_L"
              data-name="HAND L"
              className="prefix__cls-1"
              d="M121.15 134.43a25.55 25.55 0 00-20.09 5.38 25.74 25.74 0 0037.6 5.87 25.56 25.56 0 00-17.51-11.25z"
            />
          </g>
        </motion.g>

      </g>
    </svg>
  );
}

export default SvgComponent;
