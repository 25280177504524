import * as React from 'react';
import { motion } from 'framer-motion';

function SvgComponent(props) {
  return (
    <svg
      className="eboti"
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 600"
      {...props}
      style={{
        height: 'auto',
        width: 200,
      }}
    >
      <defs>
        <filter id="shadow-eboti-16" y="-40%" x="-40%" height="180%" width="180%" colorInterpolationFilters="sRGB">
          <feDropShadow dx="0" dy="0" stdDeviation="6" floodOpacity="0.4" />
        </filter>
        <clipPath id="prefix__clip-path">
          <path
            d="M159.19 506.2l-.88-1.06A80.84 80.84 0 00132 484a71.66 71.66 0 00-61.1-1.44 80.92 80.92 0 00-27.4 19.85l-.93 1a2.25 2.25 0 00-.07 2.87l.88 1.06a80.7 80.7 0 0026.35 21.16 71.71 71.71 0 0061.1 1.45 81 81 0 0027.36-19.87l.93-1a2.24 2.24 0 00.07-2.86z"
            fill="none"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-7{fill:none}.prefix__cls-2{fill:#2bb673}.prefix__cls-3{fill:#199b69}.prefix__cls-7{stroke:#116043;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px}'
          }
        </style>
      </defs>
      <g
        filter="url(#shadow-eboti-16)"
      >
        <rect
          className="prefix__cls-2"
          x={82.07}
          y={512.64}
          width={35.86}
          height={59.76}
          rx={17.93}
        />
        <path
          className="prefix__cls-3"
          d="M82.07 548.2v-23.91h35.86v23.91s-6.64 3.45-17.93 3.45-17.93-3.45-17.93-3.45z"
        />
        <g id="prefix__head">
          <path
            className="prefix__cls-3"
            d="M127.35 448.07a26.1 26.1 0 01-26.1 26.1 26.1 26.1 0 0126.1-26.1z"
          />
          <path
            className="prefix__cls-2"
            d="M72.15 440.41a33.76 33.76 0 0133.76 33.76 33.76 33.76 0 01-33.76-33.76z"
            transform="rotate(180 89.025 457.29)"
          />
          <path
            id="prefix__hrad"
            className="prefix__cls-2"
            d="M170.41 504.09l-7-7.35a89.11 89.11 0 00-126.32-3.06l-7.32 7a5.83 5.83 0 00-.2 8.21l7 7.35A88.63 88.63 0 0099.1 544a90.49 90.49 0 0017.46-1.27 88.56 88.56 0 0046.33-23.46l7.32-7a5.83 5.83 0 00.2-8.18z"
          />
          <g clipPath="url(#prefix__clip-path)">
            <path
              className="prefix__cls-3"
              d="M159.19 506.2l-.88-1.06A80.84 80.84 0 00132 484a71.66 71.66 0 00-61.1-1.44 80.92 80.92 0 00-27.4 19.85l-.93 1a2.25 2.25 0 00-.07 2.87l.88 1.06a80.7 80.7 0 0026.35 21.16 71.71 71.71 0 0061.1 1.45 81 81 0 0027.36-19.87l.93-1a2.24 2.24 0 00.07-2.86z"
            />
          </g>
          <path
            d="M157.92 507.65l-.86-1a78.91 78.91 0 00-25.82-20.39 71.12 71.12 0 00-59.81-1.45 78.91 78.91 0 00-26.77 19.09l-.91 1a2.14 2.14 0 00-.07 2.77l.86 1a79 79 0 0025.82 20.39 71.24 71.24 0 0059.82 1.45A78.91 78.91 0 00157 511.4l.91-1a2.14 2.14 0 00.06-2.77z"
            fill="#beeda2"
          />
          <path
            d="M99.06 527.93a10.1 10.1 0 01-5.69-1.53 4.85 4.85 0 01-2.06-3.16 7.77 7.77 0 011.62-6 8.76 8.76 0 0115.28 3.6c.28 1.67.2 5.69-6.71 6.89a14.54 14.54 0 01-2.44.2z"
            fill="#116043"
          />
          <motion.path
            animate={{
              scaleY: [1, 0, 1],
            }}
            transition={{
              delay: 2, duration: 0.3, repeat: Infinity, repeatDelay: 4,
            }}
            className="prefix__cls-7"
            d="M125.62 500.16v8.27"
          />
          <motion.path
            animate={{
              scaleY: [1, 0, 1],
            }}
            transition={{
              delay: 2, duration: 0.3, repeat: Infinity, repeatDelay: 4,
            }}
            className="prefix__cls-7"
            d="M75.83 500.16v8.27"
          />
        </g>
        <motion.g
          id="prefix__hand"
          animate={{
            rotate: [0, -20, 0],
          }}
          transition={{
            duration: 1, repeat: Infinity, repeatDelay: 3,
          }}
          style={{
            transformBox: 'fill-box',
            originX: 'right',
            originY: 'center',
          }}
        >
          <path
            className="prefix__cls-2"
            d="M56.73 539.13c-5.22-2.53-11.61-.13-11.61-.13s2.07 6.51 7.3 9 11.6.12 11.6.12-2.02-6.46-7.29-8.99z"
          />
          <motion.g
            animate={{
              rotate: [0, -360],
              y: [0, -200, 0],
            }}
            transition={{
              delay: 0.5, duration: 2, repeat: Infinity, repeatDelay: 2,
            }}
            style={{
              transformBox: 'fill-box',
              originX: 'center',
              originY: 'center',
            }}
          >
            <path
              d="M33.37 547a1.52 1.52 0 01-1.32-.76l-4.65-8.1a1.51 1.51 0 010-1.51l4.67-8.1a1.51 1.51 0 011.31-.76h9.32a1.52 1.52 0 011.32.76l4.65 8.09a1.53 1.53 0 010 1.52L44 546.27a1.51 1.51 0 01-1.31.76z"
              fill="#fcee21"
            />
            <path
              d="M42.7 529.32l4.65 8.09-4.66 8.1h-9.32l-4.65-8.09 4.66-8.11h9.32m0-3h-9.32a3 3 0 00-2.63 1.52l-4.67 8.1a3 3 0 000 3l4.66 8.07a3 3 0 002.63 1.53h9.32a3 3 0 002.63-1.53l4.68-8.08a3 3 0 000-3l-4.65-8.1a3.06 3.06 0 00-2.64-1.52z"
              fill="#d9e021"
            />
          </motion.g>
          <path
            className="prefix__cls-2"
            d="M28.51 548v.05c0 6.78 7.91 12.31 17.71 12.35C56 560.46 64 555 64 548.2z"
          />

        </motion.g>
        <path
          id="prefix__hand-2"
          data-name="hand"
          className="prefix__cls-2"
          d="M128.88 548.37A24.12 24.12 0 01153 572.49a24.12 24.12 0 01-24.12-24.12z"
          transform="rotate(180 140.94 560.425)"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
