import * as React from 'react';
import { motion } from 'framer-motion';

function SvgComponent(props) {
  return (
    <svg
      className="eboti"
      id="prefix__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path">
          <path
            className="prefix__cls-1"
            d="M127.33 138.43l2.28 14c1.8 11.05-4.63 21.28-14.36 22.87-9.73 1.58-19.07-6.09-20.87-17.14l-2.27-14c-1.57-9.65 4-18.59 12.54-20l4.46-.72c8.49-1.35 16.66 5.35 18.22 14.99z"
          />
        </clipPath>
        <style>
          {
            '.prefix__cls-1{fill:#2bb673}.prefix__cls-3{fill:#199b69}.prefix__cls-7{fill:none;stroke:#006838;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.57px}.prefix__cls-8{fill:#5d9fd3}.prefix__cls-9{fill:#487bb7}.prefix__cls-10{fill:#6cbfe0}'
          }
        </style>
      </defs>
      <path
        className="prefix__cls-1"
        d="M127.33 138.43l2.28 14c1.8 11.05-4.63 21.28-14.36 22.87-9.73 1.58-19.07-6.09-20.87-17.14l-2.27-14c-1.57-9.65 4-18.59 12.54-20l4.46-.72c8.49-1.35 16.66 5.35 18.22 14.99z"
      />
      <g clipPath="url(#prefix__clip-path)">
        <path
          className="prefix__cls-3"
          d="M126.23 133.5l3 10.65a75.7 75.7 0 01-37.03 10.39l-3-10.46c-2.9-10.14 2-20.43 10.92-23l4.68-1.34c8.94-2.53 18.53 3.62 21.43 13.76z"
        />
      </g>
      <motion.g
        animate={{
          rotate: [0, -10, 10, -10, 10, -10, 10, -10, 10, -10, 0],
        }}
        transition={{
          duration: 2, repeat: Infinity, repeatDelay: 4,
        }}
        style={{
          transformBox: 'fill-box',
          originX: 'center',
          originY: 'center',
        }}
        id="prefix__head"
      >
        <path
          className="prefix__cls-3"
          d="M117.53 52A21.53 21.53 0 0196 73.57 21.53 21.53 0 01117.53 52z"
          transform="rotate(-7.24 106.72 62.772)"
        />
        <path
          className="prefix__cls-1"
          d="M66.3 42.76a33.76 33.76 0 0133.76 33.76A33.76 33.76 0 0166.3 42.76z"
          transform="rotate(172.76 83.179 59.64)"
        />
        <path
          id="prefix__hrad"
          className="prefix__cls-1"
          d="M172.93 93.89l-8.29-6a90.7 90.7 0 00-126.72 20l-6 8.3a5.89 5.89 0 001.29 8.2l8.3 6A90.11 90.11 0 00109 146.62a91.94 91.94 0 0017.21-4.45 90 90 0 0042-31.78l6-8.29a5.88 5.88 0 00-1.28-8.21z"
        />
        <path
          className="prefix__cls-3"
          d="M160.89 99.25l-1-.89a80.8 80.8 0 00-29.73-16 71.7 71.7 0 00-60.36 9.57 81 81 0 00-23.34 24.47l-.73 1.17a2.24 2.24 0 00.44 2.83l1.06.89a80.72 80.72 0 0029.73 16 71.7 71.7 0 0060.36-9.57 81 81 0 0023.34-24.47l.73-1.17a2.23 2.23 0 00-.45-2.83z"
        />
        <path
          d="M160.89 99.25l-1-.89a80.8 80.8 0 00-29.73-16 71.7 71.7 0 00-60.36 9.57 81 81 0 00-23.34 24.47l-.73 1.17a2.24 2.24 0 00.44 2.83l1.06.89a80.72 80.72 0 0029.73 16 71.7 71.7 0 0060.36-9.57 81 81 0 0023.34-24.47l.73-1.17a2.23 2.23 0 00-.45-2.83z"
          fill="none"
        />
        <path
          d="M159.91 100.91l-1-.85a79.06 79.06 0 00-29.07-15.42A71.29 71.29 0 0070.71 94a78.9 78.9 0 00-22.89 23.63l-.72 1.12a2.14 2.14 0 00.43 2.73l1 .85a79.06 79.06 0 0029.07 15.42 71.19 71.19 0 0059.1-9.34 79 79 0 0022.89-23.62l.72-1.13a2.13 2.13 0 00-.43-2.73z"
          fill="#beeda2"
        />
        <ellipse
          id="prefix__mouth"
          cx={104.1}
          cy={124.09}
          rx={6.97}
          ry={9.42}
          transform="rotate(-20.07 104.098 124.113)"
          stroke="#006838"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3.57}
          fill="#006838"
        />
        <path
          id="prefix__eye_squissed"
          data-name="eye squissed"
          className="prefix__cls-7"
          d="M136.4 98.3l-11.2 7.1 11.25 6.25"
        />
        <path
          id="prefix__eye_squissed-2"
          data-name="eye squissed"
          className="prefix__cls-7"
          d="M69.58 119.92l11.64-6.33-10.81-6.99"
        />
        <g id="prefix__headphones_01" data-name="headphones 01">
          <path
            className="prefix__cls-8"
            d="M181.58 83.08C153.8-5.62 22.92 10.66 17.72 103.46c-.07 1.26.45 2.31 1.14 2.33l3 .1c.68 0 1.27-.93 1.34-2.09 4.85-86.7 127.12-101.91 153.07-19 .35 1.12 1.15 1.89 1.81 1.7l2.92-.84c.7-.24 1-1.38.58-2.58z"
          />
          <rect
            className="prefix__cls-8"
            x={174.03}
            y={81.7}
            width={8.68}
            height={31.31}
            rx={4.34}
            transform="rotate(-7.09 178.45 97.34)"
          />
          <rect
            className="prefix__cls-8"
            x={179.25}
            y={89.47}
            width={8.68}
            height={14.47}
            rx={4.34}
            transform="rotate(-7.09 183.675 96.692)"
          />
          <rect
            className="prefix__cls-9"
            x={166.97}
            y={80.54}
            width={11.84}
            height={34.99}
            rx={4.38}
            transform="rotate(-7.09 172.896 98.05)"
          />
          <rect
            className="prefix__cls-8"
            x={19.98}
            y={100.87}
            width={8.68}
            height={31.31}
            rx={4.34}
            transform="rotate(-7.09 24.296 116.552)"
          />
          <rect
            className="prefix__cls-8"
            x={14.76}
            y={109.93}
            width={8.68}
            height={14.47}
            rx={3.22}
            transform="rotate(-7.09 19.07 117.2)"
          />
          <rect
            className="prefix__cls-9"
            x={23.89}
            y={98.34}
            width={11.84}
            height={34.99}
            rx={1.91}
            transform="rotate(-7.09 29.849 115.842)"
          />
          <g>
            <rect
              className="prefix__cls-8"
              x={174.03}
              y={81.7}
              width={8.68}
              height={31.31}
              rx={4.34}
              transform="rotate(-7.09 178.45 97.34)"
            />
            <rect
              className="prefix__cls-8"
              x={179.25}
              y={89.47}
              width={8.68}
              height={14.47}
              rx={3.22}
              transform="rotate(-7.09 183.594 96.687)"
            />
            <rect
              className="prefix__cls-9"
              x={166.97}
              y={80.54}
              width={11.84}
              height={34.99}
              rx={1.91}
              transform="rotate(-7.09 172.896 98.05)"
            />
          </g>
          <ellipse
            className="prefix__cls-10"
            cx={181.59}
            cy={89}
            rx={3.01}
            ry={3.03}
            transform="rotate(-7.09 181.638 89.03)"
          />
          <ellipse
            className="prefix__cls-10"
            cx={19.15}
            cy={109.21}
            rx={3.01}
            ry={3.03}
            transform="rotate(-7.09 19.157 109.266)"
          />
        </g>
        <path
          className="prefix__cls-1"
          d="M10.9 121.43A24.12 24.12 0 0135 145.54a24.12 24.12 0 01-24.1-24.11z"
          transform="rotate(180 22.96 133.485)"
        />
        <path
          className="prefix__cls-1"
          d="M192.17 99.09a24.12 24.12 0 01-24.12 24.12 24.12 24.12 0 0124.12-24.12z"
          transform="rotate(-17.59 180.06 111.114)"
        />
      </motion.g>
    </svg>
  );
}

export default SvgComponent;
