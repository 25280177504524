import * as React from 'react';

function AirNoAnimationSlide7(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <clipPath id="air_slide_7__clip-path">
          <path fill="none" d="M0 0h1440v797H0z" />
        </clipPath>
        <clipPath id="air_slide_7__clip-path-2">
          <path
            className="air_slide_7__cls-2"
            d="M532.95 632.04l-18.17-6.57-1.17 9.96 16.36 5.46 2.98-8.85z"
          />
        </clipPath>
        <clipPath id="air_slide_7__clip-path-3">
          <path
            className="air_slide_7__cls-2"
            d="M503.92 622.35l-18.16-6.58-1.17 9.96 16.35 5.47 2.98-8.85z"
          />
        </clipPath>
        <filter
          id="air_slide_7__luminosity-invert"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feColorMatrix values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0" />
        </filter>
        <style>
          {
            '.air_slide_7__cls-2{fill:#c86b4b}.air_slide_7__cls-4{opacity:.5;mix-blend-mode:multiply}.air_slide_7__cls-5{fill:#fbc63b}.air_slide_7__cls-6{fill:#efdf4b}.air_slide_7__cls-7{fill:#fbebca}.air_slide_7__cls-8{fill:#75b0e0}.air_slide_7__cls-9{fill:#bfdbf1}.air_slide_7__cls-10{fill:#e37a39}.air_slide_7__cls-11{fill:#faae5c}.air_slide_7__cls-12{fill:#fcee21}.air_slide_7__cls-13{fill:#d9e021}.air_slide_7__cls-14{fill:#fde9a3}'
          }
        </style>
        <mask
          id="air_slide_7__mask"
          x={360}
          y={226}
          width={1030}
          height={209}
          maskUnits="userSpaceOnUse"
        >
          <g filter="url(#air_slide_7__luminosity-invert)" />
        </mask>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="air_slide_7__Layer_1" data-name="Layer 1">
          <g id="air_slide_7__float flies">
            <g>
              <rect
                className="air_slide_7__cls-5"
                x={579.11}
                y={279.46}
                width={18.95}
                height={6.75}
                rx={3.37}
                transform="rotate(-73.55 588.604 282.831)"
              />
            </g>
            <g>
              <rect
                className="air_slide_7__cls-6"
                x={580.36}
                y={274.06}
                width={6.75}
                height={18.95}
                rx={3.37}
                transform="rotate(-30 583.728 283.53)"
              />
            </g>

            <rect
              className="air_slide_7__cls-6"
              x={581.2}
              y={288.41}
              width={13.08}
              height={4.64}
              rx={2.32}
              transform="rotate(-5.26 587.465 290.632)"
            />
            <g>
              <rect
                className="air_slide_7__cls-5"
                x={543.88}
                y={310.15}
                width={14.5}
                height={5.16}
                rx={2.58}
                transform="rotate(-73.55 551.157 312.732)"
              />
            </g>
            <g>
              <rect
                className="air_slide_7__cls-6"
                x={544.84}
                y={306.03}
                width={5.16}
                height={14.5}
                rx={2.58}
                transform="rotate(-30 547.417 313.28)"
              />
            </g>
            <rect
              className="air_slide_7__cls-6"
              x={545.48}
              y={317.01}
              width={10.01}
              height={3.55}
              rx={1.78}
              transform="rotate(-5.26 550.288 318.704)"
            />
          </g>
          <g id="air_slide_7__partikler">
            <rect
              className="air_slide_7__cls-8"
              x={585.54}
              y={376.47}
              width={15.55}
              height={15.55}
              rx={7.01}
              transform="rotate(-45 593.317 384.24)"
            />
            <rect
              className="air_slide_7__cls-9"
              x={587.55}
              y={378.48}
              width={11.53}
              height={11.53}
              rx={5.76}
              transform="rotate(-45 593.317 384.24)"
            />
            <rect
              className="air_slide_7__cls-8"
              x={412.8}
              y={228.32}
              width={15.55}
              height={15.55}
              rx={7.01}
              transform="rotate(-45 420.573 236.093)"
            />
            <rect
              className="air_slide_7__cls-9"
              x={414.81}
              y={230.33}
              width={11.53}
              height={11.53}
              rx={5.76}
              transform="rotate(-45 420.573 236.093)"
            />
            <rect
              className="air_slide_7__cls-8"
              x={363.03}
              y={333}
              width={15.55}
              height={15.55}
              rx={7.01}
              transform="rotate(-45 370.805 340.778)"
            />
            <rect
              className="air_slide_7__cls-9"
              x={365.04}
              y={335.01}
              width={11.53}
              height={11.53}
              rx={5.76}
              transform="rotate(-45 370.805 340.778)"
            />
            <g>
              <rect
                className="air_slide_7__cls-8"
                x={546.59}
                y={277.21}
                width={15.55}
                height={15.55}
                rx={7.01}
                transform="rotate(-45 554.373 284.981)"
              />
              <rect
                className="air_slide_7__cls-9"
                x={548.6}
                y={279.22}
                width={11.53}
                height={11.53}
                rx={5.76}
                transform="rotate(-45 554.373 284.981)"
              />
            </g>
            <g>
              <path
                className="air_slide_7__cls-10"
                d="M955.93 352.86l-18.87 12.27a3.5 3.5 0 00.42 6.1l21.42 10.09a3.5 3.5 0 005-3.56l-2.56-22.36a3.5 3.5 0 00-5.41-2.54z"
              />
              <path
                className="air_slide_7__cls-11"
                d="M954.42 358l-12 7.79a2.22 2.22 0 00.26 3.87l13.6 6.4a2.23 2.23 0 003.16-2.26l-1.63-14.19a2.22 2.22 0 00-3.39-1.61z"
              />
            </g>
            <g>
              <path
                className="air_slide_7__cls-12"
                d="M991.7 386.82a2.38 2.38 0 01-1.17-.31l-4.95-2.86a2.33 2.33 0 01-1.16-2v-5.71a2.36 2.36 0 011.16-2l4.95-2.85a2.31 2.31 0 011.17-.32 2.4 2.4 0 011.16.31l4.95 2.86a2.36 2.36 0 011.16 2v5.71a2.33 2.33 0 01-1.16 2l-4.95 2.86a2.37 2.37 0 01-1.16.31z"
              />
              <path
                className="air_slide_7__cls-13"
                d="M991.7 372a1.08 1.08 0 01.55.15l5 2.86a1.11 1.11 0 01.55 1v5.71a1.12 1.12 0 01-.55 1l-5 2.85a1.1 1.1 0 01-1.11 0l-4.95-2.85a1.12 1.12 0 01-.55-1v-5.71a1.11 1.11 0 01.55-1l4.95-2.86a1.09 1.09 0 01.56-.15m0-2.44a3.57 3.57 0 00-1.78.48l-4.92 2.82a3.55 3.55 0 00-1.77 3.07v5.71a3.56 3.56 0 001.77 3.07l4.95 2.86a3.59 3.59 0 003.55 0l4.95-2.86a3.56 3.56 0 001.77-3.07v-5.71a3.55 3.55 0 00-1.77-3.07l-4.98-2.86a3.56 3.56 0 00-1.77-.48z"
              />
            </g>
            <g id="air_slide_7__bottom">
              <g id="air_slide_7__particles">
                <g
                  id="air_slide_7__bad_particle"
                  data-name="bad particle"
                >
                  <circle
                    className="air_slide_7__cls-5"
                    cx={1372.94}
                    cy={417.62}
                    r={12.67}
                  />
                  <circle
                    className="air_slide_7__cls-14"
                    cx={1372.94}
                    cy={417.62}
                    r={9.45}
                  />
                </g>
              </g>
              <g>

                <path
                  className="air_slide_7__cls-10"
                  d="M1379.19 403.51l-18.87 12.27a3.5 3.5 0 00.42 6.1l21.42 10.12a3.5 3.5 0 005-3.57l-2.59-22.43a3.49 3.49 0 00-5.38-2.49z"
                />
                <path
                  className="air_slide_7__cls-11"
                  d="M1377.68 408.66l-12 7.79a2.22 2.22 0 00.26 3.87l13.6 6.41a2.23 2.23 0 003.16-2.27l-1.63-14.19a2.22 2.22 0 00-3.39-1.61z"
                />
              </g>
              <g>
                <path
                  className="air_slide_7__cls-10"
                  d="M1379.19 403.51l-18.87 12.27a3.5 3.5 0 00.42 6.1l21.42 10.12a3.5 3.5 0 005-3.57l-2.59-22.43a3.49 3.49 0 00-5.38-2.49z"
                />
                <path
                  className="air_slide_7__cls-11"
                  d="M1377.68 408.66l-12 7.79a2.22 2.22 0 00.26 3.87l13.6 6.41a2.23 2.23 0 003.16-2.27l-1.63-14.19a2.22 2.22 0 00-3.39-1.61z"
                />
              </g>
              <g>
                <path
                  className="air_slide_7__cls-12"
                  d="M1372.94 430.43a3.74 3.74 0 01-1.85-.49l-7.88-4.56a3.71 3.71 0 01-1.86-3.21v-9.1a3.72 3.72 0 011.85-3.21l7.89-4.55a3.64 3.64 0 011.85-.5 3.71 3.71 0 011.86.5l7.88 4.55a3.7 3.7 0 011.85 3.21v9.1a3.72 3.72 0 01-1.85 3.21l-7.88 4.56a3.8 3.8 0 01-1.86.49z"
                />
                <path
                  className="air_slide_7__cls-13"
                  d="M1372.94 406.75a1.71 1.71 0 01.88.24l7.89 4.55a1.76 1.76 0 01.88 1.53v9.1a1.76 1.76 0 01-.88 1.53l-7.89 4.55a1.71 1.71 0 01-.88.24 1.77 1.77 0 01-.88-.24l-7.88-4.55a1.76 1.76 0 01-.88-1.53v-9.1a1.76 1.76 0 01.88-1.53l7.88-4.55a1.77 1.77 0 01.88-.24m0-3.88a5.69 5.69 0 00-2.82.75l-7.89 4.56a5.66 5.66 0 00-2.82 4.89v9.1a5.67 5.67 0 002.82 4.9l7.89 4.55a5.63 5.63 0 005.65 0l7.88-4.55a5.66 5.66 0 002.83-4.9v-9.1a5.64 5.64 0 00-2.83-4.89l-7.88-4.56a5.73 5.73 0 00-2.83-.75z"
                />
              </g>
              <g>
                <path
                  className="air_slide_7__cls-12"
                  d="M1372.94 430.43a3.74 3.74 0 01-1.85-.49l-7.88-4.56a3.71 3.71 0 01-1.86-3.21v-9.1a3.72 3.72 0 011.85-3.21l7.89-4.55a3.64 3.64 0 011.85-.5 3.71 3.71 0 011.86.5l7.88 4.55a3.7 3.7 0 011.85 3.21v9.1a3.72 3.72 0 01-1.85 3.21l-7.88 4.56a3.8 3.8 0 01-1.86.49z"
                />
                <path
                  className="air_slide_7__cls-13"
                  d="M1372.94 406.75a1.71 1.71 0 01.88.24l7.89 4.55a1.76 1.76 0 01.88 1.53v9.1a1.76 1.76 0 01-.88 1.53l-7.89 4.55a1.71 1.71 0 01-.88.24 1.77 1.77 0 01-.88-.24l-7.88-4.55a1.76 1.76 0 01-.88-1.53v-9.1a1.76 1.76 0 01.88-1.53l7.88-4.55a1.77 1.77 0 01.88-.24m0-3.88a5.69 5.69 0 00-2.82.75l-7.89 4.56a5.66 5.66 0 00-2.82 4.89v9.1a5.67 5.67 0 002.82 4.9l7.89 4.55a5.63 5.63 0 005.65 0l7.88-4.55a5.66 5.66 0 002.83-4.9v-9.1a5.64 5.64 0 00-2.83-4.89l-7.88-4.56a5.73 5.73 0 00-2.83-.75z"
                />
              </g>
            </g>
            <g id="air_slide_7__top">
              <g id="air_slide_7__particles-2" data-name="particles">
                <g
                  id="air_slide_7__bad_particle-2"
                  transform="translate(-1355 -315)"
                  data-name="bad particle"
                >
                  <circle
                    className="air_slide_7__cls-5"
                    cx={1355.43}
                    cy={315.32}
                    r={12.67}
                  />
                  <circle
                    className="air_slide_7__cls-14"
                    cx={1355.43}
                    cy={315.32}
                    r={9.45}
                  />
                </g>
              </g>
              <g>
                <path
                  className="air_slide_7__cls-10"
                  d="M1361.67 301.2l-18.86 12.27a3.5 3.5 0 00.42 6.1l21.42 10.09a3.5 3.5 0 005-3.56l-2.56-22.36a3.51 3.51 0 00-5.42-2.54z"
                />
                <path
                  className="air_slide_7__cls-11"
                  d="M1360.17 306.36l-12 7.79a2.22 2.22 0 00.26 3.87l13.6 6.4a2.22 2.22 0 003.15-2.26l-1.59-14.16a2.22 2.22 0 00-3.42-1.64z"
                />
              </g>
              <g>
                <path
                  className="air_slide_7__cls-12"
                  d="M1355.43 328.13a3.64 3.64 0 01-1.85-.5l-7.89-4.55a3.72 3.72 0 01-1.85-3.21v-9.11a3.69 3.69 0 011.85-3.2l7.89-4.56a3.74 3.74 0 013.7 0l7.89 4.56a3.69 3.69 0 011.85 3.2v9.11a3.72 3.72 0 01-1.85 3.21l-7.89 4.55a3.64 3.64 0 01-1.85.5z"
                />
                <path
                  className="air_slide_7__cls-13"
                  d="M1355.43 304.45a1.74 1.74 0 01.88.24l7.89 4.55a1.76 1.76 0 01.88 1.52v9.11a1.76 1.76 0 01-.88 1.52l-7.89 4.56a1.8 1.8 0 01-1.76 0l-7.89-4.56a1.76 1.76 0 01-.88-1.52v-9.11a1.76 1.76 0 01.88-1.52l7.89-4.55a1.74 1.74 0 01.88-.24m0-3.89a5.64 5.64 0 00-2.83.76l-7.88 4.55a5.68 5.68 0 00-2.83 4.89v9.11a5.67 5.67 0 002.83 4.89l7.88 4.55a5.64 5.64 0 002.83.76 5.57 5.57 0 002.82-.76l7.89-4.55a5.66 5.66 0 002.82-4.89v-9.11a5.67 5.67 0 00-2.82-4.89l-7.89-4.55a5.57 5.57 0 00-2.82-.76z"
                />
              </g>
            </g>
            <g mask="url(#air_slide_7__mask)">
              <g className="air_slide_7__cls-4">
                <g transform="rotate(-45 593.317 384.24)">
                  <rect
                    x={585.54}
                    y={376.47}
                    width={15.55}
                    height={15.55}
                    rx={7.01}
                  />
                  <rect
                    x={587.55}
                    y={378.48}
                    width={11.53}
                    height={11.53}
                    rx={5.76}
                  />
                </g>
                <g transform="rotate(-45 420.573 236.093)">
                  <rect
                    x={412.8}
                    y={228.32}
                    width={15.55}
                    height={15.55}
                    rx={7.01}
                  />
                  <rect
                    x={414.81}
                    y={230.33}
                    width={11.53}
                    height={11.53}
                    rx={5.76}
                  />
                </g>
                <g transform="rotate(-45 370.805 340.778)">
                  <rect
                    x={363.03}
                    y={333}
                    width={15.55}
                    height={15.55}
                    rx={7.01}
                  />
                  <rect
                    x={365.04}
                    y={335.01}
                    width={11.53}
                    height={11.53}
                    rx={5.76}
                  />
                </g>
                <g transform="rotate(-45 554.373 284.981)">
                  <rect
                    x={546.59}
                    y={277.21}
                    width={15.55}
                    height={15.55}
                    rx={7.01}
                  />
                  <rect
                    x={548.6}
                    y={279.22}
                    width={11.53}
                    height={11.53}
                    rx={5.76}
                  />
                </g>
                <path d="M955.93 352.86l-18.87 12.27a3.5 3.5 0 00.42 6.1l21.42 10.09a3.5 3.5 0 005-3.56l-2.56-22.36a3.5 3.5 0 00-5.41-2.54z" />
                <path d="M954.42 358l-12 7.79a2.22 2.22 0 00.26 3.87l13.6 6.4a2.23 2.23 0 003.16-2.26l-1.63-14.19a2.22 2.22 0 00-3.39-1.61z" />
                <g>
                  <path d="M991.7 386.82a2.38 2.38 0 01-1.17-.31l-4.95-2.86a2.33 2.33 0 01-1.16-2v-5.71a2.36 2.36 0 011.16-2l4.95-2.85a2.31 2.31 0 011.17-.32 2.4 2.4 0 011.16.31l4.95 2.86a2.36 2.36 0 011.16 2v5.71a2.33 2.33 0 01-1.16 2l-4.95 2.86a2.37 2.37 0 01-1.16.31z" />
                  <path d="M991.7 372a1.08 1.08 0 01.55.15l5 2.86a1.11 1.11 0 01.55 1v5.71a1.12 1.12 0 01-.55 1l-5 2.85a1.1 1.1 0 01-1.11 0l-4.95-2.85a1.12 1.12 0 01-.55-1v-5.71a1.11 1.11 0 01.55-1l4.95-2.86a1.09 1.09 0 01.56-.15m0-2.44a3.57 3.57 0 00-1.78.48l-4.92 2.82a3.55 3.55 0 00-1.77 3.07v5.71a3.56 3.56 0 001.77 3.07l4.95 2.86a3.59 3.59 0 003.55 0l4.95-2.86a3.56 3.56 0 001.77-3.07v-5.71a3.55 3.55 0 00-1.77-3.07l-4.98-2.86a3.56 3.56 0 00-1.77-.48z" />
                </g>
                <g id="air_slide_7__bottom-2" data-name="bottom">
                  <g id="air_slide_7__particles-3" data-name="particles">
                    <g id="air_slide_7__bad_particle-3" data-name="bad particle">
                      <circle cx={1372.94} cy={417.62} r={12.67} />
                      <circle cx={1372.94} cy={417.62} r={9.45} />
                    </g>
                  </g>
                  <g>
                    <path d="M1379.19 403.51l-18.87 12.27a3.5 3.5 0 00.42 6.1l21.42 10.12a3.5 3.5 0 005-3.57l-2.59-22.43a3.49 3.49 0 00-5.38-2.49z" />
                    <path d="M1377.68 408.66l-12 7.79a2.22 2.22 0 00.26 3.87l13.6 6.41a2.23 2.23 0 003.16-2.27l-1.63-14.19a2.22 2.22 0 00-3.39-1.61z" />
                  </g>
                  <path d="M1379.19 403.51l-18.87 12.27a3.5 3.5 0 00.42 6.1l21.42 10.12a3.5 3.5 0 005-3.57l-2.59-22.43a3.49 3.49 0 00-5.38-2.49z" />
                  <path d="M1377.68 408.66l-12 7.79a2.22 2.22 0 00.26 3.87l13.6 6.41a2.23 2.23 0 003.16-2.27l-1.63-14.19a2.22 2.22 0 00-3.39-1.61z" />
                  <g>
                    <path d="M1372.94 430.43a3.74 3.74 0 01-1.85-.49l-7.88-4.56a3.71 3.71 0 01-1.86-3.21v-9.1a3.72 3.72 0 011.85-3.21l7.89-4.55a3.64 3.64 0 011.85-.5 3.71 3.71 0 011.86.5l7.88 4.55a3.7 3.7 0 011.85 3.21v9.1a3.72 3.72 0 01-1.85 3.21l-7.88 4.56a3.8 3.8 0 01-1.86.49z" />
                    <path d="M1372.94 406.75a1.71 1.71 0 01.88.24l7.89 4.55a1.76 1.76 0 01.88 1.53v9.1a1.76 1.76 0 01-.88 1.53l-7.89 4.55a1.71 1.71 0 01-.88.24 1.77 1.77 0 01-.88-.24l-7.88-4.55a1.76 1.76 0 01-.88-1.53v-9.1a1.76 1.76 0 01.88-1.53l7.88-4.55a1.77 1.77 0 01.88-.24m0-3.88a5.69 5.69 0 00-2.82.75l-7.89 4.56a5.66 5.66 0 00-2.82 4.89v9.1a5.67 5.67 0 002.82 4.9l7.89 4.55a5.63 5.63 0 005.65 0l7.88-4.55a5.66 5.66 0 002.83-4.9v-9.1a5.64 5.64 0 00-2.83-4.89l-7.88-4.56a5.73 5.73 0 00-2.83-.75z" />
                  </g>
                  <g>
                    <path d="M1372.94 430.43a3.74 3.74 0 01-1.85-.49l-7.88-4.56a3.71 3.71 0 01-1.86-3.21v-9.1a3.72 3.72 0 011.85-3.21l7.89-4.55a3.64 3.64 0 011.85-.5 3.71 3.71 0 011.86.5l7.88 4.55a3.7 3.7 0 011.85 3.21v9.1a3.72 3.72 0 01-1.85 3.21l-7.88 4.56a3.8 3.8 0 01-1.86.49z" />
                    <path d="M1372.94 406.75a1.71 1.71 0 01.88.24l7.89 4.55a1.76 1.76 0 01.88 1.53v9.1a1.76 1.76 0 01-.88 1.53l-7.89 4.55a1.71 1.71 0 01-.88.24 1.77 1.77 0 01-.88-.24l-7.88-4.55a1.76 1.76 0 01-.88-1.53v-9.1a1.76 1.76 0 01.88-1.53l7.88-4.55a1.77 1.77 0 01.88-.24m0-3.88a5.69 5.69 0 00-2.82.75l-7.89 4.56a5.66 5.66 0 00-2.82 4.89v9.1a5.67 5.67 0 002.82 4.9l7.89 4.55a5.63 5.63 0 005.65 0l7.88-4.55a5.66 5.66 0 002.83-4.9v-9.1a5.64 5.64 0 00-2.83-4.89l-7.88-4.56a5.73 5.73 0 00-2.83-.75z" />
                  </g>
                </g>
                <g id="air_slide_7__top-2" data-name="top">
                  <g id="air_slide_7__particles-4" data-name="particles">
                    <g id="air_slide_7__bad_particle-4" data-name="bad particle">
                      <circle cx={1355.43} cy={315.32} r={12.67} />
                      <circle cx={1355.43} cy={315.32} r={9.45} />
                    </g>
                  </g>
                  <path d="M1361.67 301.2l-18.86 12.27a3.5 3.5 0 00.42 6.1l21.42 10.09a3.5 3.5 0 005-3.56l-2.56-22.36a3.51 3.51 0 00-5.42-2.54z" />
                  <path d="M1360.17 306.36l-12 7.79a2.22 2.22 0 00.26 3.87l13.6 6.4a2.22 2.22 0 003.15-2.26l-1.59-14.16a2.22 2.22 0 00-3.42-1.64z" />
                  <g>
                    <path d="M1355.43 328.13a3.64 3.64 0 01-1.85-.5l-7.89-4.55a3.72 3.72 0 01-1.85-3.21v-9.11a3.69 3.69 0 011.85-3.2l7.89-4.56a3.74 3.74 0 013.7 0l7.89 4.56a3.69 3.69 0 011.85 3.2v9.11a3.72 3.72 0 01-1.85 3.21l-7.89 4.55a3.64 3.64 0 01-1.85.5z" />
                    <path d="M1355.43 304.45a1.74 1.74 0 01.88.24l7.89 4.55a1.76 1.76 0 01.88 1.52v9.11a1.76 1.76 0 01-.88 1.52l-7.89 4.56a1.8 1.8 0 01-1.76 0l-7.89-4.56a1.76 1.76 0 01-.88-1.52v-9.11a1.76 1.76 0 01.88-1.52l7.89-4.55a1.74 1.74 0 01.88-.24m0-3.89a5.64 5.64 0 00-2.83.76l-7.88 4.55a5.68 5.68 0 00-2.83 4.89v9.11a5.67 5.67 0 002.83 4.89l7.88 4.55a5.64 5.64 0 002.83.76 5.57 5.57 0 002.82-.76l7.89-4.55a5.66 5.66 0 002.82-4.89v-9.11a5.67 5.67 0 00-2.82-4.89l-7.89-4.55a5.57 5.57 0 00-2.82-.76z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <path
            className="air_slide_7__cls-7 cloud"
            d="M380.51 264.69a15.83 15.83 0 00-14.41-15.76 15.83 15.83 0 00-15.3-11.81h-2.08a15.81 15.81 0 00-15.28 11.75h-4.54a15.84 15.84 0 00-15.82 15.82v1.65a15.48 15.48 0 00.78 4.91 2.49 2.49 0 002.36 1.69h61.16a2.46 2.46 0 002.34-1.69 15.21 15.21 0 00.79-4.91v-.82-.83z"
          />
          <path
            className="air_slide_7__cls-7 cloud"
            d="M630.79 336a12.19 12.19 0 00-11.09-12.13 12.19 12.19 0 00-11.78-9.09h-1.6a12.18 12.18 0 00-11.76 9.05h-3.49A12.19 12.19 0 00578.89 336v1.27a11.92 11.92 0 00.6 3.78 1.91 1.91 0 001.81 1.31h47.08a1.91 1.91 0 001.81-1.31 11.92 11.92 0 00.6-3.78v-.63-.64z"
          />
          <g id="air_slide_7__wind">
            <g>
              <path
                className="air_slide_7__cls-7"
                d="M783.94 325.35c6.37-.62 10.58 3.15 12.73 5.58a.5.5 0 01-.47.83c-2.57-.46-5.85-.82-8.28.35a7.21 7.21 0 00-3.63 7.74 7 7 0 005.54 5.24 8.88 8.88 0 005.62-1.13c6.71-3.63 9.85-11.35 6.49-18.41-5.17-10.88-23-10.68-32.39-6.12a.41.41 0 01-.45-.68c3.91-3.33 7.85-7.06 12.87-8.58a29.72 29.72 0 0118.43.87c7.21 2.68 15.16 8.8 16.39 16.82a27.27 27.27 0 01-4.07 18.37 25.76 25.76 0 01-14.62 10.58c-6.55 1.93-14.53 1.78-20.15-2.48-10.35-7.86-9.86-26 4.55-28.77a12.5 12.5 0 011.44-.21"
              />
            </g>
            <g>
              <path
                id="air_slide_7__wind-2"
                data-name="wind"
                className="air_slide_7__cls-7"
                d="M711.92 287.05c-6.14-1.8-8.64-6.87-9.73-9.93a.5.5 0 01.74-.59c2.22 1.38 5.13 2.94 7.82 2.76a7.22 7.22 0 006.26-5.83 7 7 0 00-3.2-6.93 8.92 8.92 0 00-5.63-1c-7.58.88-13.37 6.86-12.89 14.67.75 12 17.33 18.47 27.78 17.75a.41.41 0 01.16.8c-4.87 1.63-9.91 3.63-15.14 3.16a29.68 29.68 0 01-16.78-7.67c-5.68-5.18-10.78-13.82-8.94-21.72A27.32 27.32 0 01693 256.94a25.79 25.79 0 0117.51-4.37c6.8.66 14.15 3.77 17.77 9.82 6.68 11.14-.53 27.77-14.94 25-.5-.1-1-.21-1.42-.34"
              />
            </g>
          </g>
          <g clipPath="url(#air_slide_7__clip-path)">
            <g id="air_slide_7__FG">
              <path
                className="air_slide_7__cls-2"
                d="M1124.31 631.41a3.36 3.36 0 013.94.73 1.88 1.88 0 01.12.18l9.35 16.41 7.1-32.6a3.21 3.21 0 012.94-2.36 3.14 3.14 0 013.26 1.83.84.84 0 010 .17l6.81 30.4 10.63-16.45a1.37 1.37 0 01.26-.28 3.47 3.47 0 013.56-.42 2.86 2.86 0 011.75 2.84l-2.1 27.63a1.13 1.13 0 01-1 1l-39.92 5.9a1.12 1.12 0 01-1.28-.8l-7.26-30.79a3 3 0 011.84-3.39z"
              />
              <g id="air_slide_7__chinese_house" data-name="chinese house">
                <path
                  className="air_slide_7__cls-2"
                  d="M447.41 589.06h-45.36a1.18 1.18 0 00-1.19 1.18v31.15a1.18 1.18 0 001.19 1.18h45.36a1.18 1.18 0 001.19-1.18v-31.15a1.18 1.18 0 00-1.19-1.18zm-33.59 23.45a1.67 1.67 0 01-1.67 1.67h-6.28a1.67 1.67 0 01-1.67-1.67V602a1.67 1.67 0 011.67-1.67h6.28a1.67 1.67 0 011.67 1.67zm30.11 0a1.68 1.68 0 01-1.68 1.67H436a1.68 1.68 0 01-1.68-1.67V602a1.68 1.68 0 011.68-1.67h6.27a1.68 1.68 0 011.68 1.67zM443.05 567.05h-36.41a1.19 1.19 0 00-1.19 1.19v2.12a1.19 1.19 0 001.19 1.19h36.41a1.19 1.19 0 001.19-1.19v-2.12a1.19 1.19 0 00-1.19-1.19z"
                />
                <path
                  className="air_slide_7__cls-2"
                  d="M451.16 590h-51.63a1.18 1.18 0 00-1.19 1.18v3a1.19 1.19 0 001.19 1.19h51.63a1.19 1.19 0 001.19-1.19v-3a1.18 1.18 0 00-1.19-1.18zM456.79 586.37l-10.1-7.37-5.38-8.66a1.18 1.18 0 00-1-.56h-30.43a1.2 1.2 0 00-1 .57l-5.26 8.57-10.11 7.47a1.19 1.19 0 00.71 2.14h61.89a1.19 1.19 0 001.13-.82 1.17 1.17 0 00-.45-1.34zM417.19 622.58h13.64v-22a3 3 0 00-3-3h-7.64a3 3 0 00-3 3z"
                />
                <path
                  className="air_slide_7__cls-2"
                  d="M460.48 584.69h-68.8a1.18 1.18 0 00-1.19 1.18v5.28a1.18 1.18 0 001.19 1.18h68.8a1.18 1.18 0 001.19-1.18v-5.28a1.18 1.18 0 00-1.19-1.18z"
                />
              </g>
              <g id="air_slide_7__small_car-2" data-name="small car">
                <path
                  className="air_slide_7__cls-2"
                  d="M533.57 640a2.88 2.88 0 002.12-1.9l1.38-4.09a7.64 7.64 0 00-4.84-9.69l-9.43-3.15-.53-1.47-.31-.84-4.43-12.19-.19-.52a2.35 2.35 0 00-1.46-1.41l-20.47-6.84a2.29 2.29 0 00-2.68.92L486.34 609l-3.82 11.34A2.86 2.86 0 00483 623a2.86 2.86 0 01-.49-2.73l-.52 1.53a2.89 2.89 0 001.83 3.66l47.67 15.93a2.91 2.91 0 003.68-1.83l.51-1.52a2.88 2.88 0 01-2.11 1.96zm-24.92-33.69a1.48 1.48 0 011.86-.93l4.53 1.52 4.45 12.82-12.41-4.15a1.46 1.46 0 01-.93-1.85zm-18.78 1.91l4.4-7.21a1.1 1.1 0 011.28-.49l9.37 3.13a1.18 1.18 0 01.74 1.48l-2.61 7.73a1.17 1.17 0 01-1.49.74l-11.14-3.72a1.12 1.12 0 01-.55-1.7z"
                />
                <path
                  className="air_slide_7__cls-2"
                  d="M532.95 632.04l-18.17-6.57-1.17 9.96 16.36 5.46 2.98-8.85z"
                />
                <g clipPath="url(#air_slide_7__clip-path-2)">
                  <ellipse
                    className="air_slide_7__cls-2"
                    cx={521.7}
                    cy={637.2}
                    rx={7.82}
                    ry={8.11}
                    transform="rotate(-73.54 521.666 637.187)"
                  />
                </g>
                <g id="air_slide_7__hjul">
                  <ellipse
                    className="air_slide_7__cls-2"
                    cx={521.72}
                    cy={637.15}
                    rx={6.86}
                    ry={7.12}
                    transform="rotate(-73.54 521.683 637.137)"
                  />
                  <ellipse
                    className="air_slide_7__cls-2"
                    cx={521.7}
                    cy={637.2}
                    rx={4.22}
                    ry={4.38}
                    transform="rotate(-73.54 521.666 637.187)"
                  />
                </g>
                <path
                  className="air_slide_7__cls-2"
                  d="M503.92 622.35l-18.16-6.58-1.17 9.96 16.35 5.47 2.98-8.85z"
                />
                <g clipPath="url(#air_slide_7__clip-path-3)">
                  <ellipse
                    className="air_slide_7__cls-2"
                    cx={492.67}
                    cy={627.5}
                    rx={7.82}
                    ry={8.11}
                    transform="rotate(-73.54 492.645 627.492)"
                  />
                </g>
                <g id="air_slide_7__hjul-2" data-name="hjul">
                  <ellipse
                    className="air_slide_7__cls-2"
                    cx={492.69}
                    cy={627.45}
                    rx={6.86}
                    ry={7.12}
                    transform="rotate(-73.54 492.662 627.442)"
                  />
                  <ellipse
                    className="air_slide_7__cls-2"
                    cx={492.67}
                    cy={627.5}
                    rx={4.22}
                    ry={4.38}
                    transform="rotate(-73.54 492.645 627.492)"
                  />
                </g>
                <path
                  className="air_slide_7__cls-2"
                  d="M529.83 625.73l3.79 1.27a3.75 3.75 0 012.38 4.77l-5.71-1.91a1.75 1.75 0 01-1.1-2.21zM522.57 621.83l-1.06-.35-.31-.88-4.99-14.41-.27-.77.53-.09.4 1.09 5.37 14.52.33.89z"
                />
              </g>
              <path
                className="air_slide_7__cls-2"
                d="M341.79 595.69h2.82v29.63h-2.82z"
              />
              <path
                className="air_slide_7__cls-2"
                d="M336.44 598v-2.63h-1.86V598c0 5.57 4.74 8.38 9.54 8.45v-1.62c-3.87-.05-7.68-2.33-7.68-6.83zM351 594.26v-2.79h1.66v2.79c0 5.94-4.23 9-8.51 9v-1.72a6.86 6.86 0 006.85-7.28z"
              />
              <path
                className="air_slide_7__cls-2"
                d="M362.86 592.66v1.88a2.18 2.18 0 01-2.2 2.18h-36.11a2.18 2.18 0 01-2.19-2.18v-1.39-.36a2.19 2.19 0 002.18 2.06h36.11a2.19 2.19 0 002.21-2.19z"
              />
              <path
                className="air_slide_7__cls-2"
                d="M362.86 591.59v1.07a2.19 2.19 0 01-2.2 2.19h-36.11a2.19 2.19 0 01-2.18-2.06 15.17 15.17 0 0110-13.89 15.29 15.29 0 015.17-4.09 15.09 15.09 0 016.67-1.53 13.67 13.67 0 0111.57 6.39 13.62 13.62 0 017.08 11.92z"
              />
              <path
                className="air_slide_7__cls-2"
                d="M362.86 591.59v1.07a2.19 2.19 0 01-2.2 2.19h-36.11a2.19 2.19 0 01-2.18-2.06 15.17 15.17 0 0110-13.89 15.29 15.29 0 015.17-4.09 15.09 15.09 0 016.67-1.53 13.67 13.67 0 0111.57 6.39 13.62 13.62 0 017.08 11.92z"
              />
              <path
                className="air_slide_7__cls-2"
                d="M0 0v797h1440V0zm1268.59 622a475.77 475.77 0 01-124.47 36.72c-265.4 41.42-357.2 35.27-545.3 4.82-54.62-8.85-140.13-48.1-178.69-47.45-78.14 1.32-173.22 33.72-234.77-5.6s-90.22-86.69-80.44-189.26C118 284.19 156 145 303.13 138s657.21-35.95 750.87-20 142.79 63.75 187.71 139c74.82 125.54 102.06 331.64 26.88 365z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AirNoAnimationSlide7;
