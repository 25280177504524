import * as React from 'react';

function AirNoAnimationSlide2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <style>
          {
            '.air_slide_2__cls-2{opacity:.4;mix-blend-mode:multiply}.air_slide_2__cls-3{fill:#e37a39}.air_slide_2__cls-4{fill:#faae5c}.air_slide_2__cls-5{fill:#fcee21}.air_slide_2__cls-6{fill:#d9e021}.air_slide_2__cls-7{fill:#999}.air_slide_2__cls-8{fill:gray}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="air_slide_2__Layer_1" data-name="Layer 1">
          <g id="air_slide_2__particles">
            <path
              className="air_slide_2__cls-3"
              d="M372.05 526.75l10.77-5.55a1.89 1.89 0 00.12-3.3L372 511.3a1.88 1.88 0 00-2.86 1.64l.14 12.15a1.88 1.88 0 002.77 1.66z"
            />
            <path
              className="air_slide_2__cls-4"
              d="M373.14 524.06l6.84-3.52a1.21 1.21 0 00.07-2.1l-6.93-4.18a1.19 1.19 0 00-1.81 1l.09 7.71a1.19 1.19 0 001.74 1.09z"
            />
            <g>
              <path
                className="air_slide_2__cls-3"
                d="M229.92 468.47l-1.76-11.06a1.73 1.73 0 00-2.85-1l-8.88 7.7a1.74 1.74 0 00.61 3l10.64 3.36a1.74 1.74 0 002.24-2z"
              />
              <path
                className="air_slide_2__cls-4"
                d="M227.88 466.74l-1.11-7a1.1 1.1 0 00-1.81-.67l-5.64 4.93a1.11 1.11 0 00.39 1.89l6.76 2.13a1.09 1.09 0 001.41-1.28z"
              />
            </g>
          </g>
          <g id="air_slide_2__particles-2" data-name="particles">
            <path
              className="air_slide_2__cls-3"
              d="M920.53 454l17.52 1.32a2.74 2.74 0 002.63-4l-8.52-16.47a2.72 2.72 0 00-4.77-.14l-9 15.15a2.75 2.75 0 002.14 4.14z"
            />
            <path
              className="air_slide_2__cls-4"
              d="M923.91 451.46l11.12.84a1.75 1.75 0 001.67-2.54l-5.41-10.45a1.73 1.73 0 00-3-.09l-5.71 9.61a1.74 1.74 0 001.33 2.63z"
            />
            <g>
              <path
                className="air_slide_2__cls-3"
                d="M868.26 495.1l15.13-9a2.74 2.74 0 00-.12-4.79l-16.36-8.63a2.73 2.73 0 00-4 2.62l1.24 17.59a2.73 2.73 0 004.11 2.21z"
              />
              <path
                className="air_slide_2__cls-4"
                d="M869.61 491.11l9.6-5.68a1.74 1.74 0 00-.07-3l-10.39-5.48a1.73 1.73 0 00-2.53 1.66l.78 11.17a1.73 1.73 0 002.61 1.33z"
              />
            </g>
          </g>
          <g id="air_slide_2__PARTIKLER">
            <g>
              <path
                className="air_slide_2__cls-3"
                d="M582.2 223.53l19.08 5.92a3.11 3.11 0 003.92-3.76l-5.29-20.42a3.1 3.1 0 00-5.25-1.37l-13.79 14.5a3.12 3.12 0 001.33 5.13z"
              />
              <path
                className="air_slide_2__cls-4"
                d="M586.57 221.62l12.11 3.76a2 2 0 002.49-2.39l-3.36-13a2 2 0 00-3.34-.87l-8.75 9.21a2 2 0 00.85 3.29z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-3"
                d="M582.2 223.53l19.08 5.92a3.11 3.11 0 003.92-3.76l-5.29-20.42a3.1 3.1 0 00-5.25-1.37l-13.79 14.5a3.12 3.12 0 001.33 5.13z"
              />
              <path
                className="air_slide_2__cls-4"
                d="M586.57 221.62l12.11 3.76a2 2 0 002.49-2.39l-3.36-13a2 2 0 00-3.34-.87l-8.75 9.21a2 2 0 00.85 3.29z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-5"
                d="M594 228.23a1.64 1.64 0 01-.65-.13l-9.54-4a1.69 1.69 0 01-1-1.34l-1.34-10.28a1.69 1.69 0 01.65-1.55l8.2-6.3a1.69 1.69 0 011-.35 1.64 1.64 0 01.65.13l9.54 4a1.69 1.69 0 011 1.33l1.35 10.26a1.68 1.68 0 01-.64 1.56l-8.2 6.3a1.69 1.69 0 01-1.02.37z"
              />
              <path
                className="air_slide_2__cls-6"
                d="M591.32 206l9.54 4 1.33 10.28-8.2 6.31-9.54-4-1.34-10.29 8.21-6.3m0-3.37a3.34 3.34 0 00-2.05.7l-8.21 6.3a3.37 3.37 0 00-1.28 3.11l1.33 10.26a3.38 3.38 0 002 2.67l9.54 4a3.2 3.2 0 001.29.26 3.33 3.33 0 002-.69l8.21-6.31a3.35 3.35 0 001.28-3.1l-1.33-10.28a3.38 3.38 0 00-2.05-2.68l-9.54-4a3.38 3.38 0 00-1.29-.26z"
              />
            </g>
            <g>

              <path
                className="air_slide_2__cls-5"
                d="M594 228.23a1.64 1.64 0 01-.65-.13l-9.54-4a1.69 1.69 0 01-1-1.34l-1.34-10.28a1.69 1.69 0 01.65-1.55l8.2-6.3a1.69 1.69 0 011-.35 1.64 1.64 0 01.65.13l9.54 4a1.69 1.69 0 011 1.33l1.35 10.26a1.68 1.68 0 01-.64 1.56l-8.2 6.3a1.69 1.69 0 01-1.02.37z"
              />
              <path
                className="air_slide_2__cls-6"
                d="M591.32 206l9.54 4 1.33 10.28-8.2 6.31-9.54-4-1.34-10.29 8.21-6.3m0-3.37a3.34 3.34 0 00-2.05.7l-8.21 6.3a3.37 3.37 0 00-1.28 3.11l1.33 10.26a3.38 3.38 0 002 2.67l9.54 4a3.2 3.2 0 001.29.26 3.33 3.33 0 002-.69l8.21-6.31a3.35 3.35 0 001.28-3.1l-1.33-10.28a3.38 3.38 0 00-2.05-2.68l-9.54-4a3.38 3.38 0 00-1.29-.26z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-7"
                d="M600.8 227.92a3.44 3.44 0 01-2.45-1l-5.7-5.72-5.65 5.7a3.46 3.46 0 01-4.91 0 3.48 3.48 0 010-4.91l5.71-5.73-5.71-5.72a3.48 3.48 0 012.46-5.93 3.44 3.44 0 012.45 1l5.69 5.72 5.7-5.72a3.46 3.46 0 014.91 0 3.48 3.48 0 010 4.91l-5.71 5.72 5.71 5.73a3.48 3.48 0 01-2.46 5.93z"
              />
              <path
                className="air_slide_2__cls-8"
                d="M600.8 206.37a1.71 1.71 0 011.21 2.92l-6.94 7 6.94 7a1.71 1.71 0 010 2.42 1.68 1.68 0 01-2.41 0l-7-7-6.94 7a1.68 1.68 0 01-2.41 0 1.71 1.71 0 010-2.42l6.94-7-6.94-7a1.71 1.71 0 010-2.42 1.7 1.7 0 012.41 0l6.94 7 7-7a1.71 1.71 0 011.2-.5m0-3.52a5.17 5.17 0 00-3.7 1.54l-4.45 4.46-4.44-4.46a5.23 5.23 0 00-7.41 0 5.24 5.24 0 000 7.39l4.47 4.48-4.47 4.49a5.23 5.23 0 107.41 7.39l4.44-4.46 4.45 4.46a5.23 5.23 0 107.41-7.39l-4.51-4.49 4.47-4.48a5.24 5.24 0 000-7.39 5.18 5.18 0 00-3.71-1.54z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-7"
                d="M600.8 227.92a3.44 3.44 0 01-2.45-1l-5.7-5.72-5.65 5.7a3.46 3.46 0 01-4.91 0 3.48 3.48 0 010-4.91l5.71-5.73-5.71-5.72a3.48 3.48 0 012.46-5.93 3.44 3.44 0 012.45 1l5.69 5.72 5.7-5.72a3.46 3.46 0 014.91 0 3.48 3.48 0 010 4.91l-5.71 5.72 5.71 5.73a3.48 3.48 0 01-2.46 5.93z"
              />
              <path
                className="air_slide_2__cls-8"
                d="M600.8 206.37a1.71 1.71 0 011.21 2.92l-6.94 7 6.94 7a1.71 1.71 0 010 2.42 1.68 1.68 0 01-2.41 0l-7-7-6.94 7a1.68 1.68 0 01-2.41 0 1.71 1.71 0 010-2.42l6.94-7-6.94-7a1.71 1.71 0 010-2.42 1.7 1.7 0 012.41 0l6.94 7 7-7a1.71 1.71 0 011.2-.5m0-3.52a5.17 5.17 0 00-3.7 1.54l-4.45 4.46-4.44-4.46a5.23 5.23 0 00-7.41 0 5.24 5.24 0 000 7.39l4.47 4.48-4.47 4.49a5.23 5.23 0 107.41 7.39l4.44-4.46 4.45 4.46a5.23 5.23 0 107.41-7.39l-4.51-4.49 4.47-4.48a5.24 5.24 0 000-7.39 5.18 5.18 0 00-3.71-1.54z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-3"
                d="M585.94 229l17.42-9.8a3.12 3.12 0 000-5.45L585 203.52a3.11 3.11 0 00-4.61 2.88l1 20a3.11 3.11 0 004.55 2.6z"
              />
              <path
                className="air_slide_2__cls-4"
                d="M587.57 224.5l11.06-6.22a2 2 0 000-3.46L587 208.33a2 2 0 00-2.93 1.83l.61 12.71a2 2 0 002.89 1.63z"
              />
            </g>
            {/* ------------- motion path 2 ---------------- */}
            <g>
              <path
                className="air_slide_2__cls-3"
                d="M585.94 229l17.42-9.8a3.12 3.12 0 000-5.45L585 203.52a3.11 3.11 0 00-4.61 2.88l1 20a3.11 3.11 0 004.55 2.6z"
              />
              <path
                className="air_slide_2__cls-4"
                d="M587.57 224.5l11.06-6.22a2 2 0 000-3.46L587 208.33a2 2 0 00-2.93 1.83l.61 12.71a2 2 0 002.89 1.63z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-3"
                d="M588 228.69L604.17 217a3.13 3.13 0 00-.62-5.42l-19.41-8.09a3.11 3.11 0 00-4.26 3.38l3.19 19.79a3.11 3.11 0 004.93 2.03z"
              />
              <path
                className="air_slide_2__cls-4"
                d="M589.07 224l10.3-7.42a2 2 0 00-.4-3.44l-12.31-5.13a2 2 0 00-2.71 2.14l2 12.56a2 2 0 003.12 1.29z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-5"
                d="M594.46 228.16a2 2 0 01-.58-.1l-9.71-3.54a1.69 1.69 0 01-1.08-1.29L581.28 213a1.7 1.7 0 01.58-1.58l7.9-6.67a1.71 1.71 0 011.09-.4 1.69 1.69 0 01.58.1l9.71 3.54a1.66 1.66 0 011.08 1.29l1.8 10.21a1.67 1.67 0 01-.57 1.58l-7.91 6.67a1.66 1.66 0 01-1.08.42z"
              />
              <path
                className="air_slide_2__cls-6"
                d="M590.85 206.05l9.71 3.54 1.81 10.21-7.91 6.67-9.71-3.53-1.81-10.21 7.91-6.68m0-3.36a3.37 3.37 0 00-2.17.79l-7.91 6.67a3.36 3.36 0 00-1.14 3.16l1.8 10.21a3.37 3.37 0 002.17 2.58l9.71 3.54a3.48 3.48 0 001.15.2 3.37 3.37 0 002.17-.79l7.91-6.68a3.37 3.37 0 001.14-3.16l-1.8-10.21a3.37 3.37 0 00-2.17-2.57l-9.71-3.54a3.24 3.24 0 00-1.15-.2z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-5"
                d="M594.46 228.16a2 2 0 01-.58-.1l-9.71-3.54a1.69 1.69 0 01-1.08-1.29L581.28 213a1.7 1.7 0 01.58-1.58l7.9-6.67a1.71 1.71 0 011.09-.4 1.69 1.69 0 01.58.1l9.71 3.54a1.66 1.66 0 011.08 1.29l1.8 10.21a1.67 1.67 0 01-.57 1.58l-7.91 6.67a1.66 1.66 0 01-1.08.42z"
              />
              <path
                className="air_slide_2__cls-6"
                d="M590.85 206.05l9.71 3.54 1.81 10.21-7.91 6.67-9.71-3.53-1.81-10.21 7.91-6.68m0-3.36a3.37 3.37 0 00-2.17.79l-7.91 6.67a3.36 3.36 0 00-1.14 3.16l1.8 10.21a3.37 3.37 0 002.17 2.58l9.71 3.54a3.48 3.48 0 001.15.2 3.37 3.37 0 002.17-.79l7.91-6.68a3.37 3.37 0 001.14-3.16l-1.8-10.21a3.37 3.37 0 00-2.17-2.57l-9.71-3.54a3.24 3.24 0 00-1.15-.2z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-7"
                d="M586.05 229.22a3.43 3.43 0 01-2-.63 3.47 3.47 0 01-.86-4.83l4.62-6.64-6.61-4.64a3.47 3.47 0 012-6.32 3.43 3.43 0 012 .63l6.61 4.63 4.61-6.63a3.47 3.47 0 012.85-1.48 3.42 3.42 0 012 .62 3.49 3.49 0 01.86 4.84l-4.62 6.64 6.61 4.63a3.47 3.47 0 01-2 6.32 3.41 3.41 0 01-2-.63l-6.6-4.63-4.61 6.63a3.48 3.48 0 01-2.85 1.49z"
              />
              <path
                className="air_slide_2__cls-8"
                d="M599.26 205.07a1.69 1.69 0 011 .31 1.71 1.71 0 01.42 2.38l-5.68 8.08 8.05 5.65a1.71 1.71 0 01.42 2.38 1.69 1.69 0 01-1.4.73 1.65 1.65 0 01-1-.31l-8-5.64-5.63 8.08a1.71 1.71 0 01-1.4.73 1.65 1.65 0 01-1-.31 1.7 1.7 0 01-.42-2.38l5.62-8.08-8-5.65a1.71 1.71 0 01-.42-2.38 1.69 1.69 0 011.4-.73 1.71 1.71 0 011 .3l8.05 5.65 5.63-8.08a1.69 1.69 0 011.4-.73m0-3.53a5.21 5.21 0 00-4.29 2.25l-3.65 5.21-5.15-3.62a5.23 5.23 0 10-6 8.57l5.17 3.63-3.62 5.2a5.23 5.23 0 108.58 6l3.61-5.18 5.15 3.62a5.23 5.23 0 006-8.58l-5.17-3.64 3.61-5.2a5.23 5.23 0 00-4.28-8.23z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-7"
                d="M600.8 227.92a3.44 3.44 0 01-2.45-1l-5.7-5.72-5.65 5.7a3.46 3.46 0 01-4.91 0 3.48 3.48 0 010-4.91l5.71-5.73-5.71-5.72a3.48 3.48 0 012.46-5.93 3.44 3.44 0 012.45 1l5.69 5.72 5.7-5.72a3.46 3.46 0 014.91 0 3.48 3.48 0 010 4.91l-5.71 5.72 5.71 5.73a3.48 3.48 0 01-2.46 5.93z"
              />
              <path
                className="air_slide_2__cls-8"
                d="M600.8 206.37a1.71 1.71 0 011.21 2.92l-6.94 7 6.94 7a1.71 1.71 0 010 2.42 1.68 1.68 0 01-2.41 0l-7-7-6.94 7a1.68 1.68 0 01-2.41 0 1.71 1.71 0 010-2.42l6.94-7-6.94-7a1.71 1.71 0 010-2.42 1.7 1.7 0 012.41 0l6.94 7 7-7a1.71 1.71 0 011.2-.5m0-3.52a5.17 5.17 0 00-3.7 1.54l-4.45 4.46-4.44-4.46a5.23 5.23 0 00-7.41 0 5.24 5.24 0 000 7.39l4.47 4.48-4.47 4.49a5.23 5.23 0 107.41 7.39l4.44-4.46 4.45 4.46a5.23 5.23 0 107.41-7.39l-4.51-4.49 4.47-4.48a5.24 5.24 0 000-7.39 5.18 5.18 0 00-3.71-1.54z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-3"
                d="M585.63 229l17.6-9.49a3.13 3.13 0 00.08-5.45l-18.2-10.55a3.11 3.11 0 00-4.66 2.8l.61 20a3.1 3.1 0 004.57 2.69z"
              />
              <path
                className="air_slide_2__cls-4"
                d="M587.35 224.56l11.16-6a2 2 0 000-3.46L587 208.39a2 2 0 00-3 1.77l.39 12.72a2 2 0 002.96 1.68z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-3"
                d="M583.17 228.84l19.83-2.67a3.11 3.11 0 002-5.06l-13.38-16.31a3.1 3.1 0 00-5.34 1l-6.46 19a3.11 3.11 0 003.35 4.04z"
              />
              <path
                className="air_slide_2__cls-4"
                d="M586.34 225.26l12.56-1.69a2 2 0 001.26-3.21L591.7 210a2 2 0 00-3.39.61l-4.1 12a2 2 0 002.13 2.65z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-5"
                d="M593.22 228.3a1.7 1.7 0 01-.76-.18l-9.22-4.68a1.72 1.72 0 01-.92-1.41l-.56-10.36a1.65 1.65 0 01.76-1.5l8.64-5.67a1.72 1.72 0 011.69-.09l9.22 4.68a1.71 1.71 0 01.92 1.41l.56 10.35a1.67 1.67 0 01-.76 1.51l-8.65 5.64a1.68 1.68 0 01-.92.3z"
              />
              <path
                className="air_slide_2__cls-6"
                d="M592.09 205.91l9.21 4.68.57 10.36-8.65 5.67-9.22-4.69-.57-10.35 8.65-5.67m0-3.37a3.42 3.42 0 00-1.85.55l-8.65 5.67a3.37 3.37 0 00-1.51 3l.56 10.36a3.39 3.39 0 001.84 2.82l9.22 4.68a3.4 3.4 0 001.52.36 3.34 3.34 0 001.85-.55l8.65-5.67a3.36 3.36 0 001.51-3l-.56-10.35a3.38 3.38 0 00-1.84-2.82l-9.22-4.68a3.28 3.28 0 00-1.52-.37z"
              />
            </g>
            <g>
              <path
                className="air_slide_2__cls-5"
                d="M589.91 227.94a1.66 1.66 0 01-1.2-.5l-7.25-7.39a1.65 1.65 0 01-.43-1.62l2.75-10a1.67 1.67 0 011.2-1.18l10-2.61a1.64 1.64 0 011.63.45l7.25 7.38a1.72 1.72 0 01.43 1.63l-2.75 10a1.68 1.68 0 01-1.2 1.18l-10 2.61a1.51 1.51 0 01-.43.05z"
              />
              <path
                className="air_slide_2__cls-6"
                d="M595.4 206.27l7.25 7.38-2.74 10-10 2.61-7.25-7.39 2.74-10 10-2.61m0-3.37a3.1 3.1 0 00-.85.11l-10 2.61a3.35 3.35 0 00-2.39 2.37l-2.75 10a3.35 3.35 0 00.84 3.25l7.26 7.39a3.38 3.38 0 002.4 1 3.54 3.54 0 00.85-.11l10-2.61a3.38 3.38 0 002.39-2.37l2.75-10a3.37 3.37 0 00-.84-3.25l-7.26-7.38a3.34 3.34 0 00-2.4-1z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AirNoAnimationSlide2;
