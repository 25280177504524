import * as React from 'react';

function AirNoAnimationSlide3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <style>
          {
            '.air_slide_3__cls-3{fill:#e37a39}.air_slide_3__cls-4{fill:#faae5c}.air_slide_3__cls-5{fill:#75b0e0}.air_slide_3__cls-6{fill:#bfdbf1}.air_slide_3__cls-7{fill:#fbc63b}.air_slide_3__cls-8{fill:#fde9a3}.air_slide_3__cls-9{fill:#fcee21}.air_slide_3__cls-10{fill:#d9e021}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="air_slide_3__Layer_1" data-name="Layer 1">
          <g id="air_slide_3___4" data-name={4}>
            <g id="air_slide_3__partikler">
              <g>
                <path
                  className="air_slide_3__cls-3"
                  d="M493.46 108.61l-18.28 11.93a3.4 3.4 0 00.41 5.93l20.75 9.81a3.39 3.39 0 004.82-3.46l-2.48-21.75a3.39 3.39 0 00-5.22-2.46z"
                />
                <path
                  className="air_slide_3__cls-4"
                  d="M492 113.62l-11.6 7.57a2.17 2.17 0 00.26 3.77l13.17 6.23a2.16 2.16 0 003.06-2.2l-1.58-13.8a2.15 2.15 0 00-3.31-1.57z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-3"
                  d="M493.46 108.61l-18.28 11.93a3.4 3.4 0 00.41 5.93l20.75 9.81a3.39 3.39 0 004.82-3.46l-2.48-21.75a3.39 3.39 0 00-5.22-2.46z"
                />
                <path
                  className="air_slide_3__cls-4"
                  d="M492 113.62l-11.6 7.57a2.17 2.17 0 00.26 3.77l13.17 6.23a2.16 2.16 0 003.06-2.2l-1.58-13.8a2.15 2.15 0 00-3.31-1.57z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-3"
                  d="M626.54 59.74l-18.28 11.93a3.42 3.42 0 00.4 5.94l20.76 9.81a3.39 3.39 0 004.81-3.42l-2.47-21.74a3.39 3.39 0 00-5.22-2.52z"
                />
                <path
                  className="air_slide_3__cls-4"
                  d="M625.08 64.76l-11.6 7.57a2.16 2.16 0 00.26 3.76l13.17 6.23a2.15 2.15 0 003.05-2.2l-1.57-13.8a2.15 2.15 0 00-3.31-1.56z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-3"
                  d="M626.54 59.74l-18.28 11.93a3.42 3.42 0 00.4 5.94l20.76 9.81a3.39 3.39 0 004.81-3.42l-2.47-21.74a3.39 3.39 0 00-5.22-2.52z"
                />
                <path
                  className="air_slide_3__cls-4"
                  d="M625.08 64.76l-11.6 7.57a2.16 2.16 0 00.26 3.76l13.17 6.23a2.15 2.15 0 003.05-2.2l-1.57-13.8a2.15 2.15 0 00-3.31-1.56z"
                />
              </g>
              <g id="air_slide_3__particles">
                <g id="air_slide_3__bad_particle" data-name="bad particle">
                  <ellipse
                    className="air_slide_3__cls-5"
                    cx={498.85}
                    cy={235.58}
                    rx={12.28}
                    ry={12.33}
                  />
                  <ellipse
                    className="air_slide_3__cls-6"
                    cx={498.85}
                    cy={235.58}
                    rx={9.16}
                    ry={9.19}
                  />
                </g>
              </g>
              <g
                id="air_slide_3__particles"
              >
                <g id="air_slide_3__bad_particle" data-name="bad particle">
                  <ellipse
                    className="air_slide_3__cls-5"
                    cx={498.85}
                    cy={235.58}
                    rx={12.28}
                    ry={12.33}
                  />
                  <ellipse
                    className="air_slide_3__cls-6"
                    cx={498.85}
                    cy={235.58}
                    rx={9.16}
                    ry={9.19}
                  />
                </g>
              </g>
              <g
                id="air_slide_3__particles-2"
                data-name="particles"
              >
                <g id="air_slide_3__bad_particle-2" data-name="bad particle">
                  <ellipse
                    className="air_slide_3__cls-5"
                    cx={581.07}
                    cy={167.99}
                    rx={12.28}
                    ry={12.33}
                  />
                  <ellipse
                    className="air_slide_3__cls-6"
                    cx={581.07}
                    cy={167.99}
                    rx={9.16}
                    ry={9.19}
                  />
                </g>
              </g>
              <g
                id="air_slide_3__particles-2"
                data-name="particles"
              >
                <g id="air_slide_3__bad_particle-2" data-name="bad particle">
                  <ellipse
                    className="air_slide_3__cls-5"
                    cx={581.07}
                    cy={167.99}
                    rx={12.28}
                    ry={12.33}
                  />
                  <ellipse
                    className="air_slide_3__cls-6"
                    cx={581.07}
                    cy={167.99}
                    rx={9.16}
                    ry={9.19}
                  />
                </g>
              </g>
              *
              {' '}
              <g
                id="air_slide_3__particles-3"
                data-name="particles"
              >
                <g
                  id="air_slide_3__bad_particle-3"
                  data-name="bad particle"
                >
                  <ellipse
                    className="air_slide_3__cls-5"
                    cx={371.29}
                    cy={87.75}
                    rx={12.28}
                    ry={12.33}
                  />
                  <ellipse
                    className="air_slide_3__cls-6"
                    cx={371.29}
                    cy={87.75}
                    rx={9.16}
                    ry={9.19}
                  />
                </g>
              </g>
              <g
                id="air_slide_3__particles-3"
                data-name="particles"
              >
                <g
                  id="air_slide_3__bad_particle-3"
                  data-name="bad particle"
                >
                  <ellipse
                    className="air_slide_3__cls-5"
                    cx={371.29}
                    cy={87.75}
                    rx={12.28}
                    ry={12.33}
                  />
                  <ellipse
                    className="air_slide_3__cls-6"
                    cx={371.29}
                    cy={87.75}
                    rx={9.16}
                    ry={9.19}
                  />
                </g>
              </g>
              <g>
                <path
                  className="air_slide_3__cls-7"
                  d="M559.07 242.25l-6.87-6.89a7.94 7.94 0 00-11.27 0l-6.86 6.89a8 8 0 000 11.31l6.86 6.89a8 8 0 0011.27 0l6.87-6.89a8 8 0 000-11.31z"
                />
                <path
                  className="air_slide_3__cls-8"
                  d="M555.83 243.71l-5.09-5.11a5.9 5.9 0 00-8.35 0l-5.09 5.11a5.93 5.93 0 000 8.38l5.09 5.12a5.9 5.9 0 008.35 0l5.09-5.12a5.93 5.93 0 000-8.38z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-7"
                  d="M559.07 242.25l-6.87-6.89a7.94 7.94 0 00-11.27 0l-6.86 6.89a8 8 0 000 11.31l6.86 6.89a8 8 0 0011.27 0l6.87-6.89a8 8 0 000-11.31z"
                />
                <path
                  className="air_slide_3__cls-8"
                  d="M555.83 243.71l-5.09-5.11a5.9 5.9 0 00-8.35 0l-5.09 5.11a5.93 5.93 0 000 8.38l5.09 5.12a5.9 5.9 0 008.35 0l5.09-5.12a5.93 5.93 0 000-8.38z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-7"
                  d="M481 162.34l-6.86-6.9a8 8 0 00-11.27 0l-6.87 6.9a8 8 0 000 11.3l6.87 6.9a8 8 0 0011.27 0l6.86-6.9a8 8 0 000-11.3z"
                />
                <path
                  className="air_slide_3__cls-8"
                  d="M477.8 163.8l-5.09-5.11a5.88 5.88 0 00-8.35 0l-5.09 5.11a5.93 5.93 0 000 8.38l5.09 5.11a5.88 5.88 0 008.35 0l5.09-5.11a5.93 5.93 0 000-8.38z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-7"
                  d="M481 162.34l-6.86-6.9a8 8 0 00-11.27 0l-6.87 6.9a8 8 0 000 11.3l6.87 6.9a8 8 0 0011.27 0l6.86-6.9a8 8 0 000-11.3z"
                />
                <path
                  className="air_slide_3__cls-8"
                  d="M477.8 163.8l-5.09-5.11a5.88 5.88 0 00-8.35 0l-5.09 5.11a5.93 5.93 0 000 8.38l5.09 5.11a5.88 5.88 0 008.35 0l5.09-5.11a5.93 5.93 0 000-8.38z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-7"
                  d="M550.28 67.81l-6.87-6.89a7.94 7.94 0 00-11.26 0l-6.87 6.89a8 8 0 000 11.31l6.87 6.88a7.92 7.92 0 0011.26 0l6.87-6.89a8 8 0 000-11.3z"
                />
                <path
                  className="air_slide_3__cls-8"
                  d="M547.05 69.28L542 64.16a5.91 5.91 0 00-8.36 0l-5.09 5.12a6 6 0 000 8.38l5.09 5.11a5.91 5.91 0 008.36 0l5.09-5.11a6 6 0 00-.04-8.38z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-7"
                  d="M550.28 67.81l-6.87-6.89a7.94 7.94 0 00-11.26 0l-6.87 6.89a8 8 0 000 11.31l6.87 6.88a7.92 7.92 0 0011.26 0l6.87-6.89a8 8 0 000-11.3z"
                />
                <path
                  className="air_slide_3__cls-8"
                  d="M547.05 69.28L542 64.16a5.91 5.91 0 00-8.36 0l-5.09 5.12a6 6 0 000 8.38l5.09 5.11a5.91 5.91 0 008.36 0l5.09-5.11a6 6 0 00-.04-8.38z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-7"
                  d="M625 120.87l-6.87-6.9a7.94 7.94 0 00-11.26 0l-6.87 6.9a8 8 0 000 11.3l6.87 6.9a7.94 7.94 0 0011.26 0l6.87-6.9a8 8 0 000-11.3z"
                />
                <path
                  className="air_slide_3__cls-8"
                  d="M621.77 122.33l-5.1-5.11a5.88 5.88 0 00-8.35 0l-5.09 5.11a6 6 0 000 8.38l5.09 5.11a5.88 5.88 0 008.35 0l5.1-5.11a6 6 0 000-8.38z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-7"
                  d="M625 120.87l-6.87-6.9a7.94 7.94 0 00-11.26 0l-6.87 6.9a8 8 0 000 11.3l6.87 6.9a7.94 7.94 0 0011.26 0l6.87-6.9a8 8 0 000-11.3z"
                />
                <path
                  className="air_slide_3__cls-8"
                  d="M621.77 122.33l-5.1-5.11a5.88 5.88 0 00-8.35 0l-5.09 5.11a6 6 0 000 8.38l5.09 5.11a5.88 5.88 0 008.35 0l5.1-5.11a6 6 0 000-8.38z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-3"
                  d="M466.64 295.47l18.28-11.94a3.4 3.4 0 00-.41-5.93l-20.75-9.81a3.4 3.4 0 00-4.82 3.47l2.48 21.74a3.4 3.4 0 005.22 2.47z"
                />
                <path
                  className="air_slide_3__cls-4"
                  d="M468.1 290.45l11.6-7.57a2.17 2.17 0 00-.26-3.77l-13.17-6.22a2.15 2.15 0 00-3.06 2.2l1.58 13.8a2.15 2.15 0 003.31 1.56z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-3"
                  d="M466.64 295.47l18.28-11.94a3.4 3.4 0 00-.41-5.93l-20.75-9.81a3.4 3.4 0 00-4.82 3.47l2.48 21.74a3.4 3.4 0 005.22 2.47z"
                />
                <path
                  className="air_slide_3__cls-4"
                  d="M468.1 290.45l11.6-7.57a2.17 2.17 0 00-.26-3.77l-13.17-6.22a2.15 2.15 0 00-3.06 2.2l1.58 13.8a2.15 2.15 0 003.31 1.56z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-3"
                  d="M402.46 172.65l18.28-11.93a3.41 3.41 0 00-.41-5.93L399.58 145a3.39 3.39 0 00-4.82 3.46l2.48 21.75a3.39 3.39 0 005.22 2.44z"
                />
                <path
                  className="air_slide_3__cls-4"
                  d="M403.92 167.64l11.6-7.57a2.17 2.17 0 00-.26-3.77l-13.17-6.23a2.16 2.16 0 00-3.06 2.2l1.57 13.8a2.16 2.16 0 003.32 1.57z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-3"
                  d="M402.46 172.65l18.28-11.93a3.41 3.41 0 00-.41-5.93L399.58 145a3.39 3.39 0 00-4.82 3.46l2.48 21.75a3.39 3.39 0 005.22 2.44z"
                />
                <path
                  className="air_slide_3__cls-4"
                  d="M403.92 167.64l11.6-7.57a2.17 2.17 0 00-.26-3.77l-13.17-6.23a2.16 2.16 0 00-3.06 2.2l1.57 13.8a2.16 2.16 0 003.32 1.57z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-9"
                  d="M545.23 214.65a1.5 1.5 0 01-1.08-.46l-6.54-6.66a1.52 1.52 0 01-.38-1.47l2.48-9a1.52 1.52 0 011.08-1.07l9-2.35a1.31 1.31 0 01.39 0 1.53 1.53 0 011.08.45l6.54 6.66a1.53 1.53 0 01.38 1.47l-2.48 9a1.49 1.49 0 01-1.08 1.06l-9 2.36a1.74 1.74 0 01-.39.01z"
                />
                <path
                  className="air_slide_3__cls-10"
                  d="M550.19 195.1l6.54 6.66-2.48 9-9 2.36-6.54-6.66 2.48-9 9-2.35m0-3a2.86 2.86 0 00-.77.1l-9 2.35a3.05 3.05 0 00-2.16 2.14l-2.48 9a3.07 3.07 0 00.77 2.94l6.54 6.66a3 3 0 002.16.91 3.32 3.32 0 00.77-.1l9-2.36a3 3 0 002.16-2.13l2.48-9a3 3 0 00-.77-2.93l-6.54-6.69a3 3 0 00-2.16-.91z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-9"
                  d="M545.23 214.65a1.5 1.5 0 01-1.08-.46l-6.54-6.66a1.52 1.52 0 01-.38-1.47l2.48-9a1.52 1.52 0 011.08-1.07l9-2.35a1.31 1.31 0 01.39 0 1.53 1.53 0 011.08.45l6.54 6.66a1.53 1.53 0 01.38 1.47l-2.48 9a1.49 1.49 0 01-1.08 1.06l-9 2.36a1.74 1.74 0 01-.39.01z"
                />
                <path
                  className="air_slide_3__cls-10"
                  d="M550.19 195.1l6.54 6.66-2.48 9-9 2.36-6.54-6.66 2.48-9 9-2.35m0-3a2.86 2.86 0 00-.77.1l-9 2.35a3.05 3.05 0 00-2.16 2.14l-2.48 9a3.07 3.07 0 00.77 2.94l6.54 6.66a3 3 0 002.16.91 3.32 3.32 0 00.77-.1l9-2.36a3 3 0 002.16-2.13l2.48-9a3 3 0 00-.77-2.93l-6.54-6.69a3 3 0 00-2.16-.91z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-9"
                  d="M460.72 87.63a1.5 1.5 0 01-1.08-.46l-6.54-6.66a1.49 1.49 0 01-.38-1.46l2.47-9a1.55 1.55 0 011.08-1.05l9-2.35a1.74 1.74 0 01.39 0 1.53 1.53 0 011.08.45l6.54 6.66a1.53 1.53 0 01.38 1.47l-2.48 9a1.49 1.49 0 01-1.08 1.06l-9 2.36a1.74 1.74 0 01-.38-.02z"
                />
                <path
                  className="air_slide_3__cls-10"
                  d="M465.68 68.08l6.54 6.66-2.48 9-9 2.36-6.54-6.66 2.48-9 9-2.35m0-3a2.86 2.86 0 00-.77.1l-9 2.35a3.05 3.05 0 00-2.16 2.14l-2.48 9a3.09 3.09 0 00.76 2.94l6.54 6.66a3.05 3.05 0 002.17.91 3.32 3.32 0 00.77-.1l9-2.36a3 3 0 002.16-2.13l2.47-9a3 3 0 00-.76-2.93L467.84 66a3 3 0 00-2.16-.91z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-9"
                  d="M414 227.9a1.53 1.53 0 01-1.08-.45l-6.54-6.66a1.51 1.51 0 01-.38-1.47l2.47-9a1.52 1.52 0 011.08-1.06l9-2.36a1.73 1.73 0 01.38 0 1.5 1.5 0 011.09.46l6.58 6.64a1.51 1.51 0 01.38 1.46l-2.48 9a1.52 1.52 0 01-1.08 1.07l-9 2.35a1.58 1.58 0 01-.42.02z"
                />
                <path
                  className="air_slide_3__cls-10"
                  d="M419 208.35l6.54 6.66L423 224l-9 2.35-6.54-6.66 2.48-9 9-2.36m0-3a3.3 3.3 0 00-.76.1l-9 2.36a3 3 0 00-2.18 2.11l-2.48 9a3 3 0 00.76 2.93l6.54 6.66a3.05 3.05 0 002.17.91 2.91 2.91 0 00.77-.1l9-2.35a3 3 0 002.16-2.14l2.48-9a3 3 0 00-.76-2.94l-6.54-6.66a3 3 0 00-2.17-.91z"
                />
              </g>
              <g>
                <path
                  className="air_slide_3__cls-9"
                  d="M480.18 346.16a1.53 1.53 0 01-1.08-.45l-6.54-6.71a1.49 1.49 0 01-.38-1.46l2.47-9a1.52 1.52 0 011.08-1.06l9-2.36a1.73 1.73 0 01.38-.05 1.5 1.5 0 011.09.46l6.54 6.66a1.51 1.51 0 01.38 1.46l-2.48 9a1.52 1.52 0 01-1.08 1.07l-9 2.35a1.58 1.58 0 01-.38.09z"
                />
                <path
                  className="air_slide_3__cls-10"
                  d="M485.13 326.61l6.54 6.66-2.47 9-9 2.35-6.56-6.62 2.48-9 9-2.35m0-3a2.84 2.84 0 00-.76.1l-9 2.36a3 3 0 00-2.16 2.13l-2.48 9a3 3 0 00.76 2.93l6.54 6.66a3.05 3.05 0 002.17.91 2.91 2.91 0 00.77-.1l9-2.36a3 3 0 002.17-2.13l2.47-9a3 3 0 00-.76-2.94l-6.54-6.66a3 3 0 00-2.17-.91z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AirNoAnimationSlide3;
