import * as React from 'react';

function AirNoAnimationSlide1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1440 797"
      {...props}
    >
      <defs>
        <style>
          {
            '.air_slide_1__cls-2{opacity:.5;mix-blend-mode:multiply}.air_slide_1__cls-3{fill:#d4eeff}.air_slide_1__cls-4{fill:#fbc63b}.air_slide_1__cls-5{fill:#fde9a3}.air_slide_1__cls-6{fill:#75b0e0}.air_slide_1__cls-7{fill:#bfdbf1}.air_slide_1__cls-8{fill:#fcee21}.air_slide_1__cls-9{fill:#d9e021}.air_slide_1__cls-10{fill:#e37a39}.air_slide_1__cls-11{fill:#faae5c}.air_slide_1__cls-12{fill:#999}.air_slide_1__cls-13{fill:gray}'
          }
        </style>
      </defs>
      <g
        style={{
          isolation: 'isolate',
        }}
      >
        <g id="air_slide_1__Layer_1" data-name="Layer 1">
          <g id="air_slide_1__winds">
            <path
              className="air_slide_1__cls-3"
              d="M823.94 603a1.51 1.51 0 00-1-2.85c-12.38 3.05-29.05 7.15-35.36 8.69-22.85 5.59-44.89 7.42-68.31.16-23.71-7.35-51.28-22.51-71.09.26a33.25 33.25 0 00-7.34 28.17c5 23.12 39.3 31.29 46.68 7.3 2.13-6.94-.57-22.47-10.2-19.05 1.62 3.41 2.54 7.76.89 11.3-3.29 7-19 8.64-19.54-2.7s11.28-22.66 22.78-17.92 38.25 12.78 55.94 12.64c25.97-.3 65.76-16.62 86.55-26zM692.88 349.52a.79.79 0 001.06-1.15A248.24 248.24 0 00664 317.71c-18.77-15.81-35.33-15.41-31.25-34.18.56-2.6 4.43-9.24 11.38-9 8.63.29 9.8 11.46 9.8 11.46s6.62-12.86-.65-17.67c-8.89-5.88-21.63-7.26-29.08-3.82-13.55 6.25-26.29 33.88.7 47.2 11 5.41 25.87 11.88 38.66 19.22 12.53 7.18 23.44 14.53 29.32 18.6zM624.57 318.88a1.09 1.09 0 01-.81 2 135.78 135.78 0 01-17.41-5.51c-6.05-2.52-10.61-5.88-16.56-7.69-10.19-3.09-19.09-4.59-20.66 1.83s3.16 7.85 5.67 7.59a10.4 10.4 0 002.41-.51.92.92 0 011.1 1.33 8.27 8.27 0 01-5.07 3.74 10 10 0 01-6.29-1.29 17.12 17.12 0 01-7.8-8.27c-1.76-4.12-.75-10.51 1.8-14.2 6.53-9.41 19.83-6.61 25.51-4.31 7.17 2.91 14.12 9.63 21.78 15.09 5.15 3.67 11.54 7.47 16.33 10.2z"
            />
            <path
              className="air_slide_1__cls-3"
              d="M1023.62 208.1a.39.39 0 10-.51.6 99.57 99.57 0 0115.17 21.16c7.24 14 17.58 29.69 29.61 34.92 23.53 10.23 36.25 1.81 42.31-7.11s11.93-23.74.16-34.41-29.1-9.7-33.53.54c-3.22 7.44 1.82 16.49 4.83 20.89a1 1 0 002-.34c.19-3.79 1.21-9.95 5.79-10.95 6.69-1.46 16 4.12 10.32 15.37s-21.45 12.39-31.91 3.49c-5-4.28-14.59-15.49-23.17-26-6.77-8.26-16.34-15.05-21.07-18.16z"
              id="air_slide_1__wind"
            />
            <path
              className="air_slide_1__cls-3"
              d="M1033 242.77a.29.29 0 00-.56.14 72.3 72.3 0 01-.16 18.86c-1.77 11.31-2.4 24.89 2.39 33.1 9.37 16.08 20.39 16.63 27.72 14s17.09-8.73 14.77-20-12.85-18.15-19.8-14.08c-5 2.95-6 10.4-6.09 14.26 0 .78.84 1.21 1.3.65 1.72-2.13 4.94-5.28 8-3.9 4.53 2 7.61 9.29-.53 13.4s-17.8-2-20.11-11.67c-1.11-4.66-1.91-15.31-2.44-25.12-.4-7.7-3.08-15.79-4.49-19.64z"
              id="air_slide_1__wind-2"
              data-name="wind"
            />
            <path
              className="air_slide_1__cls-3"
              d="M235.74 397.77c-4.52-4.54-4.28-10.21-3.77-13.43a.5.5 0 01.94-.17c1.28 2.29 3.08 5.06 5.54 6.2a7.17 7.17 0 008.28-2.12 7.06 7.06 0 00.52-7.64 8.84 8.84 0 00-4.44-3.63c-7.07-2.89-15-.4-18.34 6.71-5.12 10.94 6.33 24.61 15.84 29a.42.42 0 01-.23.79c-5.06-.91-10.44-1.58-14.81-4.51a29.81 29.81 0 01-11-14.84c-2.51-7.3-2.83-17.36 2.58-23.44a27.27 27.27 0 0116.78-8.55 25.68 25.68 0 0117.45 4.59c5.65 3.86 10.61 10.13 10.89 17.21.5 13-13.8 24.2-25.12 14.81-.38-.32-.74-.65-1.08-1M1217.54 621.26c4.43-4.63 10.08-4.52 13.3-4.07a.51.51 0 01.18.94c-2.25 1.33-5 3.2-6.06 5.68a7.26 7.26 0 002.29 8.27 7 7 0 007.62.37 9 9 0 003.53-4.54c2.72-7.16.08-15.08-7.07-18.27-11-4.91-24.38 6.88-28.56 16.52a.41.41 0 01-.78-.22c.79-5.1 1.35-10.51 4.17-15a29.76 29.76 0 0114.55-11.38c7.22-2.67 17.24-3.21 23.4 2.1a27.43 27.43 0 018.87 16.65 25.93 25.93 0 01-4.2 17.62c-3.72 5.75-9.87 10.86-16.91 11.28-13 .78-24.4-13.34-15.29-24.89.32-.4.64-.76 1-1.1"
            />
            <path
              className="air_slide_1__cls-3 clouds"
              d="M1254 160.41a22.45 22.45 0 00-20.38-22.41 22.43 22.43 0 00-21.68-16.8H1209a22.43 22.43 0 00-21.65 16.71h-6.42a22.48 22.48 0 00-22.42 22.5V162.76a22.32 22.32 0 001.11 7 3.51 3.51 0 003.34 2.4h86.64a3.5 3.5 0 003.32-2.4 22 22 0 001.11-7v-1.17c0-.39-.03-.78-.03-1.18zM924.23 669.1A17.86 17.86 0 00908 651.27a17.85 17.85 0 00-17.24-13.36h-2.34a17.85 17.85 0 00-17.22 13.29h-5.11a17.89 17.89 0 00-17.83 17.9V670.96a17.5 17.5 0 00.89 5.56 2.79 2.79 0 002.65 1.91h68.91a2.78 2.78 0 002.64-1.91 17.49 17.49 0 00.88-5.56v-.93-.93zM593.77 162.81A17.86 17.86 0 00577.53 145a17.84 17.84 0 00-17.24-13.36H558a17.84 17.84 0 00-17.22 13.29h-5.11a17.88 17.88 0 00-17.82 17.89v1.92a17.43 17.43 0 00.88 5.55 2.77 2.77 0 002.65 1.91h68.91a2.77 2.77 0 002.64-1.91 17.44 17.44 0 00.89-5.55v-.93c0-.31-.05-.68-.05-1z"
            />
          </g>
          <g id="air_slide_1__Particles_GRPs" data-name="Particles GRPs">
            <g id="air_slide_1__Argon_GRP" data-name="Argon GRP">
              <g
                id="air_slide_1__Argon"
              >
                <path
                  className="air_slide_1__cls-4"
                  d="M776 238.61a3.68 3.68 0 002.25-.11l.87-.3 5.78-3.52a2.13 2.13 0 012-.1l5.13 2.5a3.92 3.92 0 103.52-7l-5.24-2.55a2.14 2.14 0 01-1.19-1.67l-.83-6.74a3.91 3.91 0 10-7.76 1l.75 6.12a2.15 2.15 0 01-1 2.1l-5.26 3.2a3.87 3.87 0 00-1.48 1.63 3.53 3.53 0 00-.28.79 3.91 3.91 0 00.45 3 3.85 3.85 0 002.29 1.65z"
                />
                <path
                  className="air_slide_1__cls-5"
                  d="M777.6 236.27l.25-.08 6.88-4.19a2.14 2.14 0 012-.1l6.34 3.09a1.57 1.57 0 101.41-2.8l-6.39-3.11a2.13 2.13 0 01-1.18-1.67l-1-8a1.56 1.56 0 10-3.1.39l.93 7.63a2.11 2.11 0 01-1 2.09l-6.55 4a1.57 1.57 0 00-.59.66 1.43 1.43 0 00-.12.31 1.56 1.56 0 00.22 1.24 1.58 1.58 0 001.9.54z"
                />
              </g>
              <g
                id="air_slide_1__Argon-2"
                data-name="Argon"
              >
                <path
                  className="air_slide_1__cls-4"
                  d="M832.36 275.45A3.78 3.78 0 00834 277l.81.43 6.51 1.84a2.13 2.13 0 011.47 1.43l1.69 5.46a4 4 0 004.06 2.87A3.93 3.93 0 00852 284l-1.73-5.58a2.15 2.15 0 01.41-2l4.33-5.22a3.91 3.91 0 10-6-5l-4.01 4.67a2.13 2.13 0 01-2.22.69l-5.91-1.67a4 4 0 00-2.21 0 3.93 3.93 0 00-2.33 5.53z"
                />
                <path
                  className="air_slide_1__cls-5"
                  d="M835.19 275.06l.23.12 7.74 2.18a2.17 2.17 0 011.47 1.44l2.09 6.75a1.6 1.6 0 001.46 1.16 1.58 1.58 0 001.54-2l-2.1-6.81a2.13 2.13 0 01.4-2l5.15-6.19a1.58 1.58 0 00-.2-2.21 1.56 1.56 0 00-2.2.19l-4.91 5.91a2.13 2.13 0 01-2.22.69l-7.37-2.08a1.58 1.58 0 00-.89 0 1.94 1.94 0 00-.3.13 1.54 1.54 0 00.11 2.76z"
                />
              </g>
              <g
                id="air_slide_1__Argon-2"
                data-name="Argon"
              >
                <path
                  className="air_slide_1__cls-4"
                  d="M936.61 263.14a3.64 3.64 0 002.19.54h.91l6.55-1.72a2.14 2.14 0 012 .49l4.21 3.86a3.92 3.92 0 105.36-5.71l-4.29-3.94a2.14 2.14 0 01-.67-1.94L954 248a3.91 3.91 0 10-7.71-1.29l-1 6.08a2.15 2.15 0 01-1.57 1.72l-5.94 1.56a3.84 3.84 0 00-1.89 1.14 4.12 4.12 0 00-.49.67 3.93 3.93 0 00-.41 3 3.87 3.87 0 001.62 2.26z"
                />
                <path
                  className="air_slide_1__cls-5"
                  d="M938.85 261.38h.27l7.78-2a2.14 2.14 0 012 .49l5.2 4.77a1.6 1.6 0 001.85.26 1.58 1.58 0 00.3-2.54l-5.25-4.87a2.15 2.15 0 01-.66-1.93l1.32-8a1.56 1.56 0 10-3.08-.51l-1.27 7.58a2.14 2.14 0 01-1.56 1.72l-7.41 1.95a1.48 1.48 0 00-.76.45 1.89 1.89 0 00-.2.27 1.56 1.56 0 00-.14 1.25 1.6 1.6 0 001.61 1.11z"
                />
              </g>
            </g>
            <g id="air_slide_1__Oxygen_GRP" data-name="Oxygen GRP">
              <g id="air_slide_1__Oxygen">
                <g
                  id="air_slide_1__bad_particle"
                  data-name="bad particle"
                >
                  <ellipse
                    className="air_slide_1__cls-6"
                    cx={222.98}
                    cy={255.39}
                    rx={13.62}
                    ry={13.67}
                  />
                  <ellipse
                    className="air_slide_1__cls-7"
                    cx={222.98}
                    cy={255.39}
                    rx={10.16}
                    ry={10.2}
                  />
                </g>
              </g>
              <g
                id="air_slide_1__Oxygen-2"
                data-name="Oxygen"
              >
                <g id="air_slide_1__bad_particle-2" data-name="bad particle">
                  <ellipse
                    className="air_slide_1__cls-6"
                    cx={310.98}
                    cy={251.92}
                    rx={13.62}
                    ry={13.67}
                  />
                  <ellipse
                    className="air_slide_1__cls-7"
                    cx={310.98}
                    cy={251.92}
                    rx={10.16}
                    ry={10.2}
                  />
                </g>
              </g>
              <g
                id="air_slide_1__Oxygen-3"
                data-name="Oxygen"
              >
                <g id="air_slide_1__bad_particle-3" data-name="bad particle">
                  <ellipse
                    className="air_slide_1__cls-6"
                    cx={292}
                    cy={202.19}
                    rx={13.62}
                    ry={13.67}
                  />
                  <ellipse
                    className="air_slide_1__cls-7"
                    cx={292}
                    cy={202.19}
                    rx={10.16}
                    ry={10.2}
                  />
                </g>
              </g>
              <g
                id="air_slide_1__Oxygen-4"
                data-name="Oxygen"
              >
                <g id="air_slide_1__bad_particle-4" data-name="bad particle">
                  <ellipse
                    className="air_slide_1__cls-6"
                    cx={376.66}
                    cy={213.54}
                    rx={13.62}
                    ry={13.67}
                  />
                  <ellipse
                    className="air_slide_1__cls-7"
                    cx={376.66}
                    cy={213.54}
                    rx={10.16}
                    ry={10.2}
                  />
                </g>
              </g>
              <g
                id="air_slide_1__Oxygen-5"
                data-name="Oxygen"
              >
                <g id="air_slide_1__bad_particle-5" data-name="bad particle">
                  <ellipse
                    className="air_slide_1__cls-6"
                    cx={449.1}
                    cy={227.2}
                    rx={13.62}
                    ry={13.67}
                  />
                  <ellipse
                    className="air_slide_1__cls-7"
                    cx={449.1}
                    cy={227.2}
                    rx={10.16}
                    ry={10.2}
                  />
                </g>
              </g>
            </g>
            <g id="air_slide_1__Svovl_GRP" data-name="Svovl GRP">
              <g
                id="air_slide_1__Svovl"
              >
                <path
                  className="air_slide_1__cls-8"
                  d="M1028.3 550.77a1.7 1.7 0 01-1.2-.5l-7.25-7.39a1.69 1.69 0 01-.43-1.62l2.75-10a1.68 1.68 0 011.2-1.18l10-2.61a1.52 1.52 0 01.42-.06 1.66 1.66 0 011.2.51l7.26 7.38a1.69 1.69 0 01.42 1.63l-2.75 10a1.68 1.68 0 01-1.2 1.19l-10 2.61a2.1 2.1 0 01-.42.04z"
                />
                <path
                  className="air_slide_1__cls-9"
                  d="M1033.79 529.1l7.25 7.38-2.74 10-10 2.61-7.25-7.39 2.75-10 10-2.61m0-3.37a3.1 3.1 0 00-.85.11l-10 2.61a3.35 3.35 0 00-2.39 2.36l-2.75 10a3.36 3.36 0 00.85 3.25l7.25 7.39a3.38 3.38 0 002.4 1 3.54 3.54 0 00.85-.11l10-2.61a3.4 3.4 0 002.4-2.37l2.74-10a3.37 3.37 0 00-.84-3.25l-7.25-7.38a3.36 3.36 0 00-2.41-1z"
                />
              </g>
              <g
                id="air_slide_1__Svovl-2"
                data-name="Svovl"
              >
                <path
                  className="air_slide_1__cls-8"
                  d="M1097.3 457.09a1.66 1.66 0 01-1.2-.51l-7.25-7.38a1.72 1.72 0 01-.43-1.63l2.75-10a1.68 1.68 0 011.2-1.18l10-2.61a1.51 1.51 0 01.42 0 1.66 1.66 0 011.2.5l7.26 7.39a1.66 1.66 0 01.42 1.62l-2.75 10a1.67 1.67 0 01-1.2 1.18l-10 2.61a1.59 1.59 0 01-.42.01z"
                />
                <path
                  className="air_slide_1__cls-9"
                  d="M1102.79 435.41l7.25 7.38-2.74 10-10 2.61-7.25-7.38 2.75-10 10-2.61m0-3.37a3.54 3.54 0 00-.85.11l-10 2.61a3.38 3.38 0 00-2.39 2.37l-2.75 10a3.38 3.38 0 00.85 3.26l7.25 7.38a3.34 3.34 0 002.4 1 3.54 3.54 0 00.85-.11l10-2.61a3.38 3.38 0 002.4-2.37l2.74-10a3.35 3.35 0 00-.84-3.25l-7.25-7.39a3.4 3.4 0 00-2.41-1z"
                />
              </g>
              <g
                id="air_slide_1__Svovl-3"
                data-name="Svovl"
              >
                <path
                  className="air_slide_1__cls-8"
                  d="M1100.3 518.6a1.66 1.66 0 01-1.2-.51l-7.25-7.38a1.72 1.72 0 01-.43-1.63l2.75-10a1.7 1.7 0 011.2-1.19l10-2.61a2 2 0 01.42-.05 1.7 1.7 0 011.2.5l7.26 7.39a1.67 1.67 0 01.42 1.62l-2.75 10a1.67 1.67 0 01-1.2 1.18l-10 2.61a1.59 1.59 0 01-.42.07z"
                />
                <path
                  className="air_slide_1__cls-9"
                  d="M1105.79 496.92l7.25 7.39-2.74 10-10 2.61-7.25-7.38 2.75-10 10-2.61m0-3.37a3.54 3.54 0 00-.85.11l-10 2.61a3.38 3.38 0 00-2.39 2.37l-2.75 10a3.37 3.37 0 00.85 3.25l7.25 7.38a3.34 3.34 0 002.4 1 3.1 3.1 0 00.85-.11l10-2.61a3.37 3.37 0 002.4-2.36l2.74-10a3.35 3.35 0 00-.84-3.25l-7.25-7.39a3.4 3.4 0 00-2.41-1z"
                />
              </g>
              <g
                id="air_slide_1__Svovl-4"
                data-name="Svovl"
              >
                <path
                  className="air_slide_1__cls-8"
                  d="M1127.5 573.58a1.72 1.72 0 01-1.21-.5l-7.25-7.39a1.68 1.68 0 01-.42-1.62l2.75-10a1.67 1.67 0 011.19-1.18l10-2.61a1.59 1.59 0 01.43-.06 1.71 1.71 0 011.2.5l7.25 7.39a1.69 1.69 0 01.42 1.63l-2.74 10a1.71 1.71 0 01-1.2 1.19l-10 2.61a2 2 0 01-.42.04z"
                />
                <path
                  className="air_slide_1__cls-9"
                  d="M1133 551.9l7.25 7.39-2.75 10-10 2.61-7.25-7.39 2.75-10 10-2.62m0-3.36a3.05 3.05 0 00-.85.11l-10 2.61a3.35 3.35 0 00-2.4 2.36l-2.74 10a3.35 3.35 0 00.84 3.25l7.25 7.39a3.38 3.38 0 002.4 1 3.62 3.62 0 00.86-.11l10-2.61a3.38 3.38 0 002.4-2.37l2.75-10a3.39 3.39 0 00-.85-3.25l-7.25-7.38a3.34 3.34 0 00-2.4-1z"
                />
              </g>
              <g
                id="air_slide_1__Svovl-5"
                data-name="Svovl"
              >
                <path
                  className="air_slide_1__cls-8"
                  d="M1162.41 502.38a1.66 1.66 0 01-1.2-.51l-7.25-7.38a1.69 1.69 0 01-.42-1.63l2.74-10a1.7 1.7 0 011.2-1.19l10-2.61a2 2 0 01.42-.05 1.72 1.72 0 011.21.5l7.25 7.39a1.66 1.66 0 01.42 1.62l-2.75 10a1.67 1.67 0 01-1.2 1.18l-10 2.61a1.59 1.59 0 01-.42.07z"
                />
                <path
                  className="air_slide_1__cls-9"
                  d="M1167.9 480.7l7.26 7.39-2.75 10-10 2.61-7.25-7.38 2.75-10 10-2.61m0-3.37a3.54 3.54 0 00-.85.11l-10 2.61a3.38 3.38 0 00-2.4 2.37l-2.75 10a3.37 3.37 0 00.85 3.25l7.25 7.38a3.34 3.34 0 002.4 1 3.1 3.1 0 00.85-.11l10-2.61a3.37 3.37 0 002.4-2.36l2.74-10a3.35 3.35 0 00-.84-3.25l-7.25-7.39a3.38 3.38 0 00-2.41-1z"
                />
              </g>
            </g>
            <g id="air_slide_1__kv\xE6lstof_GRP" data-name="kv\xE6lstof GRP">
              <g>
                <path
                  className="air_slide_1__cls-10"
                  d="M551.56 562.17l20.27-13.23a3.78 3.78 0 00-.45-6.58l-23-10.88a3.76 3.76 0 00-5.33 3.85l2.75 24.1a3.75 3.75 0 005.76 2.74z"
                />
                <path
                  className="air_slide_1__cls-11"
                  d="M553.18 556.61l12.86-8.4a2.39 2.39 0 00-.28-4.17l-14.61-6.91a2.39 2.39 0 00-3.39 2.44l1.75 15.3a2.38 2.38 0 003.67 1.74z"
                />
              </g>
              <g>
                <path
                  className="air_slide_1__cls-10"
                  d="M627.11 451.12l16.11 18.09a3.76 3.76 0 006.41-1.45l7.2-24.49a3.76 3.76 0 00-4.59-4.71L628.92 445a3.78 3.78 0 00-1.81 6.12z"
                />
                <path
                  className="air_slide_1__cls-11"
                  d="M632.83 451.87l10.23 11.48a2.38 2.38 0 004.06-.91l4.58-15.55a2.4 2.4 0 00-2.92-3L634 448a2.39 2.39 0 00-1.17 3.87z"
                />
              </g>
              <g>
                <path
                  className="air_slide_1__cls-10"
                  d="M639.29 508l-2.73 24.11a3.76 3.76 0 005.34 3.84l23-10.89a3.78 3.78 0 00.44-6.58l-20.27-13.22a3.77 3.77 0 00-5.78 2.74z"
                />
                <path
                  className="air_slide_1__cls-11"
                  d="M642.54 512.75l-1.74 15.31a2.39 2.39 0 003.39 2.43l14.6-6.91a2.39 2.39 0 00.28-4.17L646.21 511a2.38 2.38 0 00-3.67 1.75z"
                />
              </g>
              <g>
                <path
                  className="air_slide_1__cls-10"
                  d="M731.28 456l-22.5 8.88a3.78 3.78 0 00-.87 6.53l20.37 15.29a3.76 3.76 0 006-2.69l2.12-24.17a3.76 3.76 0 00-5.12-3.84z"
                />
                <path
                  className="air_slide_1__cls-11"
                  d="M728.59 461.09l-14.28 5.63a2.4 2.4 0 00-.56 4.15l12.93 9.71a2.39 2.39 0 003.81-1.71l1.35-15.34a2.39 2.39 0 00-3.25-2.44z"
                />
              </g>
              <g>
                <path
                  className="air_slide_1__cls-10"
                  d="M755 522.11l23.2 6.83a3.76 3.76 0 004.68-4.62l-6.75-24.62a3.77 3.77 0 00-6.39-1.57l-16.44 17.79a3.78 3.78 0 001.7 6.19z"
                />
                <path
                  className="air_slide_1__cls-11"
                  d="M760.28 519.73l14.72 4.33a2.4 2.4 0 003-2.94l-4.29-15.62a2.38 2.38 0 00-4.05-1l-10.46 11.3a2.41 2.41 0 001.08 3.93z"
                />
              </g>
              <g>
                <path
                  className="air_slide_1__cls-10"
                  d="M452.8 546l23.2 6.83a3.77 3.77 0 004.68-4.63l-6.76-24.61a3.75 3.75 0 00-6.38-1.57l-16.44 17.79a3.78 3.78 0 001.7 6.19z"
                />
                <path
                  className="air_slide_1__cls-11"
                  d="M458.06 543.61l14.72 4.39a2.39 2.39 0 003-2.94l-4.29-15.62a2.39 2.39 0 00-4-1L457 539.69a2.39 2.39 0 001.06 3.92z"
                />
              </g>
            </g>
            <g
              id="air_slide_1__X_unknown_partifcles"
              data-name="X unknown partifcles"
            >
              <g
                id="air_slide_1__X"
              >
                <path
                  className="air_slide_1__cls-12"
                  d="M367.35 381.43a3.45 3.45 0 01-2-.62 3.49 3.49 0 01-.85-4.84l4.62-6.64-6.62-4.63a3.47 3.47 0 012-6.32 3.39 3.39 0 012 .63l6.6 4.63 4.62-6.63a3.47 3.47 0 015.69 4l-4.63 6.64 6.62 4.64a3.47 3.47 0 11-4 5.69l-6.6-4.63-4.61 6.65a3.46 3.46 0 01-2.84 1.48z"
                />
                <path
                  className="air_slide_1__cls-13"
                  d="M380.55 357.28a1.7 1.7 0 011 .31A1.71 1.71 0 01382 360l-5.63 8.08 8.05 5.65a1.71 1.71 0 01.43 2.38 1.71 1.71 0 01-1.4.73 1.76 1.76 0 01-1-.3l-8.05-5.65-5.62 8.08a1.71 1.71 0 01-2.38.42 1.71 1.71 0 01-.4-2.39l5.63-8.08-8.05-5.65a1.71 1.71 0 112-2.8l8 5.64 5.62-8.08a1.71 1.71 0 011.4-.73m0-3.52a5.24 5.24 0 00-4.29 2.24l-3.6 5.18-5.16-3.62a5.24 5.24 0 00-7.29 1.3 5.26 5.26 0 001.29 7.28l5.18 3.63-3.62 5.2a5.25 5.25 0 001.29 7.28 5.21 5.21 0 007.29-1.3l3.6-5.17 5.16 3.61a5.22 5.22 0 007.29-1.29 5.25 5.25 0 00-1.29-7.28l-5.18-3.63 3.62-5.2a5.25 5.25 0 00-1.29-7.28 5.19 5.19 0 00-3-.95z"
                />
              </g>
              <g
                id="air_slide_1__X-2"
                data-name="X"
              >
                <path
                  className="air_slide_1__cls-12"
                  d="M422.55 360.09a3.5 3.5 0 01-3.4-2.79l-1.6-7.93-7.9 1.61a3.23 3.23 0 01-.69.07 3.48 3.48 0 01-.69-6.88l7.9-1.61-1.61-7.93a3.49 3.49 0 012.71-4.1 4 4 0 01.69-.07 3.49 3.49 0 013.4 2.79l1.6 7.93 7.9-1.61a3.23 3.23 0 01.69-.07 3.48 3.48 0 01.69 6.88l-7.9 1.61 1.61 7.93a3.49 3.49 0 01-2.71 4.1 4 4 0 01-.69.07z"
                />
                <path
                  className="air_slide_1__cls-13"
                  d="M418 332.22a1.7 1.7 0 011.67 1.38l2 9.66 9.62-2a2 2 0 01.34 0 1.7 1.7 0 011.67 1.37 1.71 1.71 0 01-1.33 2l-9.62 2 2 9.66a1.71 1.71 0 01-1.33 2h-.34a1.7 1.7 0 01-1.67-1.37l-2-9.66-9.62 2a1.32 1.32 0 01-.34 0 1.71 1.71 0 01-.34-3.39l9.62-2-1.95-9.66a1.71 1.71 0 011.33-2 2.09 2.09 0 01.34 0m0-3.52a5.17 5.17 0 00-1 .11 5.23 5.23 0 00-4.08 6.17l1.25 6.21-6.17 1.26a5.23 5.23 0 001 10.36 5.16 5.16 0 001-.1l6.17-1.26 1.25 6.2a5.26 5.26 0 005.13 4.2 5.17 5.17 0 001-.11 5.24 5.24 0 004.08-6.17l-1.25-6.21 6.17-1.26a5.23 5.23 0 00-1-10.36 5.16 5.16 0 00-1 .1l-6.17 1.26-1.25-6.2a5.26 5.26 0 00-5.13-4.2z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AirNoAnimationSlide1;
